import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import cloneDeep from 'lodash-es/cloneDeep';
import { INaikenYoyaku, ISecurityRule } from 'requestform-types';
import { naikenYoyakuCollectionPath } from 'requestform-types/lib/FirestorePath';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';
import { difference } from '@/utilities/difference';
import { convertObjectBy } from '@/utilities/objectUtil';

class NaikenYoyakuDocumentState extends FirestoreDocumentState<INaikenYoyaku> {}

export class NaikenYoyakuDocumentGetters extends FirestoreDocumentGetters<
  INaikenYoyaku,
  NaikenYoyakuDocumentState
> {}

export class NaikenYoyakuDocumentMutations extends FirestoreDocumentMutations<
  INaikenYoyaku,
  NaikenYoyakuDocumentState
> {
  setAllow(rule: ISecurityRule) {
    if (rule.allowAccount) {
      this.state.data.allowAccount = rule.allowAccount;
    }
    if (rule.allowDomain) {
      this.state.data.allowDomain = rule.allowDomain;
    }
  }
}

export class NaikenYoyakuDocumentActions extends FirestoreDocumentActions<
  INaikenYoyaku,
  NaikenYoyakuDocumentState,
  NaikenYoyakuDocumentGetters,
  NaikenYoyakuDocumentMutations
> {
  signInCtx!: Context<typeof SignInModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  createRequestInit(): void {
    const domainUID = this.signInCtx.getters.domainUID;
    this.createRequestDoc();
    this.commit('setAllow', {
      allowAccount: [],
      allowDomain: [domainUID]
    });
  }

  createRequestDoc(): void {
    const db = this.getters.getDB;
    const ref = doc(collection(db, naikenYoyakuCollectionPath));
    this.state.ref = ref;
    this.state.data.naikenYoyakuUID = ref.id;
  }

  diff(payload: Partial<INaikenYoyaku>): Partial<INaikenYoyaku> {
    if (!this.state.data) {
      console.log('request state does not set');
      return payload;
    }

    const diffObj = difference(payload, this.state.data);
    console.log('diff', diffObj);
    return diffObj;
  }

  /**
   * set()等の前処理
   * @param payload
   */
  firestoreSetPreHook(payload: Partial<INaikenYoyaku>) {
    console.log('preHook before', payload);
    let cleanPayload: any = cloneDeep(payload);
    const accountUID = this.signInCtx.getters.accountUID;

    // NOTE: undefinedは保存できないので再帰的にnullいれる
    cleanPayload = convertObjectBy(
      cleanPayload,
      (v: any) => v === undefined,
      null
    );
    // NOTE: modifierPayloadもreturnするのでundefinedチェックする
    const modifierPayload = convertObjectBy(
      {
        modifiedAt: serverTimestamp(),
        modifierUID: accountUID
      },
      (v: any) => v === undefined,
      null
    );

    cleanPayload = this.diff({
      ...cleanPayload,
      ...modifierPayload
    });

    console.log('preHook after', cleanPayload);
    // bukken等でも使うのでmodifierも返す
    return [cleanPayload, modifierPayload];
  }

  createUpdatePayload(
    payload: Partial<INaikenYoyaku>,
    modifierPayload: Partial<INaikenYoyaku>
  ): Partial<INaikenYoyaku> {
    // NOTE: 内見予約で更新しないプロパティは削除する
    delete payload['bukken'];
    delete payload['kanriKaisha'];
    delete payload['kanriKaishaShop'];
    delete payload['chukaiKaisha'];

    const sets = {
      ...modifierPayload,
      ...payload
    };
    console.log('update payload: ', sets);
    return sets;
  }

  async update(payload: Partial<INaikenYoyaku>) {
    if (this.state.ref) {
      const [cleanPayload, modifierPayload] = this.firestoreSetPreHook(payload);
      const updatePayload = this.createUpdatePayload(
        cleanPayload,
        modifierPayload
      );
      await setDoc(this.state.ref, updatePayload, { merge: true });
    }
  }
}

export const NaikenYoyakuDocumentModule = new Module({
  state: NaikenYoyakuDocumentState,
  getters: NaikenYoyakuDocumentGetters,
  mutations: NaikenYoyakuDocumentMutations,
  actions: NaikenYoyakuDocumentActions
});
