import { collection, doc, setDoc, Timestamp } from 'firebase/firestore';
import { ILog } from 'requestform-types';
import { internalLogCollectionPath } from 'requestform-types/lib/FirestorePath';
import { PartialRequired } from 'requestform-types/lib/TypeGuard';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';
import { appLogger } from '@/utilities/appLogger';

import { SignInModule } from './SignInModule';

class InternalLogCollectionState extends FirestoreCollectionState<ILog> {}

class InternalLogCollectionGetters extends FirestoreCollectionGetters<
  ILog,
  InternalLogCollectionState
> {
  store!: Store<any>;
  $init(store: Store<any>): void {
    this.store = store;
  }
}

class InternalLogCollectionMutations extends FirestoreCollectionMutations<
  ILog,
  InternalLogCollectionState
> {}

class InternalLogCollectionActions extends FirestoreCollectionActions<
  ILog,
  InternalLogCollectionState,
  InternalLogCollectionGetters,
  InternalLogCollectionMutations
> {
  signInCtx!: Context<typeof SignInModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  async addLog(payload: {
    requestUID: string;
    logObj: PartialRequired<
      ILog,
      'category' | 'creatorUID' | 'content' | 'creatorDomainName'
    >;
  }): Promise<string> {
    const { requestUID, logObj } = payload;
    const internalLogCollection = collection(
      db,
      internalLogCollectionPath(this.signInCtx.getters.domainUID, requestUID)
    );
    const logUID = doc(internalLogCollection).id;
    const log = {
      ...logObj,
      logUID,
      // NOTE: FieldValue.ServerTimestampだと昇順ソートに一瞬時差が生じるので日時は画面側で生成しておく
      createdAt: Timestamp.now(),
      status: logObj.status || null,
      creatorName: logObj.creatorName || ''
    };
    const result = await setDoc(doc(internalLogCollection, logUID), log, {
      merge: true
    })
      .then(() => {
        return true;
      })
      .catch(e => {
        appLogger.error(e, log);
        return false;
      });
    if (!result) {
      return '';
    }
    return logUID;
  }

  setInternalLogCollectionRef(requestUID: string) {
    const domainUID = this.signInCtx.getters.domainUID;
    if (!requestUID || !domainUID) {
      return;
    }
    const ref = collection(
      db,
      internalLogCollectionPath(domainUID, requestUID)
    );
    this.setRef(ref);
  }
}

export const InternalLogCollectionModule = new Module({
  state: InternalLogCollectionState,
  getters: InternalLogCollectionGetters,
  mutations: InternalLogCollectionMutations,
  actions: InternalLogCollectionActions
});
