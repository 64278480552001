import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  LogoutOptions as _LogoutOptions,
  RedirectLoginOptions,
  User
} from '@auth0/auth0-spa-js';

export const getRedirectUri = (): string => {
  return (
    import.meta.env.VITE_AUTH0_REDIRECT_URL ||
    `https://${import.meta.env.VITE_AUTH_DOMAIN}`
  );
};

const getConfig = (
  options: Partial<Auth0ClientOptions> = {}
): Auth0ClientOptions => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN; // テナント内では共通と思われる（issuerと一致する）
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID; // いい生活アカウントアプリの ClientID
  const audience = import.meta.env.VITE_AUTH0_SE_AUDIENCE; // API の identifier
  if (!domain || !clientId || !audience) {
    throw new Error(
      `Cannot be null following values: ${JSON.stringify({
        domain,
        clientId,
        audience
      })}`
    );
  }
  return {
    domain,
    client_id: clientId,
    audience,
    redirect_uri: getRedirectUri(),
    cacheLocation: 'localstorage',
    scope: 'openid profile email',
    useRefreshTokens: true,
    ...options
  };
};

type LogoutOptions = _LogoutOptions & {
  silently?: boolean;
};

export class Auth0 {
  constructor(readonly client: Auth0Client) {}
  public async loginWithRedirect<TAppState = any>(
    options: RedirectLoginOptions<TAppState> = {}
  ) {
    await this.client.loginWithRedirect(options);
  }
  public async logout(options?: LogoutOptions) {
    await this.client.logout(options);
  }
  public async getTokenSilently(
    options?: GetTokenSilentlyOptions
  ): Promise<string> {
    return await this.client.getTokenSilently(options);
  }

  public async getUser(): Promise<User | undefined> {
    return await this.client.getUser();
  }

  public async isAuthenticated(): Promise<boolean> {
    return await this.client.isAuthenticated();
  }

  public async handleRedirectCallback(): Promise<string | undefined> {
    try {
      const { appState } = await this.client.handleRedirectCallback();
      if (appState.targetUrl) {
        return appState.targetUrl;
      }
    } catch (_) {
      // 握りつぶす
    }
  }
}

/**
 * auth0 アクセス用カスタムクラスを生成する
 *
 * createAuth0Client の挙動として、セッション等が残っていれば getTokenSilently までやる
 * */
export const getAuth0 = async (): Promise<Auth0> => {
  const auth0 = await createAuth0Client(getConfig());
  // 明示的に getTokenSilently を呼ぶことで、refresh token の rotation を行う
  // 参考: https://auth0.com/docs/secure/tokens/refresh-tokens/use-refresh-token-rotation#token-storage
  try {
    await auth0.getTokenSilently();
  } catch (error: any) {
    if (error.error !== 'login_required') {
      console.error(error);
    }
  }
  return new Auth0(auth0);
};
