import cloneDeep from 'lodash-es/cloneDeep';
import { isFirestoreFieldValue } from 'requestform-types/lib/TypeGuard';

/**
 * conditionにpropertyをconvertedに置き換える
 * @param object
 * @param condition
 * @param converted
 */
export function convertObjectBy(
  object: any,
  condition: (value: any) => boolean,
  converted: any
) {
  const _obj = cloneDeep(object);
  const remove = (obj: any, cond: (value: any) => boolean, conv: any) => {
    for (const o in obj) {
      if (cond(obj[o])) {
        obj[o] = conv;
      } else if (
        typeof obj[o] === 'object' &&
        !isFirestoreFieldValue(obj[o]) &&
        o !== 'formSetting'
      ) {
        remove(obj[o], cond, conv);
      }
    }
  };
  remove(_obj, condition, converted);
  return _obj;
}
