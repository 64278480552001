import { DateString } from 'requestform-types/lib/DateTimeString';
import {
  RequestStatus,
  requestStatusMap
} from 'requestform-types/lib/IRequest';
import { isDefined, isString } from 'requestform-types/lib/TypeGuard';
import { CheckKeys } from 'requestform-types/lib/utilities';
import { Dictionary } from 'vue-router/types/router';

import {
  Converter,
  deleteCache,
  DomainFilter,
  getCache,
  isDateString,
  isDomainFilter,
  setCache
} from './searchConditionsBase';

export const BanteSearchItem = {
  None: '番手なし',
  First: '1番手のみ',
  AfterSecond: '2番手以降'
} as const;
export type BanteSearchItem = typeof BanteSearchItem[keyof typeof BanteSearchItem];
export const isBanteSearchItem = (v: unknown): v is BanteSearchItem =>
  (Object.values(BanteSearchItem) as unknown[]).includes(v);

// ex: ['下書き中', '申込者記入中'] => [1, 2]
const convertRequestStatus = (
  filter: unknown[]
): RequestStatus[] | undefined => {
  const filtered = [...requestStatusMap.entries()]
    .filter(({ 1: v }) => filter.includes(v))
    .map(([s]) => s);
  return filtered.length ? filtered : undefined;
};

// 検索条件に変更があった場合はまずここを修正する
export const SearchConditionKeys = [
  'domain',
  'bukken',
  'heyaKukakuNumber',
  'moshikomisha',
  'tags',
  'status',
  'subStatus',
  'active',
  'reactionNeeded',
  'modifiedAtStart',
  'modifiedAtEnd',
  'reviewRequestedAtStart',
  'reviewRequestedAtEnd',
  'kanriKaisha',
  'kanriKaishaExactSearch',
  'kanriKaishaLocked',
  'kanriKaishaTantoshaName',
  'chukaiKaisha',
  'chukaiKaishaTenpoName',
  'chukaiKaishaExactSearch',
  'chukaiKaishaLocked',
  'chukaiKaishaTenpoNameExactSearch',
  'chukaiKaishaTantoshaName',
  'bante'
] as const;
export type SearchConditionKeys = typeof SearchConditionKeys[number];
export type SearchConditions = CheckKeys<
  {
    domain: DomainFilter;
    bukken: string;
    heyaKukakuNumber: string;
    moshikomisha: string;
    tags: string[];
    status: RequestStatus[];
    subStatus: string[] | undefined;
    active: boolean;
    reactionNeeded: boolean;
    modifiedAtStart: DateString;
    modifiedAtEnd: DateString;
    reviewRequestedAtStart: DateString;
    reviewRequestedAtEnd: DateString;
    kanriKaisha: string;
    kanriKaishaTantoshaName: string;
    kanriKaishaExactSearch: boolean;
    kanriKaishaLocked: boolean;
    chukaiKaisha: string;
    chukaiKaishaTenpoName: string;
    chukaiKaishaExactSearch: boolean;
    chukaiKaishaLocked: boolean;
    chukaiKaishaTenpoNameExactSearch: boolean;
    chukaiKaishaTantoshaName: string;
    bante: BanteSearchItem;
  },
  SearchConditionKeys
>;

export const converter: {
  [K in SearchConditionKeys]: Converter<SearchConditions, K>;
} = {
  domain: {
    toQuery: v => v.domain,
    fromQuery: ({ domain }) => {
      if (isDomainFilter(domain)) return domain;
      return DomainFilter.Ours;
    }
  },
  bukken: {
    toQuery: v => (!v.bukken ? undefined : v.bukken),
    fromQuery: ({ bukken }) => (isString(bukken) ? bukken : undefined)
  },
  heyaKukakuNumber: {
    toQuery: v => (!v.heyaKukakuNumber ? undefined : v.heyaKukakuNumber),
    fromQuery: ({ heyaKukakuNumber }) =>
      isString(heyaKukakuNumber) ? heyaKukakuNumber : undefined
  },
  moshikomisha: {
    toQuery: v => (!v.moshikomisha ? undefined : v.moshikomisha),
    fromQuery: ({ moshikomisha }) =>
      isString(moshikomisha) ? moshikomisha : undefined
  },
  tags: {
    toQuery: v => v.tags,
    fromQuery: ({ tags }) => {
      if (isString(tags)) return [tags];
      if (Array.isArray(tags)) return tags.filter(isDefined);
    }
  },
  status: {
    toQuery: v => v.status?.map(s => requestStatusMap.get(s)).filter(isDefined),
    fromQuery: ({ status }) => {
      if (isString(status)) return convertRequestStatus([status]);
      if (Array.isArray(status)) return convertRequestStatus(status);
    }
  },
  subStatus: {
    toQuery: v => v.subStatus,
    fromQuery: ({ subStatus }) => {
      if (isString(subStatus)) return [subStatus];
      if (Array.isArray(subStatus)) return subStatus.filter(isDefined);
    }
  },
  active: {
    toQuery: v => (v.active === false ? 'false' : undefined),
    fromQuery: ({ active }) => (active === 'false' ? false : true)
  },
  reactionNeeded: {
    toQuery: v => (v.reactionNeeded === true ? 'true' : undefined),
    fromQuery: ({ reactionNeeded }) => {
      if (reactionNeeded === 'true') return true;
    }
  },
  modifiedAtStart: {
    toQuery: v => v.modifiedAtStart,
    fromQuery: ({ modifiedAtStart }) =>
      isDateString(modifiedAtStart) ? modifiedAtStart : undefined
  },
  modifiedAtEnd: {
    toQuery: v => v.modifiedAtEnd,
    fromQuery: ({ modifiedAtEnd }) =>
      isDateString(modifiedAtEnd) ? modifiedAtEnd : undefined
  },
  reviewRequestedAtStart: {
    toQuery: v => v.reviewRequestedAtStart,
    fromQuery: ({ reviewRequestedAtStart }) =>
      isDateString(reviewRequestedAtStart) ? reviewRequestedAtStart : undefined
  },
  reviewRequestedAtEnd: {
    toQuery: v => v.reviewRequestedAtEnd,
    fromQuery: ({ reviewRequestedAtEnd }) =>
      isDateString(reviewRequestedAtEnd) ? reviewRequestedAtEnd : undefined
  },
  kanriKaisha: {
    toQuery: v => (!v.kanriKaisha ? undefined : v.kanriKaisha),
    fromQuery: v => {
      if (isString(v.kanriKaisha)) return v.kanriKaisha;
    }
  },
  kanriKaishaExactSearch: {
    toQuery: v => (v.kanriKaishaExactSearch === true ? 'true' : undefined),
    fromQuery: v => {
      if (v.kanriKaishaExactSearch === 'true') return true;
    }
  },
  kanriKaishaLocked: {
    toQuery: v => {
      if (v.kanriKaishaLocked) return 'true';
    },
    fromQuery: v => {
      if (v.kanriKaishaLocked === 'true') return true;
    }
  },
  kanriKaishaTantoshaName: {
    toQuery: v =>
      !v.kanriKaishaTantoshaName ? undefined : v.kanriKaishaTantoshaName,
    fromQuery: ({ kanriKaishaTantoshaName }) =>
      isString(kanriKaishaTantoshaName) ? kanriKaishaTantoshaName : undefined
  },
  chukaiKaisha: {
    toQuery: v => (!v.chukaiKaisha ? undefined : v.chukaiKaisha),
    fromQuery: v => {
      if (isString(v.chukaiKaisha)) return v.chukaiKaisha;
    }
  },
  chukaiKaishaExactSearch: {
    toQuery: v => (v.chukaiKaishaExactSearch === true ? 'true' : undefined),
    fromQuery: v => {
      if (v.chukaiKaishaExactSearch === 'true') return true;
    }
  },
  chukaiKaishaTenpoName: {
    toQuery: v =>
      !v.chukaiKaishaTenpoName ? undefined : v.chukaiKaishaTenpoName,
    fromQuery: v => {
      if (isString(v.chukaiKaishaTenpoName)) return v.chukaiKaishaTenpoName;
    }
  },
  chukaiKaishaTenpoNameExactSearch: {
    toQuery: v =>
      v.chukaiKaishaTenpoNameExactSearch === true ? 'true' : undefined,
    fromQuery: v => {
      if (v.chukaiKaishaTenpoNameExactSearch === 'true') return true;
    }
  },
  chukaiKaishaLocked: {
    toQuery: v => {
      if (v.chukaiKaishaLocked) return 'true';
    },
    fromQuery: v => {
      if (v.chukaiKaishaLocked === 'true') return true;
    }
  },
  chukaiKaishaTantoshaName: {
    toQuery: v =>
      !v.chukaiKaishaTantoshaName ? undefined : v.chukaiKaishaTantoshaName,
    fromQuery: ({ chukaiKaishaTantoshaName }) =>
      isString(chukaiKaishaTantoshaName) ? chukaiKaishaTantoshaName : undefined
  },
  bante: {
    toQuery: v => v.bante,
    fromQuery: ({ bante }) => {
      if (isBanteSearchItem(bante)) return bante;
    }
  }
};

export const fromQuery = (
  query: Dictionary<string | (string | null)[]>
): Partial<SearchConditions> =>
  SearchConditionKeys.reduce((s, k) => {
    const condition = converter[k].fromQuery(query);
    if (!isDefined(condition)) return s;
    return {
      ...s,
      [k]: condition
    };
  }, {} as Partial<SearchConditions>);

export const toQuery = (
  searchConditions: Partial<SearchConditions>
): Record<SearchConditionKeys, string | (string | null)[]> =>
  SearchConditionKeys.reduce((q, k) => {
    const condition = converter[k].toQuery(searchConditions);
    if (!isDefined(condition)) return q;
    return {
      ...q,
      [k]: condition
    };
  }, {} as Record<SearchConditionKeys, string | (string | null)[]>);

const kanriKaishaLockedProps = [
  'kanriKaisha',
  'kanriKaishaExactSearch'
] as const;
const chukaiKaishaLockedProps = [
  'chukaiKaisha',
  'chukaiKaishaExactSearch',
  'chukaiKaishaTenpoName',
  'chukaiKaishaTenpoNameExactSearch'
] as const;

export const getCachedSearchConditions = (
  domain: DomainFilter
): Partial<SearchConditions> => {
  const lockConditions: Partial<SearchConditions> = {
    kanriKaishaLocked: kanriKaishaLockedProps.some(p => getCache(domain, p)),
    chukaiKaishaLocked: chukaiKaishaLockedProps.some(p => getCache(domain, p))
  };
  return [...kanriKaishaLockedProps, ...chukaiKaishaLockedProps].reduce(
    (s, p) => {
      const cache = getCache(domain, p);
      if (!isDefined(cache)) return s;
      return { ...s, [p]: converter[p].fromQuery({ [p]: cache }) };
    },
    lockConditions
  );
};

// NOTE: テストが書きやすいようにRequestSearchConditions.vueファイルから切り出し
export const onChangeSearchConditions = (
  before: Partial<SearchConditions>,
  after: Partial<SearchConditions>
): void => {
  const domain = after.domain ?? DomainFilter.Ours;
  // 管理会社キャッシュ
  if (after.kanriKaishaLocked) {
    kanriKaishaLockedProps.forEach(p => {
      const cache = converter[p].toQuery(after);
      isString(cache) ? setCache(domain, p, cache) : deleteCache(domain, p);
    });
  } else if (before.kanriKaishaLocked && !after.kanriKaishaLocked) {
    kanriKaishaLockedProps.forEach(p => deleteCache(domain, p));
  }
  // 仲介会社キャッシュ
  if (after.chukaiKaishaLocked) {
    chukaiKaishaLockedProps.forEach(p => {
      const cache = converter[p].toQuery(after);
      isString(cache) ? setCache(domain, p, cache) : deleteCache(domain, p);
    });
  } else if (before.chukaiKaishaLocked && !after.chukaiKaishaLocked) {
    chukaiKaishaLockedProps.forEach(p => deleteCache(domain, p));
  }
};
