import { collection, query, where } from 'firebase/firestore';
import moment from 'moment-timezone';
import { INaikenYoyaku, IShop } from 'requestform-types';
import { DateString } from 'requestform-types/lib/DateTimeString';
import { domainNaikenYoyakuCollectionPath } from 'requestform-types/lib/FirestorePath';
import { naikenYoyakuStatus } from 'requestform-types/lib/naikenYoyaku/INaikenYoyaku';
import {
  isBukken,
  isDomain,
  isNaikenYoyaku,
  isTimestamp
} from 'requestform-types/lib/TypeGuard';
import { INaikenYoyakuDomain } from 'requestform-types/src';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';
import {
  getCachedSearchConditions,
  SearchConditions
} from '@/utilities/search/naikenYoyakuSearchConditionsConverter';
import { DomainFilter } from '@/utilities/search/searchConditionsBase';

class NaikenYoyakuCollectionState extends FirestoreCollectionState<INaikenYoyaku> {
  domainFilter: DomainFilter = DomainFilter.Ours;
  // 他社物件メニュー用
  oursSearchConditions: Partial<SearchConditions> = {
    ...getCachedSearchConditions(DomainFilter.Ours),
    domain: DomainFilter.Ours,
    status: [naikenYoyakuStatus.Reserved],
    naikenDateFrom: moment().format('YYYY-MM-DD') as DateString
  };
  // 他社物件メニュー用
  theirsSearchConditions: Partial<SearchConditions> = {
    ...getCachedSearchConditions(DomainFilter.Theirs),
    domain: DomainFilter.Theirs,
    status: [naikenYoyakuStatus.Reserved],
    naikenDateFrom: moment().format('YYYY-MM-DD') as DateString
  };
}

export class NaikenYoyakuCollectionGetters extends FirestoreCollectionGetters<
  INaikenYoyaku,
  NaikenYoyakuCollectionState
> {
  store!: Store<any>;
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.store = store;
    this.signInCtx = SignInModule.context(store);
  }

  get getDomainFilter() {
    return this.state.domainFilter;
  }

  get searchConditions() {
    if (this.getDomainFilter === DomainFilter.Theirs) {
      return this.state.theirsSearchConditions;
    }
    return this.state.oursSearchConditions;
  }

  get getFilteredList() {
    let commitList =
      this.state.domainFilter === DomainFilter.Ours
        ? this.getOurData
        : this.getTheirData;

    commitList = commitList.filter(d => d.startDateTime);
    if (this.searchConditions.status?.length) {
      commitList = commitList.filter(
        d => d.status && this.searchConditions.status?.includes(d.status ?? 0)
      );
    }
    if (this.searchConditions.bukken) {
      const filter = this.searchConditions.bukken.toLowerCase();
      commitList = commitList.filter(
        d => isBukken(d.bukken) && d.bukken.name?.toLowerCase().includes(filter)
      );
    }
    if (this.searchConditions.heyaKukakuNumber) {
      const filter = this.searchConditions.heyaKukakuNumber.toLowerCase();
      commitList = commitList.filter(
        d =>
          isBukken(d.bukken) &&
          d.bukken.heyaKukakuNumber?.toLowerCase().includes(filter)
      );
    }
    if (this.searchConditions.naikenDateFrom) {
      commitList = commitList.filter(
        d =>
          d.startDateTime &&
          isTimestamp(d.startDateTime) &&
          moment(d.startDateTime.toDate()).isSameOrAfter(
            moment(this.searchConditions.naikenDateFrom),
            'day'
          )
      );
    }
    if (this.searchConditions.naikenDateTo) {
      commitList = commitList.filter(
        d =>
          d.startDateTime &&
          isTimestamp(d.startDateTime) &&
          moment(d.startDateTime.toDate()).isSameOrBefore(
            moment(this.searchConditions.naikenDateTo),
            'day'
          )
      );
    }
    if (this.searchConditions.createdAtFrom) {
      commitList = commitList.filter(
        d =>
          d.createdAt &&
          isTimestamp(d.createdAt) &&
          moment(d.createdAt.toDate()).isSameOrAfter(
            moment(this.searchConditions.createdAtFrom),
            'day'
          )
      );
    }
    if (this.searchConditions.createdAtTo) {
      commitList = commitList.filter(
        d =>
          d.createdAt &&
          isTimestamp(d.createdAt) &&
          moment(d.createdAt.toDate()).isSameOrBefore(
            moment(this.searchConditions.createdAtTo),
            'day'
          )
      );
    }
    if (this.searchConditions.purpose) {
      commitList = commitList.filter(
        d => d.purpose && this.searchConditions.purpose?.includes(d.purpose)
      );
    }
    const kanriKaishaFilter = this.searchConditions.kanriKaisha;
    if (kanriKaishaFilter) {
      const filter: (names: Partial<string[]>) => boolean = this
        .searchConditions.kanriKaishaExactSearch
        ? // 完全一致
          names => names.some(x => x === kanriKaishaFilter)
        : // 部分一致
          names =>
            names.some(x =>
              x?.toLowerCase().includes(kanriKaishaFilter.toLowerCase())
            );
      commitList = commitList.filter(d => {
        // TODO: 一覧データ用の型を用意した方が良さそう
        // NOTE: 速度優先でアサーションしている
        const kanriKaisha = d.kanriKaisha as
          | Partial<INaikenYoyakuDomain>
          | undefined;
        const kanriKaishaShop = d.kanriKaishaShop as Partial<IShop> | undefined;
        return filter([kanriKaisha?.name, kanriKaishaShop?.name]);
      });
    }
    if (this.searchConditions.chukaiKaisha) {
      const filter = (name?: string) =>
        this.searchConditions.chukaiKaishaExactSearch
          ? name === this.searchConditions.chukaiKaisha
          : name
              ?.toLowerCase()
              .includes(
                this.searchConditions.chukaiKaisha?.toLowerCase() || ''
              );
      commitList = commitList.filter(d => filter(d.chukaiKaishaName));
    }
    if (this.searchConditions.chukaiKaishaTenpoName) {
      const filter = (name?: string) =>
        this.searchConditions.chukaiKaishaTenpoNameExactSearch
          ? name === this.searchConditions.chukaiKaishaTenpoName
          : name
              ?.toLowerCase()
              .includes(
                this.searchConditions.chukaiKaishaTenpoName?.toLowerCase() || ''
              );
      commitList = commitList.filter(d => filter(d.chukaiKaishaTenpoName));
    }

    return commitList.filter(isNaikenYoyaku);
  }

  get getOurData() {
    return this.state.data
      .filter(
        n =>
          n.kanriKaisha &&
          isDomain(n.kanriKaisha) &&
          n.kanriKaisha.domainUID === this.signInCtx.getters.domainUID
      )
      .map(x => (typeof x === 'object' ? new Proxy(x, this.state.handler) : x));
  }

  get getTheirData() {
    return this.state.data
      .filter(
        n =>
          n.chukaiKaisha &&
          isDomain(n.chukaiKaisha) &&
          n.chukaiKaisha.domainUID === this.signInCtx.getters.domainUID
      )
      .map(x => (typeof x === 'object' ? new Proxy(x, this.state.handler) : x));
  }
}

class NaikenYoyakuCollectionMutations extends FirestoreCollectionMutations<
  INaikenYoyaku,
  NaikenYoyakuCollectionState
> {
  setDomainFilter(filter: DomainFilter) {
    this.state.domainFilter = filter;
  }
  setSearchConditions(v: Partial<SearchConditions>) {
    if (v.domain === DomainFilter.Theirs) {
      this.state.theirsSearchConditions = v;
    } else {
      this.state.oursSearchConditions = v;
    }
  }
}

class NaikenYoyakuCollectionActions extends FirestoreCollectionActions<
  INaikenYoyaku,
  NaikenYoyakuCollectionState,
  NaikenYoyakuCollectionGetters,
  NaikenYoyakuCollectionMutations
> {
  setDomainFilter(filter: DomainFilter) {
    this.commit('setDomainFilter', filter);
  }
  setSearchConditions(v: Partial<SearchConditions>) {
    if (v.domain !== this.getters.getDomainFilter) {
      this.commit('setDomainFilter', v.domain ?? DomainFilter.Ours);
    }
    this.commit('setSearchConditions', v);
  }
  setDomainCollectionRef(domainUID: string) {
    const searchConditions =
      this.state.domainFilter === '自社物件'
        ? this.state.oursSearchConditions
        : this.state.theirsSearchConditions;
    const cRef = query(
      collection(db, domainNaikenYoyakuCollectionPath(domainUID)),
      where(
        'status',
        'in',
        searchConditions.status?.length
          ? searchConditions.status
          : [
              naikenYoyakuStatus.Reserved,
              naikenYoyakuStatus.Complete,
              naikenYoyakuStatus.Cancel,
              naikenYoyakuStatus.Reject,
              naikenYoyakuStatus.Stop
            ]
      ),
      where(
        'startDateTime',
        '>=',
        searchConditions.naikenDateFrom
          ? moment(this.state.oursSearchConditions.naikenDateFrom).toDate()
          : new Date('1970-01-01')
      )
    );
    return this.setRef(cRef);
  }
}

export const NaikenYoyakuCollectionModule = new Module({
  state: NaikenYoyakuCollectionState,
  getters: NaikenYoyakuCollectionGetters,
  mutations: NaikenYoyakuCollectionMutations,
  actions: NaikenYoyakuCollectionActions
});
