import {
  IBukken,
  IDomain,
  IHozin,
  IKasaiHokenKaisha,
  IPerson,
  IShatakuDaikoKaisha,
  IYachinHoshoKaisha
} from '.';
import {
  Datetime,
  DocRef,
  IDocumentBase,
  ServerTimestamp
} from './IDocumentBase';
import { IDomainFormSettingValue } from './IFormConfigure';
import { IHonninKakunin } from './IHonninKakunin';
import { ILog } from './ILog';
import { kokyakuKubun } from './IPerson';
import { taxTypes, unitTypes } from './IPriceType';
import { ISecurityRule } from './ISecurityRule';
import { IShop } from './IShop';
import { kaiyakuYokokuUnitTypes } from './IUnitType';
import { MultipleSelectionObj } from './MultipleSelection';
import { createConstDef, ValueOf } from './utilities';

export const AgreeCustomKeys = [1, 2, 3, 4, 5] as const;
export type AgreeCustomKeys = typeof AgreeCustomKeys[number];
/** カスタム項目${AgreeCustomKeys}に同意する */
export type AgreeCustom = Record<`agreeCustom${AgreeCustomKeys}`, boolean>;

// TODO: 回答方式が増えたらunionで型を追加する
export type QueryCustomValueType = string;
export const QueryCustomKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
export type QueryCustomKeys = typeof QueryCustomKeys[number];
/** その他確認事項${QueryCustomKeys}の回答 */
export type QueryCustom = Record<
  `queryCustom${QueryCustomKeys}`,
  QueryCustomValueType
>;

export const ToritsugiCustomKeys = [1, 2, 3, 4, 5] as const;
export type ToritsugiCustomKeys = typeof ToritsugiCustomKeys[number];
/** カスタム付帯サービス */
export type ToritsugiCustom = Record<
  `toritsugiCustom${ToritsugiCustomKeys}`,
  boolean
>;

// 入居者
export const NyukyoshaKeys = [1, 2, 3, 4, 5] as const;
export type NyukyoshaKeys = typeof NyukyoshaKeys[number];
export type NyukyoshaProperties = `nyukyosha${NyukyoshaKeys}`;
export type Nyukyosha = Record<NyukyoshaProperties, Partial<IPerson>>;

type requestBase = IDocumentBase & ISecurityRule;

// TODO: refs #140 Objectで持つところはPartial型にする
/** 申込情報 */
export interface IRequest
  extends requestBase,
    AgreeCustom,
    QueryCustom,
    ToritsugiCustom,
    Nyukyosha {
  /** 申込情報のユニークID */
  requestUID: string;
  /** 状態 */
  status: RequestStatus;
  /** サブステータスUID */
  subStatusUID: string | null;
  /** 申込日 */
  moshikomiDate: Datetime | null;
  /** 入居希望日 */
  nyukyoKiboDate: Datetime | null;
  /** 契約開始予定日 */
  keiyakuKaishiDate: Datetime | null;
  /** 郵送契約を希望する */
  yusoKeiyakuKibo: boolean;
  /** 内見日 */
  naikenbi: Datetime | null;
  /** 物件情報 */
  bukken: DocRef<Partial<IBukken>> | undefined;
  /** 管理会社 */
  kanriKaisha: DocRef<Partial<IDomain>> | undefined;
  /** 管理会社問い合わせ先店舗 */
  kanriKaishaShop: DocRef<Partial<IShop>> | undefined;
  /** 管理会社 担当者ID */
  kanriKaishaTantoshaId: string;
  /** 管理会社 担当者名 */
  kanriKaishaTantoshaName: string;
  /** 管理会社 メールアドレス */
  kanriKaishaTantoshaMailAddress: string[];
  /** 管理会社 電話番号 */
  kanriKaishaTantoshaTelNumber: string;
  /** 仲介会社 */
  chukaiKaisha: DocRef<Partial<IDomain>> | undefined;
  /** 仲介会社 店舗名 */
  chukaiKaishaTenpoName: string;
  /** 仲介会社 店舗電話番号 */
  chukaiKaishaTenpoTelNumber: string;
  /** 仲介会社 店舗FAX番号 */
  chukaiKaishaTenpoFaxNumber: string;
  /** 仲介会社 店舗郵便番号 */
  chukaiKaishaTenpoPostNumber: string;
  /** 仲介会社 店舗住所 */
  chukaiKaishaTenpoAddress: string;
  /** 仲介会社 店舗ID */
  chukaiKaishaTenpoCustomerID: string;
  /** 仲介会社 担当者ID */
  chukaiKaishaTantoshaId: string;
  /** 仲介会社 担当者名 */
  chukaiKaishaTantoshaName: string;
  /** 仲介会社 メールアドレス */
  chukaiKaishaTantoshaMailAddress: string[];
  /** 仲介会社 担当者電話番号 */
  chukaiKaishaTantoshaTelNumber: string;
  /** 申込者 */
  moshikomisha: Partial<IPerson>;
  /** 月額賃料等は振込を希望する */
  chinryoBankTransfer: boolean;
  /** 車両種類 */
  vehicleType: vehicleTypes;
  /** 車両ナンバー */
  vehicleNumber: string;
  /** 車両色 */
  vehicleColor: string;
  /** 車名 */
  vehicleName: string;
  /** 車両全長 */
  vehicleLength: number;
  /** 車両全高 */
  vehicleHeight: number;
  /** 車両全幅 */
  vehicleWidth: number;
  /** 車両重量 */
  vehicleWeight: number;
  /** 車両排気量 */
  vehicleDisplacement: number;
  /** 駐車場の利用を希望する */
  isUseParkingCars: boolean;
  /** 駐車場貸出可否 */
  isAvailableParkingCars: boolean;
  /** 駐車場 申込台数 */
  parkingCars: number;
  /** 駐車場 申込車両情報 */
  parkingCarInfoText: string;
  /** バイク 申込台数 */
  parkingMotorcicles: number;
  /** バイク 申込車両情報 */
  parkingMotorcicleInfoText: string;
  /** 自転車 申込台数 */
  parkingBicycles: number;
  /** 自転車 申込車両情報 */
  parkingBicycleInfoText: string;
  /** ペットの飼育有無 */
  isPetBleeding: boolean;
  /** 飼育するペット情報 */
  petType: string;
  /** 楽器演奏の有無 */
  isGakkiPlaying: boolean;
  /** 演奏する楽器の情報 */
  gakkiType: string;
  /** 緊急連絡先 */
  kinkyuRenrakusaki: Partial<IPerson>;
  /** 保証人 */
  hoshounin: Partial<IPerson>;
  /** 付帯サービス説明 */
  futaiSetsumei: boolean;
  /** 付帯サービス取次可否 部屋クリーニング */
  toritsugiCleaning: boolean;
  /** 付帯サービス取次可否 ライフライン */
  toritsugiLifeline: boolean;
  /** 付帯サービス取次可否 インターネット */
  toritsugiInternet: boolean;
  /** 付帯サービス取次可否 引越業者 */
  toritsugiHikkoshi: boolean;
  /** 反社会的勢力との関わりが無いことを誓約する */
  agreeHansha: boolean;
  /** 審査OKの場合は契約手続きを進めることを誓約する */
  agreeKeiyakuResuming: boolean;
  /** 審査NGの場合に意義申し立てをしないことを誓約する */
  agreeDisableReExamination: boolean;
  /** 連帯保証人への連絡があることを承諾する */
  agreeCallToRentaihoshonin: boolean;
  /** 家賃保証会社による審査を行うことを承諾する */
  agreeGuaranteeCompanyExamination: boolean;
  /** 仲介手数料を支払うことを承諾する */
  agreeChukaiTesuryo: boolean;
  /** 記載内容に虚偽が無いことを誓約する */
  agreeKisaiNaiyo: boolean;
  /** 管理会社の個人情報の取扱方針に同意する */
  agreeKojinJohoHogo: boolean;
  /** 保証会社の個人情報の取扱方針に同意する */
  agreeYachinHoshoPrivacyPolicy: boolean | null;
  /** 保証会社の確認・同意事項に同意する */
  agreeYachinHoshoConfirmation: boolean | null;
  /** アンケート この物件の気に入ったポイント */
  questionnaireGoodPoint: MultipleSelectionObj;
  questionnaireGoodPointText?: string;
  /** アンケート この物件で譲歩したポイント */
  questionnaireBadPoint: MultipleSelectionObj;
  questionnaireBadPointText?: string;
  /** アンケート この物件を知ったきっかけ */
  questionnaireTriggered: MultipleSelectionObj;
  questionnaireTriggeredText?: string;
  /** 法人申込者 */
  hojinMoshikomisha: Partial<IHozin>;
  /** 社宅代行会社 */
  shatakuDaikoKaisha: Partial<IShatakuDaikoKaisha>;
  /** 賃料 */
  chinryo: number | null;
  /** 共益費 */
  kyoekihi: number | null;
  /** 管理費 */
  kanrihi: number | null;
  /** 駐車場使用料 */
  parking: number | null; // TODO: その他費用から取得できるが、名寄せが必要で対応困難
  parkingLower: number | null;
  parkingUpper: number | null;
  suidouChokaiHi: number | null;
  /** 決済サービス料 現状表示してない */
  kessaiServiceRyo: number | null;
  /** その他月額費用内訳 */
  sonotaGetsugakuHiyoText: string;
  /** その他月額費用金額 */
  sonotaGetsugakuHiyo: number | null;
  /** 敷金金額 */
  shikikinAmount: number | null;
  /** 敷金単位 */
  shikikinUnit: unitTypes | null;
  /** 保証金 */
  hoshokin: number | null;
  /** 保証金単位 */
  hoshokinUnit: unitTypes | null;
  /** 敷引 */
  genjoKaifuku: number | null;
  /** 敷引単位 */
  shikibikiUnit: unitTypes | null;
  /** 償却金 */
  shokyakuKin: number | null;
  /** 償却金単位 */
  shokyakukinUnit: unitTypes | null;
  /** 礼金金額 */
  reikinAmount: number | null;
  /** 礼金単位 */
  reikinUnit: unitTypes | null;
  /** 仲介手数料金額 */
  chukaiTesuryoAmount: number | null;
  /** 仲介手数料単位 */
  chukaiTesuryoUnit: unitTypes | null;
  /** 仲介手数料消費税区分 */
  chukaiTesuryoTax: taxTypes | null;
  /** 仲介手数料負担割合(パーセント) 貸主 */
  chukaiTesuryoKashinushi: number | null;
  /** 仲介手数料負担割合(パーセント) 借主 */
  chukaiTesuryoKarinushi: number | null;
  /** 仲介手数料配分割合(パーセント) 元付 */
  chukaiTesuryoMotozuke: number | null;
  /** 仲介手数料配分割合(パーセント) 客付 */
  chukaiTesuryoKyakuzuke: number | null;
  /** その他初期費用内訳 */
  sonotaShokiHiyoText: string;
  /** その他初期費用金額 */
  sonotaShokiHiyo: number | null;
  /** 前家賃 メモ「20XX年〇月分まで受領予定」 */
  maeYachin: string;
  /** 物件用途 */
  yoto: bukkenYotoTypes;
  /** 物件用途詳細 */
  yotoDetail: bukkenYotoDetailTypes;
  /** 賃貸契約区分 */
  chintaiType: chintaiKeiyakuKubun | null;
  /** 定期借家契約 再契約型か否か */
  isSaikeiyakuTeikishakka: boolean;
  /** 解約予告 */
  kaiyakuYokoku: number;
  /** 解約予告単位 */
  kaiyakuYokokuUnit: kaiyakuYokokuUnitTypes;
  /** 賃料支払方法 */
  chinryoShiharaiType: chinryoShiharaiTypes | null;
  /** 賃料支払方法その他 */
  chinryoShiharaiOther: string | null;
  /** 賃料支払期限月 */
  chinryoShiharaiKigenMonth: string;
  /** 賃料支払期限日 */
  chinryoShiharaiKigenDate: string;
  /** 契約範囲 「区画で契約」のみ */
  keiyakuRange: string;
  /** OneのPDF備考 */
  b2bMessage: string;
  /** 更新料区分 */
  koshinryoType: koshinryoTypes | null;
  /** 更新料 */
  koshinryo: number | null;
  /** 更新料単位 */
  koshinryoUnit: unitTypes | null;
  /** 更新事務手数料 */
  koshinJimuTesuryo: number | null;
  /** 更新事務手数料単位 */
  koshinJimuTesuryoUnit: unitTypes | null;
  /** 家賃保証会社 */
  yachinHoshoKaisha: Partial<IYachinHoshoKaisha>;
  /** 管理会社指定の火災保険に加入するか */
  getDesignatedFireInsurance: boolean;
  /** 火災保険会社 */
  kasaiHokenKaisha: Partial<IKasaiHokenKaisha>;
  /** 既読ユーザーリスト */
  readAccounts: { [uid: string]: boolean };
  /** 対応が必要かどうか */
  isReactionNeeded: boolean;
  /** Open/Closeフラグ */
  isActive: boolean;
  /** 申込画面設定 */
  formSetting?: IDomainFormSettingValue<any>;
  /** 番手 */
  bante: number;
  /** 誓約日時 */
  seiyakuDatetime: Datetime | null;
  /** 本人確認チェック項目 */
  honninKakunin: Partial<IHonninKakunin>;
  /** 仲介会社様への確認項目 */
  isChukaiKakunin: boolean;
  /** 申込受付日 */
  reviewRequestedAt: Datetime;
  /** タグ */
  tag: MultipleSelectionObj;
  /** One顧客番号 */
  kokyakuNumber: string;
  /** 管理会社用タグIDリスト */
  kanrikaishaTags: string[];
  /** 仲介会社用タグIDリスト */
  chukaikaishaTags: string[];
  /** 編集ロック */
  isLock: boolean;
  /** エポス審査ステータス */
  apiStatusEpos: EposStatus;
  /** エポス連携回数 */
  eposSendNumber: number;
  /** 最終履歴コメント */
  latestLog: Partial<ILog>;
  /** 作成元サービスのドメイン */
  creatorService: string;
  /** 作成元サービスの物件詳細画面URL */
  bukkenDetailPageUrl: string | null;
  /** 審査時必要項目入力済フラグ */
  isEnteredReview: boolean;
  /** 入力締め切り日時 */
  deadlineDatetime: Datetime | null;
  /** 出力された帳票 */
  outputChohyo: { [key in IDomain['domainUID']]: string[] };
  /** 一括処理最終実行日時 */
  bulkUpdatedAt: ServerTimestamp;
  /** 入居予定者 */
  plannedResidents: plannedResidentsTypes;
  /** 入居予定者 */
  plannedResidentsSonotaText: string;
  /** 入居予定の総人数 */
  plannedResidentsTotal: number;
  /** 現在のステータスに移動した時間(splunk送信用項目) */
  previousStatusChangeTime: Datetime;
  /** 区画管理番号 */
  heyaKukakuKanriNumber: string | null;
}

/** 申込ステータス */
export const RequestStatus = {
  Preparation: 1,
  FillingIn: 2,
  Confirming: 3,
  WaitReviewed: 4,
  Reviewing: 5,
  Approval: 6,
  Disapproval: 7,
  Archived: 99
} as const;
export type RequestStatusKeys = keyof typeof RequestStatus;
export type RequestStatus = typeof RequestStatus[RequestStatusKeys];

export const requestStatusMap = new Map<RequestStatus, string>([
  [RequestStatus.Preparation, '下書き中'],
  [RequestStatus.FillingIn, '申込者記入中'],
  [RequestStatus.Confirming, '仲介確認中'],
  [RequestStatus.WaitReviewed, '審査待ち'],
  [RequestStatus.Reviewing, '審査中'],
  [RequestStatus.Approval, '審査OK/手続中'],
  [RequestStatus.Disapproval, '審査NG']
]);
/** サブステータス, ToDoで使用する親ステータスのkey */
export type ParentStatusKeys = Extract<
  RequestStatusKeys,
  | 'Preparation'
  | 'FillingIn'
  | 'Confirming'
  | 'WaitReviewed'
  | 'Reviewing'
  | 'Approval'
  | 'Disapproval'
>;

/** サブステータス, ToDoで使用する親ステータスのvalue */
export type ParentStatusValue = Extract<
  RequestStatus,
  1 | 2 | 3 | 4 | 5 | 6 | 7
>;

export const parentStatusKeys: ParentStatusKeys[] = [
  'Preparation',
  'FillingIn',
  'Confirming',
  'WaitReviewed',
  'Reviewing',
  'Approval',
  'Disapproval'
];

export const parentStatusValues: ParentStatusValue[] = [
  RequestStatus.Preparation,
  RequestStatus.FillingIn,
  RequestStatus.Confirming,
  RequestStatus.WaitReviewed,
  RequestStatus.Reviewing,
  RequestStatus.Approval,
  RequestStatus.Disapproval
];

export const requestStatusKeyMap = new Map<ParentStatusValue, ParentStatusKeys>(
  [
    [RequestStatus.Preparation, 'Preparation'],
    [RequestStatus.FillingIn, 'FillingIn'],
    [RequestStatus.Confirming, 'Confirming'],
    [RequestStatus.WaitReviewed, 'WaitReviewed'],
    [RequestStatus.Reviewing, 'Reviewing'],
    [RequestStatus.Approval, 'Approval'],
    [RequestStatus.Disapproval, 'Disapproval']
  ]
);

export const requestStatusValueMap = new Map<
  ParentStatusKeys,
  ParentStatusValue
>([
  ['Preparation', RequestStatus.Preparation],
  ['FillingIn', RequestStatus.FillingIn],
  ['Confirming', RequestStatus.Confirming],
  ['WaitReviewed', RequestStatus.WaitReviewed],
  ['Reviewing', RequestStatus.Reviewing],
  ['Approval', RequestStatus.Approval],
  ['Disapproval', RequestStatus.Disapproval]
]);

/** 非アクティブな申込ステータスの疑似プロパティ（物理削除は除いている） */
export const DeactiveRequestStatuses = [
  'cancel',
  'terminate',
  'archive'
] as const;
export type DeactiveRequestStatusType = typeof DeactiveRequestStatuses[number];
export const deactiveRequestStatusMap = new Map<
  DeactiveRequestStatusType,
  string
>([
  ['cancel', 'キャンセル'],
  ['terminate', '終了'],
  ['archive', '削除']
]);

export enum ReviewStatus {
  Reviewing = 1,
  Approval,
  Disapproval,
  Cancel,
  Pending
}

export const EposStatus = {
  Reviewing: 1,
  Approval: 2,
  Disapproval: 3,
  Cancel: 4
} as const;

export type EposStatus = typeof EposStatus[keyof typeof EposStatus];

export const EposToReviewStatusMap: Record<EposStatus, ReviewStatus> = {
  [EposStatus.Reviewing]: ReviewStatus.Reviewing,
  [EposStatus.Approval]: ReviewStatus.Approval,
  [EposStatus.Disapproval]: ReviewStatus.Disapproval,
  [EposStatus.Cancel]: ReviewStatus.Cancel
};

export const EsStandardReviewStatus = {
  Reviewing: 1,
  Approval: 2,
  Disapproval: 3,
  Cancel: 4,
  Pending: 5
} as const;
export type EsStandardReviewStatus = typeof EsStandardReviewStatus[keyof typeof EsStandardReviewStatus];

export const EsStandardFutaiToritsugiStatus = createConstDef<
  Record<string, number>
>()({
  Waiting: 1,
  Receiving: 2,
  Approval: 3,
  Disapproval: 4
} as const);
export type EsStandardFutaiToritsugiStatus = ValueOf<
  typeof EsStandardFutaiToritsugiStatus
>;

export const EsStandardToReviewStatusMap: Record<
  EsStandardReviewStatus,
  ReviewStatus
> = {
  [EsStandardReviewStatus.Reviewing]: ReviewStatus.Reviewing,
  [EsStandardReviewStatus.Approval]: ReviewStatus.Approval,
  [EsStandardReviewStatus.Disapproval]: ReviewStatus.Disapproval,
  [EsStandardReviewStatus.Cancel]: ReviewStatus.Cancel,
  [EsStandardReviewStatus.Pending]: ReviewStatus.Pending
};

export type EsStandardHojinType =
  | keyof Pick<IRequest, 'yachinHoshoKaisha' | 'kasaiHokenKaisha'>
  | 'futaiToritsugi'
  | 'kanriKaisha';

/** 入居者上限 */
export const NYUKYOSHA_LIMIT = Math.max(...NyukyoshaKeys);

/** 番手 選択可能上限 */
export const BANTE_LIMIT = 10;
/** 申込一括処理可能上限 */
export const BULK_PROCESSING_LIMIT = 100;
/** 申込CSV出力可能上限 */
// NOTE: もし上限引き上げの話が出たらfucntionsの同時実行可能上限数を考慮して、安易に引き上げずに何かしらの仕組みを検討する
export const REQUEST_CSV_LIMIT = 2000;

/** 賃貸契約区分 */
export enum chintaiKeiyakuKubun {
  普通借家契約 = '普通借家契約',
  定期借家契約 = '定期借家契約',
  利用契約 = '利用契約'
}

/** 賃料支払方法 */
export enum chinryoShiharaiTypes {
  accountTransfer = 1,
  creditCard = 2,
  bankTransfer = 3,
  other = 99
}

export const chinryoShiharaiTypesMap = new Map<
  Partial<chinryoShiharaiTypes>,
  string
>([
  [chinryoShiharaiTypes.accountTransfer, '口座振替'],
  [chinryoShiharaiTypes.creditCard, 'クレジットカード'],
  [chinryoShiharaiTypes.bankTransfer, '振込'],
  [chinryoShiharaiTypes.other, 'その他']
]);

export const chinryoShiharaiTypesMapSaizonEntetsu = new Map<
  Partial<chinryoShiharaiTypes>,
  string
>([
  [chinryoShiharaiTypes.accountTransfer, '口座振替'],
  [chinryoShiharaiTypes.creditCard, 'えんてつカード']
]);

export const chinryoShiharaiTypesMapSaizonHojin = new Map<
  Partial<chinryoShiharaiTypes>,
  string
>([
  [chinryoShiharaiTypes.accountTransfer, '口座振替'],
  [chinryoShiharaiTypes.bankTransfer, '振込']
]);

export const chinryoShiharaiTypesMapSaizonKojin = new Map<
  Partial<chinryoShiharaiTypes>,
  string
>([
  [chinryoShiharaiTypes.accountTransfer, '口座振替'],
  [chinryoShiharaiTypes.creditCard, 'クレジットカード']
]);

/** 区画種別タイプ */
export enum bukkenYotoTypes {
  jukyo = 1,
  parkingCar = 7
}

export const bukkenYotoTypesMap = new Map<bukkenYotoTypes, string>([
  [bukkenYotoTypes.jukyo, '住居'],
  [bukkenYotoTypes.parkingCar, '駐車場']
]);

export type BukkenYotoLabel = {
  label: string | undefined;
  icon: string;
  value: bukkenYotoTypes;
};

export const bukkenYotoLabels: BukkenYotoLabel[] = [
  {
    label: bukkenYotoTypesMap.get(bukkenYotoTypes.jukyo),
    icon: 'mdi-home',
    value: bukkenYotoTypes.jukyo
  },
  {
    label: bukkenYotoTypesMap.get(bukkenYotoTypes.parkingCar),
    icon: 'mdi-car',
    value: bukkenYotoTypes.parkingCar
  }
];

/** 車両種類 */
export enum vehicleTypes {
  car = 1,
  motorcicle = 2
}

export const vehicleTypesMap = new Map<vehicleTypes, string>([
  [vehicleTypes.car, '自動車'],
  [vehicleTypes.motorcicle, 'バイク']
]);

/** 区画種別詳細タイプ */
export enum bukkenYotoDetailTypes {
  jukyo = 1,
  familyJukyo,
  second,
  jukyoAndJimusho,
  jimusho,
  tempo,
  parkingCar,
  trunkRoom,
  soko
}

export const bukkenYotoTypesDetailMap = new Map<bukkenYotoDetailTypes, string>([
  [bukkenYotoDetailTypes.jukyo, '主たる住居'],
  [bukkenYotoDetailTypes.familyJukyo, '家族の住居'],
  [bukkenYotoDetailTypes.second, 'セカンドハウス'],
  [bukkenYotoDetailTypes.jukyoAndJimusho, '住居兼事務所'],
  [bukkenYotoDetailTypes.jimusho, '事務所'],
  [bukkenYotoDetailTypes.tempo, '店舗'],
  [bukkenYotoDetailTypes.parkingCar, '駐車場'],
  [bukkenYotoDetailTypes.trunkRoom, 'トランクルーム'],
  [bukkenYotoDetailTypes.soko, '倉庫']
]);

export const bukkenYotoDetailJukyo = [
  bukkenYotoDetailTypes.jukyo,
  bukkenYotoDetailTypes.familyJukyo,
  bukkenYotoDetailTypes.second,
  bukkenYotoDetailTypes.jukyoAndJimusho,
  bukkenYotoDetailTypes.jimusho,
  bukkenYotoDetailTypes.tempo,
  bukkenYotoDetailTypes.trunkRoom,
  bukkenYotoDetailTypes.soko
] as const;

export const bukkenYotoDetailParking = [
  bukkenYotoDetailTypes.parkingCar,
  bukkenYotoDetailTypes.trunkRoom,
  bukkenYotoDetailTypes.soko
] as const;

export function isNoneParkingCost(request: Partial<IRequest>): boolean {
  if (request.yoto === bukkenYotoTypes.parkingCar) {
    return false;
  }
  return (
    request.isUseParkingCars === false ||
    request.isAvailableParkingCars === false
  );
}

/** 月額費用(除:賃料)合計 */
export function getMonthlyPaymentEtc(payload: Partial<IRequest>) {
  if (!payload) return 0;
  const kyoekihi = payload.kyoekihi ? payload.kyoekihi : 0;
  const kanrihi = payload.kanrihi ? payload.kanrihi : 0;
  const suidouChokaiHi = payload.suidouChokaiHi ? payload.suidouChokaiHi : 0;
  const kessaiServiceRyo = payload.kessaiServiceRyo
    ? payload.kessaiServiceRyo
    : 0;
  const sonotaGetsugakuHiyo = payload.sonotaGetsugakuHiyo
    ? payload.sonotaGetsugakuHiyo
    : 0;
  const parking = isNoneParkingCost(payload) ? 0 : payload.parking ?? 0;
  return (
    kyoekihi +
    sonotaGetsugakuHiyo +
    suidouChokaiHi +
    kanrihi +
    kessaiServiceRyo +
    parking
  );
}

/** 月額費用合計 */
export function getMonthlyPaymentTotal(payload: Partial<IRequest>) {
  if (!payload) return 0;
  const chinryo = payload.chinryo ? payload.chinryo : 0;
  const etc = getMonthlyPaymentEtc(payload);
  return chinryo + etc;
}

export const UserRequestType = [
  'kojinHouse',
  'hojinHouse',
  'kojinParking',
  'hojinParking',
  'foreignerKojinHouse',
  'foreignerKojinParking',
  'foreignerHojinHouse',
  'foreignerHojinParking'
] as const;

export type UserRequestType = typeof UserRequestType[number];

export function getUserRequestTypeKey(
  kubun: kokyakuKubun,
  yoto: bukkenYotoTypes
): UserRequestType {
  if (kubun === kokyakuKubun.kojin) {
    if (yoto === bukkenYotoTypes.jukyo) {
      return 'kojinHouse';
    }
    if (yoto === bukkenYotoTypes.parkingCar) {
      return 'kojinParking';
    }
  }
  if (kubun === kokyakuKubun.hojin) {
    if (yoto === bukkenYotoTypes.jukyo) {
      return 'hojinHouse';
    }
    if (yoto === bukkenYotoTypes.parkingCar) {
      return 'hojinParking';
    }
  }
  if (kubun === kokyakuKubun.foreignerKojin) {
    if (yoto === bukkenYotoTypes.jukyo) {
      return 'foreignerKojinHouse';
    }
    if (yoto === bukkenYotoTypes.parkingCar) {
      return 'foreignerKojinParking';
    }
  }
  if (kubun === kokyakuKubun.foreignerHojin) {
    if (yoto === bukkenYotoTypes.jukyo) {
      return 'foreignerHojinHouse';
    }
    if (yoto === bukkenYotoTypes.parkingCar) {
      return 'foreignerHojinParking';
    }
  }
  return 'kojinHouse';
}

// 駐車場申込か
// TODO: 同様の処理を共通化する #1855
export const isParkingCarType = (request: Partial<IRequest>): boolean =>
  request.yoto === bukkenYotoTypes.parkingCar;

// 法人申込か
// TODO: 同様の処理を共通化する #1855
export const isHojin = (request: Partial<IRequest>): boolean => {
  const kubun = request.moshikomisha?.kokyakuKubun;
  if (!kubun) return false;
  return [kokyakuKubun.hojin, kokyakuKubun.foreignerHojin].includes(kubun);
};

// 外国人申込か
// TODO: 同様の処理を共通化する #1855
export const isForeigner = (request: {
  moshikomisha?: { kokyakuKubun?: kokyakuKubun | null };
}): boolean => {
  const kubun = request.moshikomisha?.kokyakuKubun;
  if (!kubun) return false;
  return [kokyakuKubun.foreignerKojin, kokyakuKubun.foreignerHojin].includes(
    kubun
  );
};

export enum questionnaireTriggered {
  Search = 1,
  Portal,
  Hp,
  Flyer,
  Magazine,
  Signboard,
  IntroduceChukai,
  IntroduceAcquaintance,
  Sns,
  Other = 99
}

export const questionnaireTriggeredMap = new Map<
  questionnaireTriggered,
  string
>([
  [questionnaireTriggered.Search, '検索（google、yahoo!ほか）'],
  [
    questionnaireTriggered.Portal,
    "ポータルサイト（SUUMO、HOME'S、athome、ほか）"
  ],
  [questionnaireTriggered.Hp, '弊社ホームページ、仲介会社ホームページ'],
  [questionnaireTriggered.Flyer, 'チラシ'],
  [questionnaireTriggered.Magazine, '住宅雑誌'],
  [questionnaireTriggered.Signboard, '現地看板'],
  [questionnaireTriggered.IntroduceChukai, '仲介会社からの紹介'],
  [questionnaireTriggered.IntroduceAcquaintance, '知人からの紹介'],
  [questionnaireTriggered.Sns, 'SNS（facebook、instagram、twitterなど）'],
  [questionnaireTriggered.Other, 'その他']
]);

export enum questionnaireItems {
  Environment = 1,
  DistanceFromStation,
  ConvenienceCommuting,
  ConvenienceLife,
  SchoolDistrict,
  Age,
  Exterior,
  CrimePrevention,
  DeliveryBox,
  Elevator,
  Sunny,
  View,
  FloorPlan,
  Area,
  Internet,
  AirConditioner,
  Interior,
  Storage,
  Kitchen,
  Bathroom,
  Rent,
  InitialCost,
  Parking,
  Pet,
  Management,
  Other = 99
}

export const questionnaireItemsMap = new Map<questionnaireItems, string>([
  [questionnaireItems.Environment, '周辺環境'],
  [questionnaireItems.DistanceFromStation, '駅からの距離'],
  [questionnaireItems.ConvenienceCommuting, '通勤・通学の利便性'],
  [questionnaireItems.ConvenienceLife, '生活の利便性'],
  [questionnaireItems.SchoolDistrict, '学区'],
  [questionnaireItems.Age, '築年数'],
  [questionnaireItems.Exterior, '外観'],
  [questionnaireItems.CrimePrevention, 'オートロックなど防犯'],
  [questionnaireItems.DeliveryBox, '宅配ボックス'],
  [questionnaireItems.Elevator, 'エレベーター'],
  [questionnaireItems.Sunny, '日当たり'],
  [questionnaireItems.View, '眺望'],
  [questionnaireItems.FloorPlan, '間取り'],
  [questionnaireItems.Area, '面積'],
  [questionnaireItems.Internet, 'インターネット'],
  [questionnaireItems.AirConditioner, 'エアコン'],
  [questionnaireItems.Interior, 'デザイン'],
  [questionnaireItems.Storage, '収納'],
  [questionnaireItems.Kitchen, 'キッチン'],
  [questionnaireItems.Bathroom, '浴室'],
  [questionnaireItems.Rent, '家賃'],
  [questionnaireItems.InitialCost, '初期費用'],
  [questionnaireItems.Parking, '駐車場・バイク置き場'],
  [questionnaireItems.Pet, 'ペット飼育'],
  [questionnaireItems.Management, '管理状況'],
  [questionnaireItems.Other, 'その他']
]);

export const getCategoryNameMap = (
  yoto: bukkenYotoTypes | undefined | null,
  kubun: kokyakuKubun | undefined | null
) => {
  const categoryNameMap = new Map<string, string>([
    ['hojinMoshikomisha', '法人申込者'],
    ['shatakuDaikoKaisha', '社宅代行会社'],
    ['moshikomisha', '申込者'],
    ['nyukyosha1', '入居者'],
    ['kinkyuRenrakusaki', '緊急連絡先'],
    ['hoshounin', '保証人']
  ]);

  if (yoto === bukkenYotoTypes.parkingCar) {
    categoryNameMap.set('nyukyosha1', '利用者');
  }
  if (
    kubun &&
    [kokyakuKubun.foreignerKojin, kokyakuKubun.foreignerHojin].includes(kubun)
  ) {
    categoryNameMap.set('hoshounin', '家族緊急連絡先');
    categoryNameMap.set('kinkyuRenrakusaki', '国内緊急連絡先');
  }

  return categoryNameMap;
};

/** 定期借家契約の再契約型名を取得する */
export const getTeikishakkaSaikeiyakuTypeName = (
  chintaiType: string | null | undefined,
  isSaikeiyaku: boolean | undefined
) => {
  if (isSaikeiyaku === undefined || chintaiType !== '定期借家契約') return '';
  return isSaikeiyaku ? '再契約型' : '非再契約型';
};

export type KokyakuLinkageProps = keyof Pick<
  IRequest,
  | 'hojinMoshikomisha'
  | 'shatakuDaikoKaisha'
  | 'moshikomisha'
  | 'nyukyosha1'
  | 'nyukyosha2'
  | 'nyukyosha3'
  | 'nyukyosha4'
  | 'nyukyosha5'
  | 'kinkyuRenrakusaki'
  | 'hoshounin'
>;

export const DEFAULT_REVIEW_FORM_DEADLINE: number = 72;

export enum plannedResidentsTypes {
  Moshikomisha = 1,
  MoshikomishaAndOther,
  Other,
  Daihyosha,
  DaihyoshaAndFamily,
  Employee,
  EmployeeAndFamily,
  HojinOther
}

export const plannedResidentsTypesMapKojin = new Map<
  plannedResidentsTypes,
  string
>([
  [plannedResidentsTypes.Moshikomisha, '申込者本人のみ'],
  [plannedResidentsTypes.MoshikomishaAndOther, '申込者本人と同居人'],
  [plannedResidentsTypes.Other, '申込者本人以外の入居者']
]);

export const plannedResidentsTypesMapHojin = new Map<
  plannedResidentsTypes,
  string
>([
  [plannedResidentsTypes.Daihyosha, '代表者本人のみ'],
  [plannedResidentsTypes.DaihyoshaAndFamily, '代表者本人および家族'],
  [plannedResidentsTypes.Employee, '社員'],
  [plannedResidentsTypes.EmployeeAndFamily, '社員および家族'],
  [plannedResidentsTypes.HojinOther, 'その他']
]);

export const plannedResidentsTypesMap = new Map<plannedResidentsTypes, string>([
  ...plannedResidentsTypesMapKojin,
  ...plannedResidentsTypesMapHojin
]);

/** 更新料区分 */
export enum koshinryoTypes {
  new = 1,
  old
}

export const koshinryoTypesMap = new Map<koshinryoTypes, string>([
  [koshinryoTypes.new, '新賃料'],
  [koshinryoTypes.old, '旧賃料']
]);
