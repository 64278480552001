import {
  collection,
  CollectionReference,
  onSnapshot,
  Query,
  Unsubscribe
} from 'firebase/firestore';
import { isDoc } from 'requestform-types/lib/TypeGuard';
import { Actions, Getters, Mutations } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import { appLogger } from '@/utilities/appLogger';

type FirestoreCollectionRefLike = Query | CollectionReference;
type Child<T> = { unsubscribe: Unsubscribe; data?: T };

export class FirestoreCollectionState<T extends {}> {
  data: T[] = [];
  ref: FirestoreCollectionRefLike | null = null;
  handler: any = {};
  isLoaded: boolean = false;
  children: { [K in keyof T]?: Child<T[K]> }[] = [];
  unSubscribe: (() => void) | null = null;
}

export class FirestoreCollectionGetters<
  T extends {},
  S extends FirestoreCollectionState<T>
> extends Getters<S> {
  get getData() {
    return this.state.data;
  }

  get getRef() {
    return this.state.ref;
  }

  get getIsLoaded() {
    return this.state.isLoaded;
  }
}

export class FirestoreCollectionMutations<
  T extends {},
  S extends FirestoreCollectionState<T>
> extends Mutations<S> {}

export class FirestoreCollectionActions<
  T extends {},
  S extends FirestoreCollectionState<T>,
  G extends FirestoreCollectionGetters<T, S>,
  M extends FirestoreCollectionMutations<T, S>
> extends Actions<S, G, M, FirestoreCollectionActions<T, S, G, M>> {
  setRef(ref: FirestoreCollectionRefLike): Promise<number> {
    // TODO: バインディング時間をここで計測する必要があるかは検討
    const startTime = performance.now();
    if (ref !== this.state.ref) this.unsetRef();
    this.state.ref = ref;

    return new Promise((resolve, reject) => {
      this.state.unSubscribe = onSnapshot(ref, {
        next: snap => {
          // NOTE: 各ドキュメントに参照型フィールドが入っていなことを前提としている
          this.state.data = snap.docs.map(s => s.data() as T);
          this.state.isLoaded = true;
          const duration = Math.floor(performance.now() - startTime);
          resolve(duration);
        },
        error: error => {
          appLogger.error(`Failed onSnapshot ${ref.type}`, error);
          reject(error);
        }
      });
    });
  }
  protected setChildRefs(data: T): (Promise<Partial<T>> | Partial<T>)[] {
    // NOTE: 参照型フィールドが1階層目にしかないことを前提に最適化している
    return (Object.entries(data) as [keyof T, T[keyof T]][]).reduce(
      (ret, [key, v], i) => {
        if (isDoc(v)) {
          if (this.state.children[i]?.[key]?.data) {
            ret.push({
              [key]: this.state.children[i][key]?.data
            } as Partial<T>);
            return ret;
          }
          ret.push(
            new Promise<Partial<T>>((resolve, reject) => {
              this.state.children[i] = {
                ...this.state.children[i],
                [key]: {
                  unsubscribe: onSnapshot(v, {
                    next: snap => {
                      const childData = snap.data() as T[typeof key];
                      // NOTE: この時点では this.state.children[key] が必ず存在しているため as で解決
                      (this.state.children[i][key] as Child<
                        T[typeof key]
                      >).data = childData;
                      this.state.data.splice(i, 1, { ...data, ...childData });
                      resolve({ [key]: childData } as Partial<T>);
                    },
                    error: error => {
                      reject(
                        new Error(`Failed onSnapshot ${v.path}: ${error}`)
                      );
                    }
                  })
                }
              };
            })
          );
          return ret;
          // TODO: 参照型フィールドが削除された場合にバインディングを解除するようにする
        }
        return ret;
      },
      [] as (Promise<Partial<T>> | Partial<T>)[]
    );
  }
  setRefWithChildren(ref: FirestoreCollectionRefLike): Promise<number> {
    this.state.isLoaded = false;
    // TODO: バインディング時間をここで計測する必要があるかは検討
    const startTime = performance.now();
    if (ref !== this.state.ref) this.unsetRef();
    this.state.ref = ref;

    return new Promise((resolve, reject) => {
      const dataArray = [] as T[];
      this.state.unSubscribe = onSnapshot(ref, {
        next: async snap => {
          const docs = snap.docs;
          this.state.data = await Promise.all(
            docs.map(doc => {
              const data = doc.data() as T;
              return Promise.all(this.setChildRefs(data)).then(children => {
                const childrenData = children.reduce((ret, child) => {
                  return { ...ret, ...child };
                }, {});
                return {
                  ...data,
                  ...childrenData
                };
              });
            })
          );
          this.state.isLoaded = true;
          const duration = Math.floor(performance.now() - startTime);
          resolve(duration);
        },
        error: error => {
          appLogger.error(`Failed onSnapshot ${ref.type}`, error);
          reject(error);
        }
      });
      this.state.data = dataArray;
    });
  }
  unsetChildrenRef() {
    this.state.children.forEach(child => {
      if (!child) return;
      (Object.values(child) as Child<unknown>[]).forEach(
        ({ unsubscribe }) => typeof unsubscribe === 'function' && unsubscribe()
      );
    });
  }
  unsetRef() {
    if (this.state.unSubscribe) {
      this.state.unSubscribe();
      this.unsetChildrenRef();
    }
    if (this.state.unSubscribe) {
      this.state.unSubscribe();
    }
    this.state.data = [];
    this.state.ref = null;
    this.state.isLoaded = false;
    this.state.children = [];
    this.state.unSubscribe = null;
  }

  setCollectionRef(collectionPath: string) {
    const cRef = collection(db, collectionPath);
    return this.setRef(cRef);
  }
}
