import { getCurrentInstance } from '@vue/composition-api';
import { CombinedVueInstance } from 'vue/types/vue';

export type InstanceProxy<T extends Vue = Vue> = CombinedVueInstance<
  T,
  object,
  object,
  object,
  Record<never, any>
>;

export const useInstanceProxy = <T extends Vue = Vue>() => {
  const instance = getCurrentInstance();

  // NOTE: `setup()`外で使用するとインスタンスは取得できません
  // https://v3.vuejs.org/api/composition-api.html#getcurrentinstance
  if (!instance) {
    throw new Error(`Should be used in setup().`);
  }

  // NOTE: proxyが従来の`this`にあたるコンポーネントインスタンス
  return instance.proxy as InstanceProxy<T>;
};
