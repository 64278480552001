import {
  orderBy,
  OrderByDirection,
  QueryConstraint,
  Timestamp,
  where
} from 'firebase/firestore';
import moment from 'moment-timezone';
import { DateString } from 'requestform-types/lib/DateTimeString';
import { isDefined, isString } from 'requestform-types/lib/TypeGuard';
import { CheckKeys } from 'requestform-types/lib/utilities';
import { Dictionary } from 'vue-router/types/router';

import { isDateString } from './searchConditionsBase';

export type Converter<T extends {}, U extends keyof T> = {
  toQuery: (v: Partial<T>) => string | string[] | undefined;
  fromQuery: (v: Dictionary<string | (string | null)[]>) => T[U] | undefined;
  toSearchQuery: (v: Partial<T>) => QueryConstraint[] | undefined;
};

export const isOrder = (v: unknown): v is OrderByDirection =>
  isString(v) && (v === 'asc' || v === 'desc');
export type OrderedBy = `${SortableKey}.${OrderByDirection}`;

// 検索条件に変更があった場合はまずここを修正する
export const SearchConditionKeys = [
  'name',
  'dueDateStart',
  'dueDateEnd',
  'tantosha',
  'isCompleted',
  'isActive',
  'orderedBy'
] as const;
export type SearchConditionKeys = typeof SearchConditionKeys[number];
export type SearchConditions = CheckKeys<
  {
    name: string[];
    dueDateStart: DateString;
    dueDateEnd: DateString;
    tantosha: string[];
    isCompleted: boolean;
    isActive: boolean;
    orderedBy: OrderedBy;
  },
  SearchConditionKeys
>;

export const sortableKey = ['dueDateTime'] as const;
export type SortableKey = typeof sortableKey[number];
export const isSortableKey = (v: unknown): v is SortableKey =>
  isString(v) && sortableKey.some(key => key === v);

export const isOrderedBy = (v: unknown): v is OrderedBy => {
  if (isString(v) && v.length !== 2) {
    const [key, order] = v.split('.');
    return isSortableKey(key) && isOrder(order);
  } else {
    return false;
  }
};

export const splitOrderedBy = (v: OrderedBy | undefined) => {
  if (!v) {
    return ['dueDateStart', 'desc'] as [SearchConditionKeys, OrderByDirection];
  }
  return v.split('.') as [SearchConditionKeys, OrderByDirection];
};

export const converter: {
  [K in SearchConditionKeys]: Converter<SearchConditions, K>;
} = {
  name: {
    toQuery: v => v.name,
    fromQuery: ({ name }) => {
      if (isString(name)) return [name];
      if (Array.isArray(name)) return name.filter(isDefined);
    },
    toSearchQuery: ({ name }) => {
      if (!name || name.length === 0) return undefined;
      return [where('name', 'in', name)];
    }
  },
  dueDateStart: {
    toQuery: v => v.dueDateStart,
    fromQuery: ({ dueDateStart }) =>
      isDateString(dueDateStart) ? dueDateStart : undefined,
    toSearchQuery: ({ dueDateStart }) => {
      if (!isDateString(dueDateStart)) return undefined;
      return [
        where(
          'dueDateTime',
          '>=',
          Timestamp.fromDate(moment(dueDateStart).toDate())
        )
      ];
    }
  },
  dueDateEnd: {
    toQuery: v => v.dueDateEnd,
    fromQuery: ({ dueDateEnd }) =>
      isDateString(dueDateEnd) ? dueDateEnd : undefined,
    toSearchQuery: ({ dueDateEnd }) => {
      if (!isDateString(dueDateEnd)) return undefined;
      return [
        where(
          'dueDateTime',
          '<=',
          Timestamp.fromDate(moment(dueDateEnd).toDate())
        )
      ];
    }
  },
  tantosha: {
    toQuery: v => v.tantosha,
    fromQuery: ({ tantosha }) => {
      if (isString(tantosha)) return [tantosha];
      if (Array.isArray(tantosha)) return tantosha.filter(isDefined);
    },
    toSearchQuery: ({ tantosha }) => {
      if (!tantosha || tantosha.length === 0) return undefined;
      return [where('tantosha', 'array-contains-any', tantosha)];
    }
  },
  isCompleted: {
    toQuery: v => {
      if (v.isCompleted === true) return 'true';
      else if (v.isCompleted === false) return 'false';
    },
    fromQuery: v => {
      if (v.isCompleted === 'true') return true;
      else if (v.isCompleted === 'false') return false;
    },
    toSearchQuery: ({ isCompleted }) => {
      if (isCompleted == undefined) return undefined;
      return [where('isCompleted', '==', isCompleted)];
    }
  },
  isActive: {
    toQuery: v => {
      if (v.isActive === true) return 'true';
    },
    fromQuery: v => {
      if (v.isActive === 'true') return true;
    },
    toSearchQuery: ({ isActive }) => {
      if (!isActive) return undefined;
      return [where('request.isActive', '==', true)];
    }
  },
  orderedBy: {
    toQuery: v => v.orderedBy,
    fromQuery: ({ orderedBy }) =>
      isOrderedBy(orderedBy) ? orderedBy : undefined,
    toSearchQuery: ({ orderedBy }) => {
      // NOTE: デフォルトは期日の降順でソートする
      if (!isOrderedBy(orderedBy)) return [orderBy('dueDateTime', 'desc')];
      const [key, order] = splitOrderedBy(orderedBy);
      return [orderBy(key, order)];
    }
  }
};

export const fromQuery = (
  query: Dictionary<string | (string | null)[]>
): Partial<SearchConditions> =>
  SearchConditionKeys.reduce((s, k) => {
    const condition = converter[k].fromQuery(query);
    if (!isDefined(condition)) return s;
    return {
      ...s,
      [k]: condition
    };
  }, {} as Partial<SearchConditions>);

export const toQuery = (
  searchConditions: Partial<SearchConditions>
): Record<SearchConditionKeys, string | (string | null)[]> =>
  SearchConditionKeys.reduce((q, k) => {
    const condition = converter[k].toQuery(searchConditions);
    if (!isDefined(condition)) return q;
    return {
      ...q,
      [k]: condition
    };
  }, {} as Record<SearchConditionKeys, string | (string | null)[]>);

export const toSearchQuery = (
  searchConditions: Partial<SearchConditions>
): QueryConstraint[] =>
  SearchConditionKeys.map(v => {
    const query = converter[v].toSearchQuery(searchConditions);
    if (!isDefined(query)) return undefined;
    return query;
  })
    .flat()
    .filter(isDefined);
