import { Store } from 'vuex';

import { DomainSettingDocumentModule } from '@/requestform/store/DomainSettingDocumentModule';
import { SignInModule } from '@/requestform/store/SignInModule';
import { getIsAllowIp } from '@/utilities/firebaseFunctions';

import { appLogger, parseError } from './appLogger';

/** IPアドレス制限設定状況を取得 */
const getIsIpRestriction = async (store: Store<any>) => {
  const domainSettingCtx = DomainSettingDocumentModule.context(store);
  if (!domainSettingCtx.getters.getRef) {
    const domainUID = SignInModule.context(store).getters.domainUID;
    await domainSettingCtx.actions
      .setDomainDocumentRef(domainUID)
      .catch(e => appLogger.error(e));
  }
  return domainSettingCtx.getters.getIpRestriction;
};

/**
 * アクセスが許可されているか判定する
 * IPアドレス制限が設定されている場合は、functions 経由でIPアドレスが許可対象に含まれているか確認する
 * @param store Vuexのデータストア
 */
export const getIsAccessAllowed = async (store: Store<any>) => {
  // NOTE: IPアドレス制限が有効でなければIP制限をしない
  const isIpRestriction = await getIsIpRestriction(store);
  if (!isIpRestriction) return true;

  // NOTE: IPアドレスの許可判定を functions に移譲
  const isAllowIpAddress: boolean = await getIsAllowIp()
    .then(r => {
      return r.data;
    })
    .catch(e => {
      appLogger.error('Error check ip address', parseError(e));
      return false;
    });
  return isAllowIpAddress;
};
