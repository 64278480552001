
<v-app>
  <v-overlay
    :z-index="999"
    :value="$loading.state || !getIsAuthResolved"
    :absolute="$loading.absolute"
  >
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
  <latest-version-snack-bar :show="showLatestVersionSnackBar" />
  <div class="information-dialog" style="z-index: 998"></div>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
  <v-dialog
    :value="getIsOpenHojinSelectDialog"
    width="800"
    @input="setIsOpenHojinSelectDialog"
  >
    <hojin-select-dialog v-if="getIsOpenHojinSelectDialog" />
  </v-dialog>
</v-app>
