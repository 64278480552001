import { computed, ComputedRef } from '@vue/composition-api';
import { pick } from 'lodash-es';
import { Actions, Getters, Module, Mutations } from 'vuex-smart-module';
import { Dispatcher } from 'vuex-smart-module/lib/assets';

import { useInstanceProxy } from './useInstanceProxy';

export const useVuexGetters = <
  S extends {},
  G extends Getters<S>,
  M extends Mutations<S>,
  A extends Actions<S, G, M, A> | Actions<S, G, M>,
  Keys extends (keyof G & string)[]
>(
  module: Module<S, G, M, A>,
  keys: Keys
): { [K in Keys[number]]: ComputedRef<G[K]> } => {
  const { $store } = useInstanceProxy();
  const { getters } = module.context($store);
  return keys.reduce((r, k) => {
    return { ...r, [k]: computed(() => getters[k]) };
  }, {} as { [K in Keys[number]]: ComputedRef<G[K]> });
};

export const useVuexActions = <
  S extends {},
  G extends Getters<S>,
  M extends Mutations<S>,
  A extends Actions<S, G, M, A> | Actions<S, G, M>,
  Keys extends (keyof Dispatcher<A> & string)[]
>(
  module: Module<S, G, M, A>,
  keys: Keys
): { [K in Keys[number]]: Dispatcher<A>[K] } => {
  const { $store } = useInstanceProxy();
  const { actions } = module.context($store);
  return pick(actions, keys);
};
