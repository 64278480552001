import { serverTimestamp, setDoc } from '@firebase/firestore';
import { IDocumentBase, RequestAttachedData } from 'requestform-types';
import { requestAttachedDataDocumentPath } from 'requestform-types/lib/FirestorePath';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';

class RequestAttachedDataDocumentState extends FirestoreDocumentState<RequestAttachedData> {}

export class RequestAttachedDataDocumentGetters extends FirestoreDocumentGetters<
  RequestAttachedData,
  RequestAttachedDataDocumentState
> {}

class RequestAttachedDataDocumentMutations extends FirestoreDocumentMutations<
  RequestAttachedData,
  RequestAttachedDataDocumentState
> {}

export class RequestAttachedDataDocumentActions extends FirestoreDocumentActions<
  RequestAttachedData,
  RequestAttachedDataDocumentState,
  RequestAttachedDataDocumentGetters,
  RequestAttachedDataDocumentMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  setRequestAttachedDataDocumentRef(payload: {
    domainUID: string;
    requestUID: string;
  }) {
    this.setDocumentRef(
      requestAttachedDataDocumentPath(payload.domainUID, payload.requestUID)
    );
  }

  save(payload: Partial<Exclude<RequestAttachedData, keyof IDocumentBase>>) {
    if (!this.getters.getRef) {
      throw new Error('"ref" is undefined. Please call it after binding.');
    }
    const updateData: RequestAttachedData = {
      comment: payload.comment ?? '',
      modifiedAt: serverTimestamp(),
      modifierUID: this.signInCtx.getters.accountUID
    };
    return setDoc(this.getters.getRef, updateData);
  }
}

export const RequestAttachedDataDocumentModule = new Module({
  state: RequestAttachedDataDocumentState,
  getters: RequestAttachedDataDocumentGetters,
  mutations: RequestAttachedDataDocumentMutations,
  actions: RequestAttachedDataDocumentActions
});
