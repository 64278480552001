import {
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  serverTimestamp,
  setDoc
} from 'firebase/firestore';
import { ITag } from 'requestform-types';
import { domainTagCollectionPath } from 'requestform-types/lib/FirestorePath';
import { DisplayTagType, tagColorsMap } from 'requestform-types/lib/ITag';
import { Store } from 'vuex';
import { Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';

class DomainTagCollectionState extends FirestoreCollectionState<ITag> {}

export class DomainTagCollectionGetters extends FirestoreCollectionGetters<
  ITag,
  DomainTagCollectionState
> {}

class DomainTagCollectionMutations extends FirestoreCollectionMutations<
  ITag,
  DomainTagCollectionState
> {}

class DomainTagCollectionActions extends FirestoreCollectionActions<
  ITag,
  DomainTagCollectionState,
  DomainTagCollectionGetters,
  DomainTagCollectionMutations
> {
  store!: Store<any>;
  $init(store: Store<any>): void {
    this.store = store;
  }

  getDisplayDomainTagsByUIDs(UIDs: string[]): DisplayTagType[] {
    const domainTags = this.getters.getData;
    const displayTags = domainTags
      .filter((d: ITag) => UIDs.includes(d.tagUID))
      .map((t: ITag) => ({
        text: t.name,
        color: tagColorsMap.get(t.color as number)
      }));
    return displayTags as DisplayTagType[];
  }

  async update(payload: { domainUID: string; data: Partial<ITag> }) {
    const { domainUID, data } = payload;
    if (!domainUID) {
      return Promise.reject('domainUID does not exits');
    }
    const collectionRef = collection(db, domainTagCollectionPath(domainUID));
    if (!data.tagUID) {
      const tagUID = doc(collectionRef).id;
      data.tagUID = tagUID;
      data.createdAt = serverTimestamp();
    } else {
      delete data.createdAt;
    }
    await setDoc(doc(collectionRef, data.tagUID), data, { merge: true }).catch(
      e => {
        return Promise.reject(e);
      }
    );
  }

  async delete(payload: { domainUID: string; tagUID: string }) {
    const { domainUID, tagUID } = payload;
    if (!domainUID) {
      return Promise.reject('domainUID does not exits');
    }
    if (!tagUID) {
      return Promise.reject('tagUID does not exits');
    }
    const docRef = doc(
      collection(db, domainTagCollectionPath(domainUID)),
      tagUID
    );
    await deleteDoc(docRef);
  }

  setDomainTagCollectionRef(domainUID: string) {
    if (domainUID && !this.state.ref) {
      const ref = query(
        collection(db, domainTagCollectionPath(domainUID)),
        orderBy('name', 'asc')
      );
      this.setRef(ref);
    }
  }
}

export const DomainTagCollectionModule = new Module({
  state: DomainTagCollectionState,
  getters: DomainTagCollectionGetters,
  mutations: DomainTagCollectionMutations,
  actions: DomainTagCollectionActions
});
