import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc
} from 'firebase/firestore';
import { IChohyoTemplate } from 'requestform-types';
import { domainChohyoTemplatePath } from 'requestform-types/lib/FirestorePath';
import { Store } from 'vuex';
import { Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';

class ChohyoTemplateCollectionState extends FirestoreCollectionState<IChohyoTemplate> {}

class ChohyoTemplateCollectionGetters extends FirestoreCollectionGetters<
  IChohyoTemplate,
  ChohyoTemplateCollectionState
> {
  store!: Store<any>;
  $init(store: Store<any>): void {
    this.store = store;
  }
}

class ChohyoTemplateCollectionMutations extends FirestoreCollectionMutations<
  IChohyoTemplate,
  ChohyoTemplateCollectionState
> {}

// type guard
export function isChohyoTemplate(data: any): data is IChohyoTemplate {
  return data && data.templateUID;
}

export class ChohyoTemplateCollectionActions extends FirestoreCollectionActions<
  IChohyoTemplate,
  ChohyoTemplateCollectionState,
  ChohyoTemplateCollectionGetters,
  ChohyoTemplateCollectionMutations
> {
  async getDomainChohyoTemplates(domainUID: string) {
    const templates: IChohyoTemplate[] = [];
    const snaps = await getDocs(
      query(
        collection(db, domainChohyoTemplatePath(domainUID)),
        orderBy('name', 'asc')
      )
    );
    snaps.forEach(t => {
      const template = t.data();
      if (isChohyoTemplate(template)) {
        templates.push(template);
      }
    });
    return templates;
  }

  async updateChohyoTemplatesComment(payload: {
    domainUID: string;
    chohyoTemplate: Partial<IChohyoTemplate>;
  }) {
    const snap = await getDoc(
      doc(
        collection(db, domainChohyoTemplatePath(payload.domainUID)),
        payload.chohyoTemplate.templateUID
      )
    );
    if (!snap.exists()) {
      throw Error('Firestoreからの帳票テンプレート情報取得に失敗');
    }
    await updateDoc(snap.ref, {
      comment: payload.chohyoTemplate.comment || ''
    });
  }
}

export const ChohyoTemplateCollectionModule = new Module({
  state: ChohyoTemplateCollectionState,
  getters: ChohyoTemplateCollectionGetters,
  mutations: ChohyoTemplateCollectionMutations,
  actions: ChohyoTemplateCollectionActions
});
