import {
  chukaiKaishaDisplaySystemTags,
  chukaiKaishaSearchSystemTags,
  DisplayTagType,
  eposSystemTags,
  SystemTags,
  SystemTagsMap
} from 'requestform-types/lib/ITag';
import {
  getNumberKeys,
  MultipleSelectionObj
} from 'requestform-types/lib/MultipleSelection';
import {
  isDefined,
  isMultipleSelectionObj
} from 'requestform-types/lib/TypeGuard';

export type SearchTagType = {
  text: string;
  value: string;
};

export function getSystemTagKeys(tag: MultipleSelectionObj): number[] {
  if (tag && isMultipleSelectionObj(tag)) {
    return getNumberKeys(tag, true);
  }
  return [];
}

export const getDisplaySystemTags: (payload: {
  systemTags: MultipleSelectionObj | undefined;
  isKanriKaisha: boolean;
}) => DisplayTagType[] = payload => {
  const { systemTags, isKanriKaisha } = payload;
  if (!systemTags) {
    return [];
  }
  const keys = getSystemTagKeys(systemTags);
  return keys
    .filter(
      tag =>
        isKanriKaisha ||
        (chukaiKaishaDisplaySystemTags as number[]).includes(tag)
    )
    .map(k => SystemTagsMap.get(k))
    .filter(isDefined);
};

export const getSearchSystemTags: (payload: {
  isKanriKaisha: boolean;
  hasToEposLicense: boolean;
  hasBizSupportLicense: boolean;
}) => SearchTagType[] = payload => {
  const { isKanriKaisha, hasToEposLicense, hasBizSupportLicense } = payload;
  const tags = Array.from(SystemTagsMap.entries())
    .filter(([k]) => {
      if (
        !isKanriKaisha &&
        !(chukaiKaishaSearchSystemTags as number[]).includes(k)
      )
        return false;
      if (!hasToEposLicense && (eposSystemTags as number[]).includes(k))
        return false;
      if (!hasBizSupportLicense && k === SystemTags.LifeinLinked) return false;
      return true;
    })
    .map(([k, v]) => ({
      text: v.text,
      value: k.toString()
    }));
  return tags;
};
