import {
  collection,
  doc,
  orderBy,
  query,
  QueryConstraint,
  setDoc,
  Timestamp,
  where
} from 'firebase/firestore';
import moment from 'moment';
import { DateString } from 'requestform-types/lib/DateTimeString';
import {
  domainFutaiToritsugiCollectionPath,
  domainFutaiToritsugiDocumentPath
} from 'requestform-types/lib/FirestorePath';
import { ToritsugiStatus } from 'requestform-types/lib/IFutaiToritsugi';
import {
  isDefined,
  isTimestamp,
  PartialRequired
} from 'requestform-types/lib/TypeGuard';
import { CheckKeys } from 'requestform-types/lib/utilities';
import { IFutaiToritsugi } from 'requestform-types/src';
import { GetUserNameMapByAccountUIDsResult } from 'requestform-types/src/functionsPayload';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';
import { getUserNameMapByAccountUIDs } from '@/utilities/firebaseFunctions';

import { OneGuidNameMap } from '../bizSupport/useOneOrganization';
import { SignInModule } from '../SignInModule';

export type UserNameMap = Exclude<
  GetUserNameMapByAccountUIDsResult,
  undefined
> | null;

export const SearchConditionKeys = [
  'kokyakuName',
  'kokyakuNameKana',
  'tantoOrganizationGuid',
  'tantoUserGuid',
  'toritsugiStatusCode',
  'createdAtFrom',
  'createdAtTo',
  'lastCallTimeFrom',
  'lastCallTimeTo',
  'callResult'
] as const;
export type SearchConditionKeys = typeof SearchConditionKeys[number];
export type SearchConditions = CheckKeys<
  {
    kokyakuName?: string;
    kokyakuNameKana?: string;
    tantoOrganizationGuid?: string;
    tantoUserGuid?: string;
    toritsugiStatusCode?: ToritsugiStatus[];
    createdAtFrom?: DateString;
    createdAtTo?: DateString;
    lastCallTimeFrom?: DateString;
    lastCallTimeTo?: DateString;
    callResult?: string;
  },
  SearchConditionKeys
>;

export const getCreatorName = (
  futaiToritsugi: IFutaiToritsugi,
  userNameMap: UserNameMap,
  oneUserGuidMap: OneGuidNameMap
) => {
  if (futaiToritsugi.creatorUID && userNameMap?.[futaiToritsugi.creatorUID]) {
    return userNameMap?.[futaiToritsugi.creatorUID].userName ?? '';
  } else if (futaiToritsugi.creatorGuid) {
    return oneUserGuidMap[futaiToritsugi.creatorGuid] ?? '';
  } else {
    return '';
  }
};
export const getTantoUserName = (
  futaiToritsugi: IFutaiToritsugi,
  oneUserGuidMap: OneGuidNameMap
) => {
  if (futaiToritsugi.tantoUserGuid) {
    return oneUserGuidMap[futaiToritsugi.tantoUserGuid] ?? '';
  } else {
    return '';
  }
};
export const getTantoOrganizationName = (
  futaiToritsugi: IFutaiToritsugi,
  oneOrganizationGuidMap: OneGuidNameMap
) => {
  if (futaiToritsugi.tantoOrganizationGuid) {
    return oneOrganizationGuidMap[futaiToritsugi.tantoOrganizationGuid] ?? '';
  } else {
    return '';
  }
};

class FutaiToritsugiCollectionState extends FirestoreCollectionState<IFutaiToritsugi> {
  userNameMap: UserNameMap | null = null;
  searchConditions: Partial<SearchConditions> = {};
  // userNameMapの取得も含めたloadedフラグ
  isDataLoaded: boolean = false;
}

export class FutaiToritsugiCollectionGetters extends FirestoreCollectionGetters<
  IFutaiToritsugi,
  FutaiToritsugiCollectionState
> {
  get getIsLoaded() {
    return this.state.isDataLoaded;
  }
  get getUserNameMap() {
    return this.state.userNameMap;
  }
  get searchConditions() {
    return this.state.searchConditions;
  }
  get getFilteredList() {
    let target = this.state.data;
    if (this.searchConditions.kokyakuName) {
      const filter = this.searchConditions.kokyakuName.toLowerCase();
      target = target.filter(f =>
        f.kokyakuName?.toLocaleLowerCase().includes(filter)
      );
    }

    if (this.searchConditions.kokyakuNameKana) {
      const filter = this.searchConditions.kokyakuNameKana.toLowerCase();
      target = target.filter(f =>
        f.kokyakuNameKana?.toLocaleLowerCase().includes(filter)
      );
    }

    if (this.searchConditions.tantoOrganizationGuid) {
      const filter = this.searchConditions.tantoOrganizationGuid;
      target = target.filter(f => f.tantoOrganizationGuid === filter);
    }

    if (this.searchConditions.tantoUserGuid) {
      const filter = this.searchConditions.tantoUserGuid;
      target = target.filter(f => f.tantoUserGuid === filter);
    }

    if (this.searchConditions.toritsugiStatusCode?.length) {
      const filter = this.searchConditions.toritsugiStatusCode;
      target = target.filter(
        f => f.toritsugiStatusCode && filter.includes(f.toritsugiStatusCode)
      );
    }

    if (this.searchConditions.lastCallTimeFrom) {
      target = target.filter(
        f =>
          f.lastCallTime &&
          isTimestamp(f.lastCallTime) &&
          moment(f.lastCallTime.toDate()).isSameOrAfter(
            moment(this.searchConditions.lastCallTimeFrom),
            'day'
          )
      );
    }

    if (this.searchConditions.lastCallTimeTo) {
      target = target.filter(
        r =>
          r.lastCallTime &&
          isTimestamp(r.lastCallTime) &&
          moment(r.lastCallTime.toDate()).isSameOrBefore(
            moment(this.searchConditions.lastCallTimeTo),
            'day'
          )
      );
    }

    if (this.searchConditions.callResult) {
      const filter = this.searchConditions.callResult.toLowerCase();
      target = target.filter(f =>
        f.callResult?.toLocaleLowerCase().includes(filter)
      );
    }

    return target;
  }
}

class FutaiToritsugiCollectionMutations extends FirestoreCollectionMutations<
  IFutaiToritsugi,
  FutaiToritsugiCollectionState
> {
  setIsLoaded(v: boolean) {
    this.state.isDataLoaded = v;
  }
  setUserNameMap(v: UserNameMap | null) {
    this.state.userNameMap = v;
  }
  setSearchConditions(v: Partial<SearchConditions>) {
    this.state.searchConditions = v;
  }
}

export class FutaiToritsugiCollectionActions extends FirestoreCollectionActions<
  IFutaiToritsugi,
  FutaiToritsugiCollectionState,
  FutaiToritsugiCollectionGetters,
  FutaiToritsugiCollectionMutations
> {
  store!: Store<any>;
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.store = store;
    this.signInCtx = SignInModule.context(store);
  }

  setDomainCollectionRef(searchConditions: Partial<SearchConditions>) {
    this.commit('setIsLoaded', false);
    const whereQuerys: QueryConstraint[] = [];
    if (searchConditions.createdAtFrom) {
      whereQuerys.push(
        where(
          'createdAt',
          '>=',
          moment(searchConditions.createdAtFrom).toDate()
        )
      );
    }
    if (searchConditions.createdAtTo) {
      whereQuerys.push(
        where(
          'createdAt',
          '<=',
          moment(searchConditions.createdAtTo).endOf('date').toDate()
        )
      );
    }
    const cRef = query(
      collection(
        db,
        domainFutaiToritsugiCollectionPath(this.signInCtx.getters.domainUID)
      ),
      ...whereQuerys,
      orderBy('createdAt', 'desc')
    );
    return this.setRef(cRef)
      .then(() => {
        getUserNameMapByAccountUIDs({
          accountUIDs: this.state.data.map(v => v.creatorUID).filter(isDefined),
          allowAccount: []
        }).then(v => {
          this.commit('setUserNameMap', v.data ?? null);
        });
      })
      .finally(() => {
        this.commit('setIsLoaded', true);
      });
  }
  async setSearchConditions(v: Partial<SearchConditions>) {
    // NOTE: createdAtに変更があった場合は対象のデータのみ再バインドする
    if (
      this.state.searchConditions.createdAtFrom !== v.createdAtFrom ||
      this.state.searchConditions.createdAtTo !== v.createdAtTo
    ) {
      await this.setDomainCollectionRef(v);
    }
    this.commit('setSearchConditions', v);
  }
  async updateDocument(
    data: PartialRequired<IFutaiToritsugi, 'futaiToritsugiUID'>
  ) {
    const docRef = doc(
      db,
      domainFutaiToritsugiDocumentPath(
        this.signInCtx.getters.domainUID,
        data.futaiToritsugiUID
      )
    );
    const now = Timestamp.now();
    await setDoc(
      docRef,
      {
        ...data,
        userModified: now,
        modifiedAt: now,
        modifierUID: this.signInCtx.getters.accountUID
      } as IFutaiToritsugi,
      {
        merge: true
      }
    );
  }
}

export const FutaiToritsugiCollectionModule = new Module({
  state: FutaiToritsugiCollectionState,
  getters: FutaiToritsugiCollectionGetters,
  mutations: FutaiToritsugiCollectionMutations,
  actions: FutaiToritsugiCollectionActions
});
