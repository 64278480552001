import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { IBilling } from 'requestform-types';
import {
  billingDocumentPath,
  billingLogRequestCollectionPath
} from 'requestform-types/lib/FirestorePath';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { auth, db } from '@/firebase/firebase';
import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';

class BillingDocumentState extends FirestoreDocumentState<IBilling> {}

export class BillingDocumentGetters extends FirestoreDocumentGetters<
  IBilling,
  BillingDocumentState
> {}

class BillingDocumentMutations extends FirestoreDocumentMutations<
  IBilling,
  BillingDocumentState
> {}

class BillingDocumentActions extends FirestoreDocumentActions<
  IBilling,
  BillingDocumentState,
  BillingDocumentGetters,
  BillingDocumentMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  async getCount(yyyymm: string): Promise<IBilling | undefined> {
    const domainUID = this.signInCtx.getters.domainUID;
    const user = auth.currentUser;
    if (domainUID && user) {
      const billingDocuemntSnap = await getDoc(
        doc(db, billingDocumentPath(domainUID, yyyymm))
      );
      if (billingDocuemntSnap.exists()) {
        return billingDocuemntSnap.data() as IBilling;
      }
    }
    return undefined;
  }
}

export async function addBillingLogRequest(
  domainUID: string,
  requestUID: string
) {
  const user = auth.currentUser;
  if (domainUID && user) {
    // NOTE: 2回目以降はsecurity rulesで書き込み失敗すため、1requestに1回/初回のみが記録される
    await setDoc(
      doc(db, `${billingLogRequestCollectionPath(domainUID)}/${requestUID}`),
      {
        requestUID,
        creatorUID: user.uid,
        createdAt: serverTimestamp()
      }
    );
  }
}

export const BillingDocumentModule = new Module({
  state: BillingDocumentState,
  getters: BillingDocumentGetters,
  mutations: BillingDocumentMutations,
  actions: BillingDocumentActions
});
