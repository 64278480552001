import { doc, getDoc, setDoc } from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes
} from 'firebase/storage';
import {
  domainSettingDocumentPath,
  functionSettingDocumentPath
} from 'requestform-types/lib/FirestorePath';
import {
  FunctionSetting,
  IDomainSetting
} from 'requestform-types/lib/IDomainSetting';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db, storage } from '@/firebase/firebase';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';

import { SignInModule } from './SignInModule';

class DomainSettingDocumentState extends FirestoreDocumentState<IDomainSetting> {}

export class DomainSettingDocumentGetters extends FirestoreDocumentGetters<
  IDomainSetting,
  DomainSettingDocumentState
> {
  get getIpRestriction() {
    return this.state.data?.ipRestriction;
  }
}

class DomainSettingDocumentMutations extends FirestoreDocumentMutations<
  IDomainSetting,
  DomainSettingDocumentState
> {}

export class DomainSettingDocumentActions extends FirestoreDocumentActions<
  IDomainSetting,
  DomainSettingDocumentState,
  DomainSettingDocumentGetters,
  DomainSettingDocumentMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  async update(payload: Partial<IDomainSetting>) {
    if (this.state.ref) {
      await setDoc(this.state.ref, payload, { merge: true });
    }
  }
  async getDomainSettingDocument(domainUID?: string) {
    const uid = domainUID ? domainUID : this.signInCtx.getters.domainUID;
    if (!uid) return;
    const dDoc = await getDoc(doc(db, domainSettingDocumentPath(uid)));
    if (!dDoc.exists()) {
      console.warn(domainUID, ' does not exist');
      return;
    }
    return dDoc.data();
  }
  setDomainDocumentRef(domainUID: string) {
    if (!this.state.ref && !!domainUID) {
      return this.setDocumentRef(domainSettingDocumentPath(domainUID));
    }
  }
  async getDomainFunctionSettingDocument(
    domainUID?: string
  ): Promise<FunctionSetting | undefined> {
    const uid = domainUID ? domainUID : this.signInCtx.getters.domainUID;
    if (!uid) return;
    const dfDoc = await getDoc(doc(db, functionSettingDocumentPath(uid)));
    return dfDoc?.data() as FunctionSetting;
  }
}

export function isPdfFile(file: File): boolean {
  return ['application/pdf'].includes(file.type);
}

export function getPdfName(filename: string, suffix: string) {
  const basename = filename.split('.pdf')[0];
  return `${basename}_${suffix}.pdf`;
}

export function getPdfFileIfExists(pdfName: string | undefined) {
  if (!pdfName) return null;
  const dummy = new File([''], pdfName, {
    type: 'application/pdf'
  });
  return dummy;
}

export async function deleteStorageFile(
  filepath: string,
  existedPdfname: string,
  suffix: string
) {
  const storageFile = ref(
    storage,
    `${filepath}/${getPdfName(existedPdfname, suffix)}`
  );
  await deleteObject(storageFile).catch(e => {
    console.log(e);
  });
}

export async function addFileToStorage(
  filepath: string,
  file: File,
  suffix: string,
  domainUID: string
): Promise<string> {
  const putFileOptions = {
    cacheControl: 'private, max-age=3600',
    customMetadata: {
      allowDomain: domainUID
    },
    contentType: 'application/pdf'
  };
  const storageFile = ref(
    storage,
    `${filepath}/${getPdfName(file.name, suffix)}`
  );
  await uploadBytes(storageFile, file, putFileOptions).catch(e => {
    return Promise.reject(e);
  });
  const url = await getDownloadURL(storageFile);
  return url;
}

export const DomainSettingDocumentModule = new Module({
  state: DomainSettingDocumentState,
  getters: DomainSettingDocumentGetters,
  mutations: DomainSettingDocumentMutations,
  actions: DomainSettingDocumentActions
});
