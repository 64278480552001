import { Datetime } from './IDocumentBase';
import { IRequest } from './IRequest';
import { MultipleSelectionObj } from './MultipleSelection';
import { ValueOf } from './utilities';

// 確認書類
export const MoshikomishaFileKeys = [
  'honninKakuninShorui1' /** 本人確認書類（おもて） */,
  'honninKakuninShorui2' /** 本人確認書類（うら） */,
  'kenkoHokensho1' /** 健康保険証（おもて） */,
  'kenkoHokensho2' /** 健康保険証（うら） */,
  'shunyuShomeisho' /** 収入証明書 */,
  'hosokuShorui1' /** 補足書類1 */,
  'hosokuShorui2' /** 補足書類2 */,
  'shinkenshaDoisho' /** 親権者同意書 */
] as const;
export type MoshikomishaFileKeys = typeof MoshikomishaFileKeys[number];
export type MoshikomishaFiles = Record<MoshikomishaFileKeys, string>;

export const NyukyoshaFileKeys = [
  'honninKakuninShorui1' /** 本人確認書類（おもて） */,
  'honninKakuninShorui2' /** 本人確認書類（うら） */
] as const;
export type NyukyoshaFileKeys = typeof NyukyoshaFileKeys[number];
export type NyukyoshaFiles = Record<NyukyoshaFileKeys, string>;

/** 人物 */
export interface IPerson extends MoshikomishaFiles {
  /** 続柄 */
  relation: relationTypes | null;
  /** 続柄その他 */
  relationText: string;
  /** 名称 */
  name: string;
  /** 名称カナ */
  nameKana: string;
  /** 姓 */
  sei: string;
  /** 姓カナ */
  seiKana: string;
  /** 名 */
  mei: string;
  /** 名カナ */
  meiKana: string;
  /** 郵便番号 */
  postNumber: string;
  /** 住所コード */
  jushoCode: string;
  /** 字町名 */
  jushoText: string;
  /** 枝番 */
  nokoriJusho: string;
  /** 街区・号棟 */
  gaikuGoto: string;
  /** 字町名カナ */
  jushoTextKana: string;
  /** 枝番カナ */
  nokoriJushoKana: string;
  /** 街区・号棟カナ */
  gaikuGotoKana: string;
  /** 住所 */
  address: string;
  /** 現住所所有状況 */
  currentShoyuKubun: shoyuKubun | null;
  /** 現住所所有状況その他 */
  currentShoyuKubunSonotaText: string;
  /** 住宅ローンの有無 */
  housingLoan: boolean;
  /** 現住所居住年数 */
  currentResidenceYears: number;
  /** 現住所居住月数 */
  currentResidenceMonths: number;
  /** 現住所月額賃料 or 住宅ローンの額 */
  currentChinryo: number;
  /** 現住所保証人 */
  currentHoshoninType: relationTypes | null;
  /** 現住所保証人その他 */
  currentHoshoninSonotaText: string;
  /** 国籍 */
  nationality: string;
  /** 職業 */
  shokugyo: careers | null;
  /** 職業その他 */
  shokugyoSonotaText: string;
  /** 派遣・出向先企業名  */
  hakensakiKigyoumei: string;
  /** 派遣・出向先郵便番号 */
  hakensakiPostNumber: string;
  /** 派遣・出向先住所コード */
  hakensakiJushoCode: string;
  /** 派遣・出向先字町名 */
  hakensakiJushoText: string;
  /** 派遣・出向先枝番 */
  hakensakiNokoriJusho: string;
  /** 派遣・出向先街区・号棟 */
  hakensakiGaikuGoto: string;
  /** 派遣・出向先電話番号  */
  hakensakiTel: string;
  /** 派遣・出向先最寄駅  */
  hakensakiMoyoriEki: string;
  /** 派遣・出向先所属部署  */
  hakensakiBusho: string;
  /** 派遣・出向先役職  */
  hakensakiYakushoku: string;
  /** 保険種別 */
  hokenshubetsu: hokenTypes | null;
  /** 保険種別その他 */
  hokenshubetsuSonotaText: string;
  /** 生活保護受給 */
  seikatsuhogo: boolean;
  /** 生活保護受給理由 */
  seikatsuhogoRiyu: string;
  /** 年収 */
  nenshu: number;
  /** 平均手取り月収 */
  heikinTedoriGesshu: number;
  /** 勤務先家賃補助 */
  kinmusakiYachinHojo: hojoTypes | null;
  /** 勤務先上場 */
  kinmusakiJojo: jojoTypes | null;
  /** 預貯金 */
  yochokin: number;
  /** 借入(住宅ローン除く) */
  kariirekin: number;
  /** 給料日 */
  payday: string;
  /** クレジットカード所有状況 */
  creditCardOwned: MultipleSelectionObj;
  /** 携帯電話番号 */
  mobilePhoneNumber: string;
  /** 連絡先タイプ1 */
  renrakusakiType1: renrakusakiTypes | null;
  /** 電話番号 */
  telNumber: string;
  /** 連絡先タイプ2 */
  renrakusakiType2: renrakusakiTypes | null;
  /** メールアドレス */
  mailAddress: string;
  /** メールアドレス種別 */
  mailAddressType: mailAddressTypes | null;
  /** 生年月日 */
  birthday: Datetime | null;
  /** 性別 */
  sex: sexs | null;
  /** 配偶者 */
  haigusha: haigushaTypes | null;
  /** 子供 */
  childs: personNumberTypes | null;
  /** 親兄弟 */
  oyakyodai: personNumberTypes | null;
  /** 申込者が生計を同一にする家族人数(本人含む) */
  fuyoKazoku: personNumberTypes | null;
  /** 親との同居区分 */
  oyaDokyoKubun: DokyoKubun;
  /** 転居理由 */
  tenkyoRiyu: tenkyoriyuTypes | null;
  /** 転居理由その他 */
  tenkyoRiyuSonotaText: string;
  /** 顧客ID */
  kokyakuId: string;
  /** 顧客区分 */
  kokyakuKubun: kokyakuKubun | null;
  /** 取引種別 */
  torihikishubetsu: torihikiShubetsu | null;
  /** 担当組織ID */
  tantoSoshikiId: string;
  /** 勤務先名称 */
  kinmusakiName: string;
  /** 勤務先名称カナ */
  kinmusakiNameKana: string;
  /** 勤務先郵便番号 */
  kinmusakiPostNumber: string;
  /** 勤務先住所コード */
  kinmusakiJushoCode: string;
  /** 勤務先字町名 */
  kinmusakiJushoText: string;
  /** 勤務先枝番 */
  kinmusakiNokoriJusho: string;
  /** 勤務先街区・号棟 */
  kinmusakiGaikuGoto: string;
  /** 勤務先所在地 */
  kinmusakiAddress: string;
  /** 勤務先TEL番号 */
  kinmusakiTelNumber: string;
  /** 勤務先部署 */
  kinmusakiBusho: string;
  /** 勤務先役職 */
  kinmusakiYakushoku: string;
  /** 勤務先入社年 */
  kinmusakiNyushaYear: number | null;
  /** 勤務先入社月 */
  kinmusakiNyushaMonth: number | null;
  /** 勤務先ホームページ */
  kinmusakiHomepage: string;
  /** 勤務先業種 */
  kinmusakiGyoshu: gyoshuTypes;
  /** 勤務先業種その他 */
  kinmusakiGyoshuSonotaText: string;
  /** 勤務先業務内容 */
  kinmusakiGyomuNaiyo: string;
  /** 勤務先主要取引先 */
  kinmusakiTorihikisaki: string;
  /** 勤務先従業員数 */
  kinmusakiJyugyoinRange: jyugyoinRangeTypes | null;
  /** 勤務先設立 */
  kinmusakiEstablishmentYear: number;
  /** 勤務先年商 */
  kinmusakiNensho: number;
  /** 勤務先資本金 */
  kinmusakiShihonkin: number;
  /** 勤務先最寄駅 */
  kinmusakiMoyoriEki: string;
  /** その他書類(本人確認) */
  images: string[];
  /** 備考 */
  biko: string;
  /** 連絡可能時間帯 */
  contactHours: MultipleSelectionObj;
  /** 連絡可能時間帯その他 */
  contactHoursText: string;
  /** 希望言語 */
  languageTypes: languageTypes;
  /** 希望言語その他 */
  languageTypesText: string;
  /** 日本滞在暦年 */
  taizaiNensu: number;
  /** 日本滞在暦月 */
  taizaiTsukisu: number;
  /** 在留資格 */
  zairyuShikaku: string;
  /** One連携顧客ID */
  kokyakuGuid: string;
  /** One顧客連携済み日時 */
  linkageDateTime: Datetime;
  /** 立場 */
  standPoint: standPointTypes | null;
  /** 立場その他 */
  standPointText: string;
  /** 申込者情報記入の注意書き */
  moshikomishaFillingNotes: string;
  /** 申込者宛てに審査必須項目の入力催促通知メールを送信する */
  isSendScheduleNotice: boolean;
}

export enum shoyuKubun {
  jikoShoyu = 1,
  kazokuShoyu,
  chintai,
  shataku,
  ryo,
  sonota = 99
}

export const shoyuKubunMap = new Map<shoyuKubun, string>([
  [shoyuKubun.jikoShoyu, '自己所有'],
  [shoyuKubun.kazokuShoyu, '家族所有'],
  [shoyuKubun.chintai, '賃貸'],
  [shoyuKubun.shataku, '社宅・官舎'],
  [shoyuKubun.ryo, '寮'],
  [shoyuKubun.sonota, 'その他']
]);

export enum careers {
  keieisha = 1,
  seishainAdmin,
  seishain,
  keiyakuShain,
  hakenShain,
  arbeit,
  komuin,
  kojinJigyonushi,
  gakusei,
  sengyoshuhu,
  nenkin,
  mushoku,
  other = 99
}

export const careersMap = new Map<careers, string>([
  [careers.keieisha, '経営者（会社役員含む）'],
  [careers.seishainAdmin, '正社員（管理職以上）'],
  [careers.seishain, '正社員（一般従業員）'],
  [careers.keiyakuShain, '契約社員'],
  [careers.hakenShain, '派遣社員'],
  [careers.arbeit, 'アルバイト'],
  [careers.komuin, '公務員・団体職員'],
  [careers.kojinJigyonushi, '個人事業主'],
  [careers.gakusei, '学生'],
  [careers.sengyoshuhu, '専業主婦'],
  [careers.nenkin, '年金'],
  [careers.mushoku, '無職'],
  [careers.other, 'その他']
]);
export enum relationTypes {
  honnin = 1,
  shain,
  husband,
  wife,
  child,
  father,
  mother,
  olderBrother,
  youngerBrother,
  olderSister,
  youngerSister,
  hoshoKaisha,
  sonota = 99
}

export const relationTypesMap = new Map<relationTypes, string>([
  [relationTypes.honnin, '本人'],
  [relationTypes.shain, '社員'],
  [relationTypes.husband, '夫'],
  [relationTypes.wife, '妻'],
  [relationTypes.child, '子'],
  [relationTypes.father, '父'],
  [relationTypes.mother, '母'],
  [relationTypes.olderBrother, '兄'],
  [relationTypes.youngerBrother, '弟'],
  [relationTypes.olderSister, '姉'],
  [relationTypes.youngerSister, '妹'],
  [relationTypes.hoshoKaisha, '保証会社'],
  [relationTypes.sonota, 'その他']
]);

export enum hokenTypes {
  shaho = 1,
  kokuho,
  kyosai,
  sonota = 99
}

export const hokenTypesMap = new Map<hokenTypes, string>([
  [hokenTypes.shaho, '社保'],
  [hokenTypes.kokuho, '国保'],
  [hokenTypes.kyosai, '共済'],
  [hokenTypes.sonota, 'その他']
]);

export enum renrakusakiTypes {
  tel = 1,
  mobile,
  kinmusaki,
  fax,
  other = 99
}
export const renrakusakiTypesMap = new Map<renrakusakiTypes, string>([
  [renrakusakiTypes.tel, '自宅'],
  [renrakusakiTypes.mobile, '携帯'],
  [renrakusakiTypes.kinmusaki, '勤務先'],
  [renrakusakiTypes.fax, 'FAX'],
  [renrakusakiTypes.other, 'その他']
]);

export enum mailAddressTypes {
  pc = 1,
  mobile,
  smartPhone,
  other = 99
}
export const mailAddressTypesMap = new Map<mailAddressTypes, string>([
  [mailAddressTypes.pc, 'PC'],
  [mailAddressTypes.mobile, '携帯'],
  [mailAddressTypes.smartPhone, 'スマートフォン'],
  [mailAddressTypes.other, 'その他']
]);

export enum sexs {
  man = 1,
  woman,
  other = 99
}
export const sexsMap = new Map<sexs, string>([
  [sexs.man, '男性'],
  [sexs.woman, '女性']
]);

export enum tenkyoriyuTypes {
  koshin = 1,
  yachin,
  tenkin,
  shusyoku,
  tenshoku,
  nyugaku,
  kekkon,
  shussan,
  rikon,
  tezema,
  rokyuka,
  taishitsu,
  dokyo,
  dokuritsu,
  jitakuBaikyaku,
  kibun,
  tsukinJikan,
  kankyo,
  seikatsu,
  ekikyori,
  gakku,
  other = 99
}
export const tenkyoriyuTypesMap = new Map<tenkyoriyuTypes, string>([
  [tenkyoriyuTypes.koshin, '現住居の更新'],
  [tenkyoriyuTypes.yachin, '賃料負担減'],
  [tenkyoriyuTypes.tenkin, '転勤'],
  [tenkyoriyuTypes.shusyoku, '就職'],
  [tenkyoriyuTypes.tenshoku, '転職'],
  [tenkyoriyuTypes.nyugaku, '入学'],
  [tenkyoriyuTypes.kekkon, '結婚'],
  [tenkyoriyuTypes.shussan, '出産'],
  [tenkyoriyuTypes.rikon, '離婚'],
  [tenkyoriyuTypes.tezema, '現住居手狭'],
  [tenkyoriyuTypes.rokyuka, '現住居老朽化'],
  [tenkyoriyuTypes.taishitsu, '寮/社宅から退室'],
  [tenkyoriyuTypes.dokyo, '親と同居'],
  [tenkyoriyuTypes.dokuritsu, '独立'],
  [tenkyoriyuTypes.jitakuBaikyaku, '自宅売却'],
  [tenkyoriyuTypes.kibun, '気分転換'],
  [tenkyoriyuTypes.tsukinJikan, '通勤時間短縮'],
  [tenkyoriyuTypes.kankyo, '周辺環境向上'],
  [tenkyoriyuTypes.seikatsu, '生活利便性向上'],
  [tenkyoriyuTypes.ekikyori, '駅距離向上'],
  [tenkyoriyuTypes.gakku, '学区の変更'],
  [tenkyoriyuTypes.other, 'その他']
]);

export enum kokyakuKubun {
  kojin = 1,
  hojin,
  foreignerKojin,
  foreignerHojin
}

export const kokyakuKubunMap = new Map<kokyakuKubun, string>([
  [kokyakuKubun.kojin, '個人'],
  [kokyakuKubun.hojin, '法人'],
  [kokyakuKubun.foreignerKojin, '外国人'],
  [kokyakuKubun.foreignerHojin, '外国人(法人)']
]);

export const kokyakuKubunLabels = [
  {
    label: kokyakuKubunMap.get(kokyakuKubun.kojin),
    icon: 'mdi-account',
    value: kokyakuKubun.kojin
  },
  {
    label: kokyakuKubunMap.get(kokyakuKubun.hojin),
    icon: 'mdi-domain',
    value: kokyakuKubun.hojin
  },
  {
    label: kokyakuKubunMap.get(kokyakuKubun.foreignerKojin),
    icon: 'mdi-earth',
    value: kokyakuKubun.foreignerKojin
  },
  {
    label: kokyakuKubunMap.get(kokyakuKubun.foreignerHojin),
    icon: 'mdi-earth',
    value: kokyakuKubun.foreignerHojin
  }
];

// NOTE: 追客に連動される情報、賃貸物件の申込の場合は chinshaku のみとなる
export enum torihikiShubetsu {
  chinshaku = '賃借'
  // chintai = '賃貸',
  // konyu = '購入',
  // baikyaku = '売却'
}

export enum gyoshuTypes {
  kinyuKikan = 1,
  fudosan,
  rikuun,
  seizo,
  shosha,
  kouriService,
  inshoku,
  kokoku,
  publisher,
  joho,
  it,
  education,
  doctor,
  geino,
  komuin,
  service,
  other = 99
}

export const gyoshuTypesMap = new Map<gyoshuTypes, string>([
  [gyoshuTypes.kinyuKikan, '金融・保険・証券'],
  [gyoshuTypes.fudosan, '不動産・建設'],
  [gyoshuTypes.rikuun, '航空・運輸'],
  [gyoshuTypes.seizo, '製造'],
  [gyoshuTypes.shosha, '商社'],
  [gyoshuTypes.kouriService, '卸売・小売'],
  [gyoshuTypes.inshoku, '飲食・宿泊業'],
  [gyoshuTypes.kokoku, '広告・メディア'],
  [gyoshuTypes.publisher, '出版・印刷'],
  [gyoshuTypes.joho, '情報通信'],
  [gyoshuTypes.it, 'ＩＴ・コンサルティング'],
  [gyoshuTypes.education, '教育・福祉・医療'],
  [gyoshuTypes.doctor, '医師・弁護士・会計士'],
  [gyoshuTypes.geino, '芸能'],
  [gyoshuTypes.komuin, '公務員'],
  [gyoshuTypes.service, 'サービス'],
  [gyoshuTypes.other, 'その他']
]);

export enum jyugyoinRangeTypes {
  lessthan10 = 1,
  lessthan50,
  lessthan100,
  lessthan300,
  lessthan500,
  lessthan1000,
  more1000
}

export const jyugyoinRangeTypesMap = new Map<jyugyoinRangeTypes, string>([
  [jyugyoinRangeTypes.lessthan10, '10人未満'],
  [jyugyoinRangeTypes.lessthan50, '10〜50人'],
  [jyugyoinRangeTypes.lessthan100, '50〜100人'],
  [jyugyoinRangeTypes.lessthan300, '100〜300人'],
  [jyugyoinRangeTypes.lessthan500, '300〜500人'],
  [jyugyoinRangeTypes.lessthan1000, '500〜1000人'],
  [jyugyoinRangeTypes.more1000, '1000人以上']
]);

export enum hojoTypes {
  none = 1,
  lessthan3,
  lessthan5,
  lessthan10,
  lessthan15,
  over15,
  all
}

export const hojoTypesMap = new Map<hojoTypes, string>([
  [hojoTypes.none, 'なし'],
  [hojoTypes.lessthan3, '3万円未満'],
  [hojoTypes.lessthan5, '3～5万円未満'],
  [hojoTypes.lessthan10, '5～10万円未満'],
  [hojoTypes.lessthan15, '10～15万円未満'],
  [hojoTypes.over15, '15万円以上'],
  [hojoTypes.all, '全額']
]);

export enum creditCardTypes {
  none = 1,
  visa,
  masterCard,
  jcb,
  amex,
  diners,
  ginren,
  other = 99
}

export const creditCardTypesMap = new Map<creditCardTypes, string>([
  [creditCardTypes.none, '所有無し'],
  [creditCardTypes.visa, 'VISA'],
  [creditCardTypes.masterCard, 'MasterCard'],
  [creditCardTypes.jcb, 'JCB'],
  [creditCardTypes.amex, 'AMEX'],
  [creditCardTypes.diners, 'Diners'],
  [creditCardTypes.ginren, '銀聯']
]);

export enum jojoTypes {
  jojo = 1,
  hijojo
}

export const jojoTypesMap = new Map<jojoTypes, string>([
  [jojoTypes.jojo, '上場'],
  [jojoTypes.hijojo, '非上場']
]);

export const getRelationTypesItemsByKokyakuKubun = (
  requestObj: Partial<IRequest>
) => {
  return Array.from(relationTypesMap)
    .filter(([, text]: any) => {
      // 社員の選択肢は顧客種別:法人のときのみ
      if (requestObj.moshikomisha?.kokyakuKubun !== kokyakuKubun.hojin) {
        return text !== '社員';
      }
      return true;
    })
    .map(([value, text]: any) => {
      return { text, value };
    });
};

export const relationTypesItems = (requestObj: Partial<IRequest>) => {
  return getRelationTypesItemsByKokyakuKubun(requestObj).filter((x: any) => {
    return x.text !== '保証会社';
  });
};

export const relationExcludeMyselfTypesItems = (
  requestObj: Partial<IRequest>
) => {
  return getRelationTypesItemsByKokyakuKubun(requestObj).filter((x: any) => {
    return x.text !== '本人';
  });
};

// NOTE: 今後時間帯の選択肢分割しそうな気がするのでsuffixとして0を付与している
export enum contactHourTypes {
  notSpecified = 1,
  weekdayMorning0 = 10,
  weekdayNoon0 = 20,
  weekdayAfternoon0 = 30,
  weekdayEvening0 = 40,
  holidayMorning0 = 50,
  holidayNoon0 = 60,
  holidayAfternoon0 = 70,
  holidayEvening0 = 80,
  other = 99
}

export const contactHourTypesMap = new Map<contactHourTypes, string>([
  [contactHourTypes.notSpecified, '指定無し'],
  [contactHourTypes.weekdayNoon0, '平日10:00〜13:00'],
  [contactHourTypes.weekdayAfternoon0, '平日13:00〜16:00'],
  [contactHourTypes.weekdayEvening0, '平日16:00〜19:00'],
  [contactHourTypes.holidayNoon0, '土日祝10:00〜13:00'],
  [contactHourTypes.holidayAfternoon0, '土日祝13:00〜16:00'],
  [contactHourTypes.holidayEvening0, '土日祝16:00〜19:00']
]);

export enum languageTypes {
  japanese = 1,
  english,
  chinese,
  korean,
  other = 99
}

export const languageTypesMap = new Map<languageTypes, string>([
  [languageTypes.japanese, '日本語 Japanese'],
  [languageTypes.english, '英語 English'],
  [languageTypes.chinese, '中国語 Chinese'],
  [languageTypes.korean, '韓国語 Korean'],
  [languageTypes.other, 'その他 Other']
]);

export enum haigushaTypes {
  no,
  // 互換保持のため 画面からは選択不可
  yes,
  workingTogether,
  notWorkingTogether
}
export const haigushaTypesMap = new Map<haigushaTypes, string>([
  [haigushaTypes.no, '無'],
  [haigushaTypes.yes, '有'],
  [haigushaTypes.workingTogether, '有（共働き）'],
  [haigushaTypes.notWorkingTogether, '有（共働きでない）']
]);

export enum personNumberTypes {
  zero = 0,
  one,
  two,
  three,
  four,
  overFive
}
export const personNumberTypesMap = new Map<personNumberTypes, string>([
  [personNumberTypes.zero, '0人'],
  [personNumberTypes.one, '1人'],
  [personNumberTypes.two, '2人'],
  [personNumberTypes.three, '3人'],
  [personNumberTypes.four, '4人'],
  [personNumberTypes.overFive, '5人以上']
]);

// NOTE: 「区分」なので選択肢が増える可能性も考慮してnumberにしている
export const DokyoKubun = {
  Dokyo: 1,
  Bekkyo: 2
} as const;
export type DokyoKubun = ValueOf<typeof DokyoKubun>;
export const dokyoKubunMap = new Map<DokyoKubun, string>([
  [DokyoKubun.Dokyo, '同居'],
  [DokyoKubun.Bekkyo, '別居']
]);

export enum standPointTypes {
  hozinDaihyosha = 0,
  nyukyoYoteisha,
  soumuTantosha,
  shatakuDaikoKaisha,
  other = 99
}
export const standPointTypesMap = new Map<standPointTypes, string>([
  [standPointTypes.hozinDaihyosha, '法人代表者'],
  [standPointTypes.nyukyoYoteisha, '入居予定者'],
  [standPointTypes.soumuTantosha, '総務担当者'],
  [standPointTypes.shatakuDaikoKaisha, '社宅代行会社'],
  [standPointTypes.other, 'その他']
]);
