import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query
} from 'firebase/firestore';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { IDefaultChohyoTemplate } from 'requestform-types';
import {
  defaultFaxNumberCollectionPath,
  defaultFaxNumberDocumentPath,
  defaultPrivacyPolicyAgreementUrlCollectionPath,
  defaultPrivacyPolicyAgreementUrlDocumentPath,
  yachinHoshoKaishaMasterCollectionPath
} from 'requestform-types/lib/FirestorePath';
import { YachinHoshoKaishaMaster } from 'requestform-types/lib/IYachinHoshoKaisha';
import {
  isDefaultFaxNumber,
  isDefaultPrivacyPolicyAgreementUrl,
  isYachinHoshoKaishaMaster
} from 'requestform-types/lib/TypeGuard';
import { Actions, Getters, Module, Mutations } from 'vuex-smart-module';

import { db, storage } from '@/firebase/firebase';

class MasterDataState {
  yachinHoshoKaishas: Partial<YachinHoshoKaishaMaster>[] = [];
  defaultChohyoTemplates: IDefaultChohyoTemplate[] = [];
}

export class MasterDataGetters extends Getters<MasterDataState> {
  get yachinHoshoKaishas() {
    return this.state.yachinHoshoKaishas;
  }
  get isExistYachinHoshoKaishas() {
    return this.state.yachinHoshoKaishas.length > 0;
  }
  get defaultChohyoTemplates() {
    return this.state.defaultChohyoTemplates;
  }
}

class MasterDataMutations extends Mutations<MasterDataState> {
  updateYachinHoshoKaishas(
    yachinHoshoKaishas: Partial<YachinHoshoKaishaMaster>[]
  ) {
    this.state.yachinHoshoKaishas = yachinHoshoKaishas;
  }
  updateDefaultChohyoTemplates(
    defaultChohyoTemplates: IDefaultChohyoTemplate[]
  ) {
    this.state.defaultChohyoTemplates = defaultChohyoTemplates;
  }
}

export class MasterDataActions extends Actions<
  MasterDataState,
  MasterDataGetters,
  MasterDataMutations,
  MasterDataActions
> {
  async updateYachinHoshoKaishas() {
    const snaps = await getDocs(
      query(
        collection(db, yachinHoshoKaishaMasterCollectionPath),
        orderBy('shortName', 'asc')
      )
    );

    const yachinHoshoKaishas: Partial<YachinHoshoKaishaMaster>[] = [];
    snaps.forEach(result => {
      const yachinHoshoKaisha = result.data();
      if (isYachinHoshoKaishaMaster(yachinHoshoKaisha)) {
        yachinHoshoKaishas.push({
          hoshoKaishaMasterUID: result.id,
          ...yachinHoshoKaisha
        });
      }
    });
    this.commit('updateYachinHoshoKaishas', yachinHoshoKaishas);
  }

  async updateDefaultChohyoTemplates() {
    const files = await listAll(ref(storage, 'chohyoTemplate/default'));

    const defaultChohyoTemplates: IDefaultChohyoTemplate[] = [];
    for (const file of files.items) {
      if (file.name === '全項目確認用.xlsx') continue;
      defaultChohyoTemplates.push({
        fileName: file.name,
        downloadURL: await getDownloadURL(file)
      });
    }
    this.commit('updateDefaultChohyoTemplates', defaultChohyoTemplates);
  }

  async getDefaultFaxNumber(yachinHoshoKaishaMasterUID: string) {
    const collectionRef = collection(
      db,
      defaultFaxNumberCollectionPath(yachinHoshoKaishaMasterUID)
    );
    const snapshot = await getDocs(
      query(collectionRef, orderBy('name', 'asc'))
    );
    const result = snapshot.docs
      .map(doc => {
        const data = doc.data();
        return data;
      })
      .filter(isDefaultFaxNumber);
    return result;
  }

  async getDefaultFaxNumberDocument(payload: {
    yachinHoshoKaishaMasterUID: string;
    defaultFaxNumberUID: string;
  }) {
    const { yachinHoshoKaishaMasterUID, defaultFaxNumberUID } = payload;
    const defaultFaxNumberDocuemntSnap = await getDoc(
      doc(
        db,
        defaultFaxNumberDocumentPath(
          yachinHoshoKaishaMasterUID,
          defaultFaxNumberUID
        )
      )
    );
    if (
      !defaultFaxNumberDocuemntSnap.exists() ||
      !isDefaultFaxNumber(defaultFaxNumberDocuemntSnap.data())
    ) {
      return undefined;
    }
    return defaultFaxNumberDocuemntSnap.data();
  }

  async getDefaultPrivacyPolicyAgreementUrl(
    yachinHoshoKaishaMasterUID: string
  ) {
    if (!yachinHoshoKaishaMasterUID) {
      return [];
    }
    const collectionRef = collection(
      db,
      defaultPrivacyPolicyAgreementUrlCollectionPath(yachinHoshoKaishaMasterUID)
    );
    const snapshot = await getDocs(
      query(collectionRef, orderBy('name', 'asc'))
    );
    const result = snapshot.docs
      .map(doc => {
        const data = doc.data();
        return data;
      })
      .filter(isDefaultPrivacyPolicyAgreementUrl);
    return result;
  }

  async getDefaultPrivacyPolicyAgreementUrlDocument(payload: {
    yachinHoshoKaishaMasterUID: string;
    defaultPrivacyPolicyAgreementUrlUID: string;
  }) {
    const {
      yachinHoshoKaishaMasterUID,
      defaultPrivacyPolicyAgreementUrlUID
    } = payload;
    const defaultPrivacyPolicyAgreementUrlDocuemntSnap = await getDoc(
      doc(
        db,
        defaultPrivacyPolicyAgreementUrlDocumentPath(
          yachinHoshoKaishaMasterUID,
          defaultPrivacyPolicyAgreementUrlUID
        )
      )
    );
    if (
      !defaultPrivacyPolicyAgreementUrlDocuemntSnap.exists() ||
      !isDefaultPrivacyPolicyAgreementUrl(
        defaultPrivacyPolicyAgreementUrlDocuemntSnap.data()
      )
    ) {
      return undefined;
    }
    return defaultPrivacyPolicyAgreementUrlDocuemntSnap.data();
  }
}

export const MasterDataModule = new Module({
  state: MasterDataState,
  getters: MasterDataGetters,
  mutations: MasterDataMutations,
  actions: MasterDataActions
});
