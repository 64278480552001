// TODO: どこでfirebaseのinitializeAppを行うかは検討
import '@/firebase/firebase';
import './registerServiceWorker';

import VueCompositionApi from '@vue/composition-api';
import axios from 'axios';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueScrollTo from 'vue-scrollto';
// @ts-ignore
import VueTheMask from 'vue-the-mask';
import VuetifyToast from 'vuetify-toast-snackbar-ng';

import VueLoading from '@/plugins/loading';
import vuetify from '@/plugins/vuetify';
import Filters from '@/utilities/Filters';

import App from './App.vue';
import router from './router';
import store from './store';
Vue.use(VueLoading);
import '@e-seikatsu/design-system/dist/tokens/index.css';
import 'moment/locale/ja';

import { errorHandler, warnHandler } from '@/utilities/appLogger';

if (import.meta.env.NODE_ENV !== 'production') {
  console.log(`git hash: ${import.meta.env.VITE_GIT_HASH}`);
}
Vue.config.productionTip = false;
Vue.use(VueTheMask);
Vue.use(VuetifyToast, {
  x: '',
  y: 'top',
  timeout: 6000,
  showClose: true,
  closeColor: 'white',
  shorts: {
    success: {
      color: 'success',
      icon: 'mdi-check-circle'
    },
    info: {
      color: 'info',
      icon: 'info'
    },
    warning: {
      color: 'warning',
      icon: 'mdi-alert-circle'
    },
    error: {
      color: 'error',
      icon: 'mdi-alert'
    }
  },
  property: '$toast'
});
Vue.use(VueScrollTo);
Vue.use(VueCompositionApi);

// TODO: Vue3ではfilterが使えなくなるため通常関数としての呼び出しに変更する
Vue.filter('moneyDelimiter', Filters.MoneyDelimiter);
Vue.filter('monthDayDisplay', Filters.MonthDayDisplay);
Vue.filter('dateDisplay', Filters.DateDisplay);
Vue.filter('dateTimeDisplay', Filters.DateTimeDisplay);
Vue.filter('timeDisplay', Filters.TimeDisplay);
Vue.filter('commentDateTimeDisplay', Filters.CommentDateTimeDisplay);

Vue.use(
  VueGtag,
  {
    config: {
      id: import.meta.env.VITE_ANALYTICS_ID
    }
  },
  router
);

Vue.config.errorHandler = errorHandler;
Vue.config.warnHandler = warnHandler;

type MaintenanceFlagJson = {
  prd: {
    isMaintenance: boolean;
  };
  inside: {
    isMaintenance: boolean;
  };
};

const maintenanceFlagJson: string | undefined = import.meta.env
  .VITE_MAINTENANCE_FLAG_URL;
const maintenanceInfoIntervalTime: number = 10 * 60 * 1000;

const getMaintenanceInfo = async () => {
  if (!maintenanceFlagJson) {
    return;
  }
  const maintenanceData = await axios
    .get<MaintenanceFlagJson>(maintenanceFlagJson)
    .then(resp => resp.data)
    .catch(() => undefined);

  if (!maintenanceData) {
    return;
  }

  return maintenanceData.prd.isMaintenance;
};

const checkMaintenanceFlag = async () => {
  const maintenanceFlag = await getMaintenanceInfo();

  if (maintenanceFlag && !location.pathname.includes('maintenance')) {
    window.location.href = location.origin + '/maintenance';
  }

  //メンテナンス中以外にエラーページにいた時、その前のページにリダイレクト
  if (!maintenanceFlag && location.pathname.includes('maintenance')) {
    window.history.back();
  }
};
checkMaintenanceFlag();
window.setInterval(checkMaintenanceFlag, maintenanceInfoIntervalTime);

new Vue({
  components: { App },
  render: h => h(App),
  router,
  store,
  template: '<App/>',
  vuetify
}).$mount('#app');
