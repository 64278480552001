<template>
  <v-card>
    <v-card-title class="font-weight-bold">
      <span>Square利用法人切り替え</span>
      <v-spacer />
      <v-btn text right icon @click="onClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-4">
      <div class="word_color">
        「いい生活アカウント」アップデートにより、Squareでご利用いただける法人について仕様変更を行いました。

        <ul>
          <li>複数の法人がある場合は、代表1つの法人のみご利用いただけます</li>
          <li>
            その他の法人はアーカイブ扱いとなり、以降新規の内見/申込作成ができません
          </li>
          <li>
            アーカイブ法人に紐づく内見/申込情報を確認したい場合は、以下のラジオボタンを選択し切り替えてください
          </li>
        </ul>

        なお代表とする法人を変更したい場合は、アーカイブ法人のリスト右端「…」ボタンから「この法人を代表にする」を選択してください。
      </div>
      <v-radio-group v-model="selectedHojin">
        <div class="d-flex justify-space-between align-center">
          <v-radio
            v-if="mainUseHojinDoc"
            :value="mainUseHojinDoc.domainUID"
            class="my-2"
            label
          >
            <template #label>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row">
                  <v-chip
                    class="ma-2 chips_text"
                    label
                    small
                    color="#4caf50"
                    text-color="#ffffff"
                  >
                    代表
                  </v-chip>
                  <div class="py-2 hojin_name">
                    {{ mainUseHojinDoc.name }}
                  </div>
                </div>
                <div style="font-weight: bold" class="mx-2">
                  法人作成日：{{
                    convertTimestampToDate(mainUseHojinDoc.createdAt)
                  }}
                </div>
              </div>
            </template>
          </v-radio>
          <v-menu offset-y>
            <template #activator="{ on }">
              <v-btn class="ml-10 mr-5" icon text v-on="on">
                <v-icon size="40">mdi-dots-horizontal </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item disabled>
                <v-list-item-title class="font-weight-bold">
                  この法人を代表にする
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-for="doc in archiveHojinDocs" :key="doc.domainUID">
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center">
            <v-radio :value="doc.domainUID" class="my-2" label>
              <template #label>
                <div class="d-flex flex-column">
                  <div class="d-flex flex-row">
                    <v-chip
                      class="ma-2 chips_text"
                      label
                      small
                      color="rgba(0, 0, 0, 0.12)"
                      text-color="rgba(0, 0, 0, 0.87)"
                    >
                      アーカイブ
                    </v-chip>
                    <div class="py-2 hojin_name">
                      {{ doc.name }}
                    </div>
                  </div>
                  <div style="font-weight: bold" class="mx-2">
                    法人作成日：{{ convertTimestampToDate(doc.createdAt) }}
                  </div>
                </div>
              </template>
            </v-radio>
            <v-menu offset-y>
              <template #activator="{ on }">
                <v-btn class="ml-10 mr-5" icon text v-on="on">
                  <v-icon size="40">mdi-dots-horizontal </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  :disabled="isKanriAndChukaiMerge"
                  @click="openDaihyoHojinChangeDialog"
                >
                  <v-list-item-title class="font-weight-bold">
                    この法人を代表にする
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-dialog v-model="isOpenDaihyoHojinChangeDialog" width="600px">
              <v-card>
                <v-card-title class="font-weight-bold">
                  <span>代表法人を変更</span>
                  <v-spacer />
                  <v-btn text right icon @click="closeDaihyoHojinChangeDialog">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-4">
                  <div class="mb-3 ml-1">
                    下記アーカイブ法人を新規内見申込ができる代表法人に変更します。
                  </div>
                  <v-row class="my-0 mx-1">
                    <v-col cols="3" class="header-column">
                      <div>法人名</div>
                    </v-col>
                    <div class="px-2 word_color clo">
                      {{ doc.name }}
                    </div>
                  </v-row>
                  <v-row class="my-0 mx-1">
                    <v-col cols="3" class="header-column">
                      <div>法人作成日</div>
                    </v-col>
                    <div class="px-2 word_color clo">
                      {{ convertTimestampToDate(doc.createdAt) }}
                    </div>
                  </v-row>
                  <v-alert text type="warning" class="mt-3">
                    <div>
                      <span>
                        代表法人を変更すると、この法人の会社情報はいい生活アカウントに登録されている会社情報で上書きされます。</span
                      >
                    </div>
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="closeDaihyoHojinChangeDialog"
                    >キャンセル</v-btn
                  >
                  <v-btn
                    color="primary"
                    :disabled="isKanriAndChukaiMerge"
                    @click="onChangeMainUseHojin(doc.domainUID)"
                  >
                    この法人を代表法人にする
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="onClose">キャンセル</v-btn>
      <v-btn
        color="primary"
        :disabled="selectedHojin === activeHojin"
        @click="changeHojinDomainUID(selectedHojin)"
      >
        切り替え
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { Timestamp } from "firebase/firestore";
import moment from "moment-timezone";
import { IDomain } from "requestform-types";
import {
  ChangeCurrentDomainUIDPayload,
  ChangeDomainUIDPayload
} from "requestform-types/lib/functionsPayload";
import { DomainMap } from "requestform-types/lib/IDomain";

import {
  changeCurrentDomainUID,
  changeDomainUID
} from "@/utilities/firebaseFunctions";
import { useInstanceProxy } from "@/utilities/useInstanceProxy";

export default defineComponent({
  props: {
    mapData: {
      type: Object as PropType<DomainMap>,
      required: true
    },
    domainUid: {
      type: String,
      required: true
    },
    domainUidData: {
      type: Array as PropType<Partial<IDomain>[]>,
      required: true
    },
    isKanriAndChukaiMerge: {
      type: Boolean,
      required: true
    }
  },

  setup(props, context) {
    const instance = useInstanceProxy();
    const { $toast, $loading, $router } = instance;

    const selectedHojin = ref<string>(props.domainUid);

    const mainUseHojinDoc = computed(() =>
      props.domainUidData.find(
        x => x.domainUID === props.mapData.currentDomainUID
      )
    );

    const archiveHojinDocs = computed(() =>
      props.domainUidData
        .filter(x => x.domainUID !== props.mapData.currentDomainUID)
        .sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return a.createdAt < b.createdAt ? -1 : 1;
          }
          return 0;
        })
    );

    const convertTimestampToDate = (hojinCreatedDate: Timestamp) => {
      if (hojinCreatedDate) {
        const milisecToSec = 1000;
        const hojinCreatedDateYmd = moment(
          hojinCreatedDate.seconds * milisecToSec
        ).format("YYYY-MM-DD");

        return hojinCreatedDateYmd;
      }
    };

    const changeHojinDomainUID = async (selectedDomainUID: string) => {
      const selectedDomainUIDBody: ChangeDomainUIDPayload = {
        domainUID: selectedDomainUID
      };
      $loading.start({ absolute: false });
      await changeDomainUID(selectedDomainUIDBody).then(() => {
        $router.go(0);
      });
      $loading.end();
    };

    const isOpenDaihyoHojinChangeDialog = ref<boolean>(false);
    const openDaihyoHojinChangeDialog = () => {
      isOpenDaihyoHojinChangeDialog.value = true;
    };
    const closeDaihyoHojinChangeDialog = () => {
      isOpenDaihyoHojinChangeDialog.value = false;
    };

    const onChangeMainUseHojin = async (domainUID: string) => {
      const selectedHojinBody: ChangeCurrentDomainUIDPayload = {
        domainUID: domainUID
      };
      $loading.start({ absolute: false });
      const activeDomainUID = selectedHojin.value;
      await changeCurrentDomainUID(selectedHojinBody).then(() => {
        $toast.success("代表の法人が変更されました");
      });

      // NOTE: 選択中の法人を代表に切り替えるとチェックが外れてしまう
      if (domainUID === activeDomainUID) {
        setTimeout(() => {
          selectedHojin.value = domainUID;
        }, 500);
      }
      $loading.end();
      closeDaihyoHojinChangeDialog();
    };

    const onClose = () => {
      context.emit("close");
    };

    return {
      mainUseHojin: props.mapData.currentDomainUID,
      mainUseHojinDoc,
      archiveHojinDocs,
      convertTimestampToDate,
      changeHojinDomainUID,
      onChangeMainUseHojin,
      onClose,
      selectedHojin,
      activeHojin: props.domainUid,
      isKanriKaishaMerge: props.isKanriAndChukaiMerge,
      isOpenDaihyoHojinChangeDialog,
      openDaihyoHojinChangeDialog,
      closeDaihyoHojinChangeDialog
    };
  }
});
</script>

<style>
.word_color {
  color: black;
}
.chips_text {
  font-size: 12px;
  font-weight: bold;
  border-radius: 2px;
}
.hojin_name {
  font-weight: bold;
  color: black;
}
.clo {
  display: flex;
  align-items: center;
}
</style>
