import {
  collection,
  DocumentData,
  limit,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  where
} from 'firebase/firestore';
import { domainMapCollectionPath } from 'requestform-types/lib/FirestorePath';
import { DomainMap } from 'requestform-types/lib/IDomain';
import { isDomainMap } from 'requestform-types/lib/TypeGuard';
import { Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';
import { appLogger } from '@/utilities/appLogger';

type DomainMapData = DomainMap | null;

class DomainMapDocumentState extends FirestoreDocumentState<DomainMapData> {}

export class DomainMapDocumentGetters extends FirestoreDocumentGetters<
  DomainMapData,
  DomainMapDocumentState
> {}

class DomainMapDocumentMutations extends FirestoreDocumentMutations<
  DomainMapData,
  DomainMapDocumentState
> {}

class DomainMapDocumentActions extends FirestoreDocumentActions<
  DomainMapData,
  DomainMapDocumentState,
  DomainMapDocumentGetters,
  DomainMapDocumentMutations
> {
  setDocRef(domainUID: string) {
    this.state.isLoaded = false;
    if (
      this.getters.getRef &&
      !this.getters.getData?.relatedDomainUIDs.includes(domainUID)
    ) {
      this.unsetRef();
    }

    return new Promise((resolve, reject) => {
      this.state.unSubscribe = onSnapshot(
        query(
          collection(db, domainMapCollectionPath),
          where('relatedDomainUIDs', 'array-contains', domainUID),
          limit(1)
        ),
        {
          next: snap => {
            const doc = snap.docs[0] as
              | QueryDocumentSnapshot<DocumentData>
              | undefined;
            if (!doc) {
              this.state.data = null;
              this.state.isLoaded = true;
              this.state.ref = null;
              return resolve(true);
            }
            const data = doc.data();
            const ref = doc.ref;
            if (!isDomainMap(data)) {
              const errorMessage = `invalid data. path: ${ref.path}`;
              appLogger.error(errorMessage, { path: ref.path });
              return reject(errorMessage);
            }
            this.state.data = data;
            this.state.isLoaded = true;
            this.state.ref = ref;
            resolve(true);
          },
          error: error => {
            appLogger.debug(
              `Failed onSnapshot domainMap. domainUID: ${domainUID}`,
              {
                error,
                domainUID
              }
            );
            reject(error);
          }
        }
      );
    });
  }
}

export const DomainMapDocumentModule = new Module({
  state: DomainMapDocumentState,
  getters: DomainMapDocumentGetters,
  mutations: DomainMapDocumentMutations,
  actions: DomainMapDocumentActions
});
