import '@mdi/font/css/materialdesignicons.css';

import Vue from 'vue';
import Vuetify, {
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAutocomplete,
  VBadge,
  VBtn,
  VBtnToggle,
  VCalendar,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VChip,
  VCol,
  VCombobox,
  VContainer,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFileInput,
  VFooter,
  VForm,
  VIcon,
  VImg,
  VInput,
  VLazy,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemIcon,
  VListItemTitle,
  VMain,
  VMenu,
  VMessages,
  VNavigationDrawer,
  VOverlay,
  VProgressCircular,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSimpleCheckbox,
  VSimpleTable,
  VSkeletonLoader,
  VSnackbar,
  VSpacer,
  VSpeedDial,
  VStepper,
  VStepperHeader,
  VStepperStep,
  VSubheader,
  VSwitch,
  VTab,
  VTabItem,
  VTabs,
  VTextarea,
  VTextField,
  VTimeline,
  VTimelineItem,
  VToolbar,
  VToolbarTitle,
  VTooltip
} from 'vuetify/lib';
import { Intersect, Ripple } from 'vuetify/lib/directives';
import colors from 'vuetify/lib/util/colors';
import ja from 'vuetify/src/locale/ja';

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAutocomplete,
    VBadge,
    VBtn,
    VBtnToggle,
    VCalendar,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VSimpleCheckbox,
    VChip,
    VCol,
    VCombobox,
    VContainer,
    VDataTable,
    VDatePicker,
    VDialog,
    VDivider,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VFooter,
    VForm,
    VIcon,
    VImg,
    VInput,
    VLazy,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemIcon,
    VListItemTitle,
    VMain,
    VMenu,
    VMessages,
    VNavigationDrawer,
    VOverlay,
    VProgressCircular,
    VRadio,
    VRadioGroup,
    VRow,
    VSelect,
    VSimpleTable,
    VSkeletonLoader,
    VSnackbar,
    VSpacer,
    VSpeedDial,
    VSubheader,
    VSwitch,
    VTab,
    VTabs,
    VTabItem,
    VTextarea,
    VTextField,
    VTimeline,
    VTimelineItem,
    VToolbar,
    VToolbarTitle,
    VTooltip,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VFileInput
  },
  directives: {
    Ripple,
    Intersect
  }
});

/* eslint-disable no-unused-vars, @typescript-eslint/naming-convention */
const lightThemeSettings = {
  primary: '#1F76DD',
  secondary: '#EDC32F',
  accent: '#04E3EC',
  neutral: '#113364',
  neutral_light: '#F3F7FB',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FB8C00',
  ny_reserved: colors.blue.lighten1,
  ny_complete: colors.blue.lighten5,
  ny_cancel: colors.grey.lighten3,
  ny_holiday: '#fCD8D9',
  ny_holiday_font: '#EB5757'
};
/* eslint-enable */

export type VFormObj = Vue & { validate(): boolean };

export default new Vuetify({
  lang: {
    locales: { ja },
    current: 'ja'
  },
  theme: {
    dark: false,
    themes: {
      light: lightThemeSettings
    }
  },
  icons: {
    iconfont: 'mdi'
  }
});
