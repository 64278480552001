import CommonProxy from '@/model/CommonProxy';

export default class RequestProxy {
  private handler = {
    get: (target: any, property: string) => {
      return property in target
        ? target[property]
        : this.defaultValues[property];
    },
    set: (target: any, property: string, value: any) => {
      target[property] = value;
      return true;
    },
    has(target: any, key: string) {
      return key in target;
    }
  };

  readonlyHandler = {
    get: (target: any, property: string) => {
      return property in target
        ? target[property]
        : this.defaultValues[property];
    }
  };

  private defaultValues: any = {
    bukken: new CommonProxy().create({}),
    yachinHoshoKaisha: new CommonProxy().create({}),
    kasaiHokenKaisha: new CommonProxy().create({}),
    chukaiKaisha: new CommonProxy().create({}),
    kanriKaisha: new CommonProxy().create({}),
    honninKakunin: new CommonProxy().create({}),
    hoshounin: {
      birthday: null
    },
    moshikomiDate: null,
    keiyakuKaishiDate: null,
    nyukyoKiboDate: null,
    kinkyuRenrakusaki: {
      birthday: null
    },
    moshikomisha: {
      birthday: null,
      images: []
    },
    nyukyosha1: {
      birthday: null
    },
    hojinMoshikomisha: {
      images: [],
      files: []
    },
    shatakuDaikoKaisha: {
      images: []
    },
    modifiedAt: null,
    createdAt: null,
    status: '作成中'
  };

  create(payload: any) {
    return new Proxy(payload, this.handler);
  }
}
