import { serverTimestamp, setDoc } from 'firebase/firestore';
import { IDocumentBase } from 'requestform-types';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';

type IDomainHoshoKaishaSetting = IDocumentBase;

class DomainHoshoKaishaSettingDocumentState extends FirestoreDocumentState<IDomainHoshoKaishaSetting> {}

class DomainHoshoKaishaSettingDocumentGetters extends FirestoreDocumentGetters<
  IDomainHoshoKaishaSetting,
  DomainHoshoKaishaSettingDocumentState
> {}

class DomainHoshoKaishaSettingDocumentMutations extends FirestoreDocumentMutations<
  IDomainHoshoKaishaSetting,
  DomainHoshoKaishaSettingDocumentState
> {}

export class DomainHoshoKaishaSettingDocumentActions extends FirestoreDocumentActions<
  IDomainHoshoKaishaSetting,
  DomainHoshoKaishaSettingDocumentState,
  DomainHoshoKaishaSettingDocumentGetters,
  DomainHoshoKaishaSettingDocumentMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }
  async update(payload?: Partial<IDomainHoshoKaishaSetting>) {
    if (this.state.ref) {
      const modifierPayload: IDomainHoshoKaishaSetting = {
        modifiedAt: serverTimestamp()
      };
      await setDoc(
        this.state.ref,
        { ...payload, ...modifierPayload },
        { merge: true }
      );
    }
  }
}

export const DomainHoshoKaishaSettingDocumentModule = new Module({
  state: DomainHoshoKaishaSettingDocumentState,
  getters: DomainHoshoKaishaSettingDocumentGetters,
  mutations: DomainHoshoKaishaSettingDocumentMutations,
  actions: DomainHoshoKaishaSettingDocumentActions
});
