import { IDocumentBase } from './IDocumentBase';
import { EsStandardFutaiToritsugiStatus, ReviewStatus } from './IRequest';
import { ValueOf } from './utilities';

/** タグ */
export type ITag = IDocumentBase & {
  tagUID: string;
  /** タグ名称  */
  name: string;
  /** タグ説明 */
  comment: string;
  /** タグ背景色 */
  color: number | null;
};

export type DisplayTagType = {
  text: string;
  color: string;
  textColor?: string;
  clickable?: boolean;
  icon?: string;
};

// WARNING: 基本的には "末尾" に追加すること
export const SystemTags = {
  Cancel: 1,
  KokyakuLinkedToOne: 2,
  Multiple: 3,
  EposReviewing: 4,
  EposApproval: 5,
  EposDisapproval: 6,
  EposCancel: 7,
  NotEnteredReview: 8,
  ExpiredReview: 9,
  EnteredReview: 10,
  EsStandardHoshoReviewing: 11,
  EsStandardHoshoApproval: 12,
  EsStandardHoshoDisapproval: 13,
  EsStandardHoshoCancel: 14,
  EsStandardHoshoPending: 15,
  EsStandardHokenReviewing: 16,
  EsStandardHokenApproval: 17,
  EsStandardHokenDisapproval: 18,
  EsStandardHokenCancel: 19,
  EsStandardHokenPending: 20,
  ProxyInputed: 21,
  LifeinLinked: 22,
  EsStandardDUALToritsugiWaiting: 23,
  EsStandardDUALToritsugiReceiving: 24,
  EsStandardDUALToritsugiApproval: 25,
  EsStandardDUALToritsugiDisapproval: 26,
  EsStandardHousemateChukaiSysLinked: 27,
  JaccsReviewing: 28,
  JaccsApproval: 29,
  JaccsDisapproval: 30,
  JaccsCancel: 31
} as const;
export type SystemTags = ValueOf<typeof SystemTags>;

// NOTE: 仲介会社に表示するシステムタグ
export const chukaiKaishaDisplaySystemTags: SystemTags[] = [
  SystemTags.Cancel,
  SystemTags.NotEnteredReview,
  SystemTags.ExpiredReview,
  SystemTags.EnteredReview,
  SystemTags.ProxyInputed
];

// NOTE: 仲介会社の検索に表示するシステムタグ
export const chukaiKaishaSearchSystemTags: SystemTags[] = [
  SystemTags.NotEnteredReview,
  SystemTags.ExpiredReview,
  SystemTags.EnteredReview,
  SystemTags.ProxyInputed
];

export const eposSystemTags: SystemTags[] = [
  SystemTags.EposReviewing,
  SystemTags.EposApproval,
  SystemTags.EposDisapproval,
  SystemTags.EposCancel
];

export const esStandardDUALToritsugiSystemTags = [
  SystemTags.EsStandardDUALToritsugiWaiting,
  SystemTags.EsStandardDUALToritsugiReceiving,
  SystemTags.EsStandardDUALToritsugiApproval,
  SystemTags.EsStandardDUALToritsugiDisapproval
] as const;

export const jaccsSystemTags: SystemTags[] = [
  SystemTags.JaccsReviewing,
  SystemTags.JaccsApproval,
  SystemTags.JaccsDisapproval,
  SystemTags.JaccsCancel
];

export const EsStandardHousemateChukaiSysSystemTags = [
  SystemTags.EsStandardHousemateChukaiSysLinked
] as const;

export const SystemTagsMap = new Map<number, DisplayTagType>([
  [
    SystemTags.Cancel,
    { text: '申込キャンセル', color: 'grey', textColor: 'white' }
  ],
  [
    SystemTags.KokyakuLinkedToOne,
    { text: '賃貸クラウド顧客連携済み', color: 'primary', textColor: 'white' }
  ],
  [
    SystemTags.Multiple,
    {
      text: '複数申込',
      color: 'error',
      textColor: 'white',
      clickable: true,
      icon: 'mdi-magnify'
    }
  ],
  [
    SystemTags.EposReviewing,
    { text: 'エポス依頼中', color: 'light-green', textColor: 'white' }
  ],
  [
    SystemTags.EposApproval,
    { text: 'エポスOK', color: 'green', textColor: 'white' }
  ],
  [
    SystemTags.EposDisapproval,
    { text: 'エポスNG', color: 'red', textColor: 'white' }
  ],
  [
    SystemTags.EposCancel,
    { text: 'エポスキャンセル', color: 'grey', textColor: 'white' }
  ],
  [
    SystemTags.JaccsReviewing,
    { text: 'ジャックス依頼中', color: 'light-green', textColor: 'white' }
  ],
  [
    SystemTags.JaccsApproval,
    { text: 'ジャックスOK', color: 'green', textColor: 'white' }
  ],
  [
    SystemTags.JaccsDisapproval,
    { text: 'ジャックスNG', color: 'red', textColor: 'white' }
  ],
  [
    SystemTags.JaccsCancel,
    { text: 'ジャックスキャンセル', color: 'grey', textColor: 'white' }
  ],
  [
    SystemTags.NotEnteredReview,
    { text: '審査必要項目未入力', color: 'orange', textColor: 'white' }
  ],
  [
    SystemTags.ExpiredReview,
    { text: '審査必須項目入力期限切れ', color: 'red', textColor: 'white' }
  ],
  [
    SystemTags.EnteredReview,
    { text: '審査必要項目入力済', color: 'green', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHoshoReviewing,
    { text: '保証会社審査中', color: 'light-green', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHoshoApproval,
    { text: '保証会社審査OK', color: 'green', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHoshoDisapproval,
    { text: '保証会社審査NG', color: 'red', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHoshoCancel,
    { text: '保証会社審査キャンセル', color: 'grey', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHoshoPending,
    { text: '保証会社審査保留中', color: 'orange', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHokenReviewing,
    { text: '保険会社申請中', color: 'light-green', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHokenApproval,
    { text: '保険会社申請受理', color: 'green', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHokenDisapproval,
    { text: '保険会社申請却下', color: 'red', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHokenCancel,
    { text: '保険会社申請キャンセル', color: 'grey', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHokenPending,
    { text: '保険会社申請保留中', color: 'orange', textColor: 'white' }
  ],
  [
    SystemTags.ProxyInputed,
    { text: '代理入力有り', color: 'primary', textColor: 'white' }
  ],
  [
    SystemTags.LifeinLinked,
    { text: 'ライフイン連携済', color: 'primary', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardDUALToritsugiWaiting,
    { text: 'DUAL取次対応待ち', color: 'light-green', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardDUALToritsugiReceiving,
    { text: 'DUAL取次対応中', color: 'orange', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardDUALToritsugiApproval,
    { text: 'DUAL取次OK', color: 'green', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardDUALToritsugiDisapproval,
    { text: 'DUAL取次NG', color: 'red', textColor: 'white' }
  ],
  [
    SystemTags.EsStandardHousemateChukaiSysLinked,
    { text: '仲介SYS連携済', color: 'green', textColor: 'white' }
  ]
]);

export enum tagColors {
  grey = 1,
  red,
  purple,
  indigo,
  blue,
  lightGreen,
  yellow,
  orange,
  brown
}

export const tagColorsMap = new Map<tagColors, string>([
  [tagColors.grey, 'grey lighten-2'],
  [tagColors.red, 'red lighten-4'],
  [tagColors.purple, 'purple lighten-4'],
  [tagColors.indigo, 'indigo lighten-4'],
  [tagColors.blue, 'blue lighten-4'],
  [tagColors.lightGreen, 'light-green lighten-4'],
  [tagColors.yellow, 'yellow lighten-4'],
  [tagColors.orange, 'orange lighten-4'],
  [tagColors.brown, 'brown lighten-4']
]);

export type ReviewStatusTagMap = Map<ReviewStatus, SystemTags>;

export const EposTagMap: ReviewStatusTagMap = new Map([
  [ReviewStatus.Reviewing, SystemTags.EposReviewing],
  [ReviewStatus.Approval, SystemTags.EposApproval],
  [ReviewStatus.Disapproval, SystemTags.EposDisapproval],
  [ReviewStatus.Cancel, SystemTags.EposCancel]
]);

export const EsStandardHoshoKaishaTagMap: ReviewStatusTagMap = new Map([
  [ReviewStatus.Reviewing, SystemTags.EsStandardHoshoReviewing],
  [ReviewStatus.Approval, SystemTags.EsStandardHoshoApproval],
  [ReviewStatus.Disapproval, SystemTags.EsStandardHoshoDisapproval],
  [ReviewStatus.Cancel, SystemTags.EsStandardHoshoCancel],
  [ReviewStatus.Pending, SystemTags.EsStandardHoshoPending]
]);

export const JaccsTagMap: ReviewStatusTagMap = new Map([
  [ReviewStatus.Reviewing, SystemTags.JaccsReviewing],
  [ReviewStatus.Approval, SystemTags.JaccsApproval],
  [ReviewStatus.Disapproval, SystemTags.JaccsDisapproval],
  [ReviewStatus.Cancel, SystemTags.JaccsCancel]
]);

export const EsStandardHokenKaishaTagMap: ReviewStatusTagMap = new Map([
  [ReviewStatus.Reviewing, SystemTags.EsStandardHokenReviewing],
  [ReviewStatus.Approval, SystemTags.EsStandardHokenApproval],
  [ReviewStatus.Disapproval, SystemTags.EsStandardHokenDisapproval],
  [ReviewStatus.Cancel, SystemTags.EsStandardHokenCancel],
  [ReviewStatus.Pending, SystemTags.EsStandardHokenPending]
]);

export const EsStandardDUALToritsugiTagMap: Map<
  EsStandardFutaiToritsugiStatus,
  typeof esStandardDUALToritsugiSystemTags[number]
> = new Map([
  [
    EsStandardFutaiToritsugiStatus.Waiting,
    SystemTags.EsStandardDUALToritsugiWaiting
  ],
  [
    EsStandardFutaiToritsugiStatus.Receiving,
    SystemTags.EsStandardDUALToritsugiReceiving
  ],
  [
    EsStandardFutaiToritsugiStatus.Approval,
    SystemTags.EsStandardDUALToritsugiApproval
  ],
  [
    EsStandardFutaiToritsugiStatus.Disapproval,
    SystemTags.EsStandardDUALToritsugiDisapproval
  ]
]);

export const EsStandardHousemateChukaiSysTagMap: ReviewStatusTagMap = new Map([
  [ReviewStatus.Reviewing, SystemTags.EsStandardHousemateChukaiSysLinked]
]);
