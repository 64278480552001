import {
  browserLocalPersistence,
  EmailAuthProvider,
  inMemoryPersistence,
  reauthenticateWithCredential,
  sendPasswordResetEmail as doSendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut as doSignOut,
  updatePassword as doUpdatePassword,
  User
} from 'firebase/auth';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { User as EsaUser } from 'requestform-types/lib/esa/user';
import { accountCollectionPath } from 'requestform-types/lib/FirestorePath';
import { isAccount } from 'requestform-types/lib/TypeGuard';
import { NavigationGuardNext } from 'vue-router';
import { Store } from 'vuex';
import {
  Actions,
  Context,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module';

import { Auth0, getAuth0, getRedirectUri } from '@/auth0/client';
import { auth, db, signInWithCustomTokenSilently } from '@/firebase/firebase';
import { appLogger, parseError } from '@/utilities/appLogger';
import { getEsaUserList } from '@/utilities/esa';
import {
  fillingOnDomainGuid,
  publishFirebaseToken
} from '@/utilities/firebaseFunctions';
import { getUserOrganization, OneUserOrganization } from '@/utilities/proxy';

import { DisposableModule } from './DisposableModule';
import { LicenseCollectionModule } from './LicenseCollectionModule';

export type AfterAuthResolvedTask = {
  (user: User | null, domainUID: string): any;
};

const getAccount = async (accountUID: string) => {
  return await getDoc(doc(collection(db, accountCollectionPath), accountUID))
    .then(snap => {
      const data = snap.data();
      if (!isAccount(data)) return undefined;
      return data;
    })
    .catch(e => {
      throw e;
    });
};

export class SignInState {
  auth0: Auth0 | null = null;
  user: User | null = null;
  domainUID: string = '';
  userName: string = '';
  email: string = '';
  // ログイン状態が確定したか
  isAuthResolved: boolean = false;
  // ログイン状態確定後に実行するタスク
  afterAuthResolvedTasks: AfterAuthResolvedTask[] = [];
  // いい生活アカウントユーザーID
  idProviderUserUID: string = '';
  // いい生活アカウントでサインイン後のリダイレクト先
  auth0RedirectTo: string = '';
  // Auth0(いい生活アカウント)でサインイン中か
  isAuthenticatedWithAuth0: boolean = false;
  // oneから取得した権限情報 null:取得に失敗
  hasMasterRoleFlag: boolean | null = null;
  // oneの所属組織情報
  oneUserOrganizations: OneUserOrganization[] = [];
  // ESA所属法人のユーザーリスト(エラーで取得できなかった場合はnullとしている)
  esaUserList: EsaUser[] | null = [];
}

export class SignInGetters extends Getters<SignInState> {
  get auth0() {
    return this.state.auth0;
  }
  get getUser() {
    return this.state.user;
  }
  get accountUID() {
    return this.state.user ? this.state.user.uid : '';
  }
  get email() {
    return this.state.email;
  }
  get domainUID() {
    return this.state.domainUID;
  }
  get isSignIn() {
    return !!this.state.user;
  }
  get isIDProviderAccount() {
    return this.state.isAuthenticatedWithAuth0;
  }
  get userName() {
    return this.state.userName;
  }
  get getIsAuthResolved(): boolean {
    return this.state.isAuthResolved;
  }
  get getAfterAuthResolvedTasks(): AfterAuthResolvedTask[] {
    return this.state.afterAuthResolvedTasks;
  }
  get idProviderUserUID(): string {
    return this.state.idProviderUserUID;
  }
  get auth0RedirectTo(): string {
    return this.state.auth0RedirectTo;
  }
  get getHasMasterRoleFlag() {
    return this.state.hasMasterRoleFlag;
  }
  get getOneUserOrganizations(): OneUserOrganization[] {
    return this.state.oneUserOrganizations;
  }
  get getEsaUserList() {
    return this.state.esaUserList;
  }
}

export class SignInMutations extends Mutations<SignInState> {
  setAuth0(auth0: Auth0 | null) {
    this.state.auth0 = auth0;
  }
  setUser(user: User | null) {
    this.state.user = user;
  }
  setDomainUID(uid: string) {
    this.state.domainUID = uid;
  }
  setUserName(userName: string) {
    this.state.userName = userName;
  }
  setUserEmail(email: string) {
    this.state.email = email;
  }
  setIdProviderUserUID(v: string) {
    this.state.idProviderUserUID = v;
  }
  setIsAuthResolved(v: boolean) {
    this.state.isAuthResolved = v;
  }
  setAuth0RedirectTo(v: string) {
    this.state.auth0RedirectTo = v;
  }
  setIsAuthenticatedWithAuth0(v: boolean) {
    this.state.isAuthenticatedWithAuth0 = v;
  }
  setHasMasterRoleFlag(v: boolean) {
    this.state.hasMasterRoleFlag = v;
  }
  setOneUserOrganizations(v: OneUserOrganization[]) {
    this.state.oneUserOrganizations = v;
  }
  pushAfterAuthResolvedTask(v: AfterAuthResolvedTask) {
    this.state.afterAuthResolvedTasks.push(v);
  }
  clearAfterAuthResolvedTask() {
    this.state.afterAuthResolvedTasks.length = 0;
  }
  setEsaUserList(v: EsaUser[] | null) {
    this.state.esaUserList = v;
  }
}

export class SignInActions extends Actions<
  SignInState,
  SignInGetters,
  SignInMutations
> {
  disposableModule?: Context<typeof DisposableModule>;
  licenseCollectionModule?: Context<typeof LicenseCollectionModule>;
  $init(store: Store<any>) {
    this.disposableModule = DisposableModule.context(store);
    this.licenseCollectionModule = LicenseCollectionModule.context(store);
  }
  async getOrInitAuth0(): Promise<Auth0> {
    if (this.getters.auth0) {
      return this.getters.auth0;
    }
    const auth0 = await getAuth0();
    this.mutations.setAuth0(auth0);
    return auth0;
  }
  auth0Redirect(next: NavigationGuardNext<Vue>) {
    const redirectTo = this.getters.auth0RedirectTo;
    if (redirectTo) {
      this.mutations.setAuth0RedirectTo('');
      next({ path: redirectTo });
    }
  }
  async syncIsAuthenticatedWithAuth0(): Promise<boolean> {
    const auth0 = await this.getOrInitAuth0();
    const isAuthenticated = await auth0.isAuthenticated();
    this.mutations.setIsAuthenticatedWithAuth0(isAuthenticated);
    const persistence = isAuthenticated
      ? inMemoryPersistence
      : browserLocalPersistence;
    await setPersistence(auth, persistence);
    return isAuthenticated;
  }
  pushAfterAuthResolvedTask(v: AfterAuthResolvedTask) {
    if (this.state.isAuthResolved) {
      return v(this.getters.getUser, this.getters.domainUID);
    }
    this.mutations.pushAfterAuthResolvedTask(v);
  }
  setIsAuthResolved(v: boolean) {
    this.mutations.setIsAuthResolved(v);
  }
  // NOTE: ログイン状態が変わるたびに呼び出される処理
  async changeAuthCallback(user: User | null) {
    this.mutations.setIsAuthResolved(false);
    await this.syncIsAuthenticatedWithAuth0();
    this.mutations.setUser(user);
    if (user) {
      // TODO: fillingOnDomainGuidの処理も createFirebaseCustomToken に含めるか検討
      // OneのdomainGuidを補完
      await fillingOnDomainGuid().catch(e => {
        appLogger.error('error: fillingOnDomainGuid', parseError(e));
      });
      // アカウント情報を取得
      const accountData = await getAccount(user.uid).catch(e => {
        throw e;
      });
      if (!accountData) throw new Error('Failed get account data.');
      this.mutations.setDomainUID(accountData.domainUID);
      this.mutations.setUserName(accountData.userName ?? '');
      this.mutations.setUserEmail(user.email ?? accountData.email ?? '');
      this.mutations.setIdProviderUserUID(accountData.idProviderUserUID ?? '');
    } else {
      this.mutations.setDomainUID('');
      this.mutations.setUserName('');
      this.mutations.setUserEmail('');
      this.mutations.setIdProviderUserUID('');

      // Auth0の認証をチェック
      const auth0 = await this.getOrInitAuth0();
      const redirectTo = await auth0.handleRedirectCallback();
      const isAuthenticated = await this.syncIsAuthenticatedWithAuth0();

      // Auth0の認証のみがある場合はfirebaseの認証を試みる
      if (isAuthenticated) {
        await auth0
          .getTokenSilently()
          .then(token => {
            return publishFirebaseToken(token);
          })
          .then(customToken => {
            return signInWithCustomTokenSilently(customToken);
          })
          .catch(async e => {
            await appLogger.error(
              'firebase authentication with custom token failed',
              { e }
            );
            // customTokenの取得に失敗したら強制ログアウトでリセットする
            return this.signOutFromAuth0('/signIn');
          });
        if (redirectTo) {
          this.mutations.setAuth0RedirectTo(redirectTo);
        }
        return;
      }
    }
    this.mutations.setIsAuthResolved(true);
    await Promise.all(
      this.state.afterAuthResolvedTasks.map(v =>
        v(this.getters.getUser, this.getters.domainUID)
      )
    );
    this.mutations.clearAfterAuthResolvedTask();
  }

  async legacySignIn(payload: { email: string; password: string }) {
    await signInWithEmailAndPassword(
      auth,
      payload.email,
      payload.password
    ).catch(err => {
      console.log('error: ', err);
      throw err;
    });
    this.mutations.setIsAuthResolved(false);
    console.log('legacySignIn');
  }

  async signIn() {
    const auth0 = await this.getOrInitAuth0();
    const redirectTo =
      // eslint-disable-next-line compat/compat
      new URLSearchParams(window.location.search).get('redirect') || '/';
    await auth0.loginWithRedirect({
      appState: { targetUrl: redirectTo }
    });
  }

  signOutFromFirebase() {
    return doSignOut(auth);
  }

  async signOutFromAuth0(redirectTo: string) {
    const auth0 = await this.getOrInitAuth0();
    await auth0.logout({
      returnTo: `${getRedirectUri()}${redirectTo}`
    });
    await this.syncIsAuthenticatedWithAuth0();
  }

  async signOut(redirectTo: string = '/signIn') {
    this.mutations.setIsAuthResolved(false);
    await this.disposableModule?.actions.dispose();
    await this.signOutFromFirebase().catch(err => {
      this.mutations.setIsAuthResolved(true);
      appLogger.error(err);
    });
    if (this.getters.isIDProviderAccount) {
      await this.signOutFromAuth0(redirectTo);
    }
  }

  async updatePassword(payload: { oldPassword: string; newPassword: string }) {
    const user = auth.currentUser;
    const email = this.getters.getUser ? this.getters.getUser.email : '';
    if (!user || !email) {
      console.log('not signIn');
      return;
    }
    // NOTE: 直近でログインしている必要がある
    // https://firebase.google.com/docs/auth/web/manage-users?hl=ja#re-authenticate_a_user
    await reauthenticateWithCredential(
      user,
      EmailAuthProvider.credential(email, payload.oldPassword)
    ).catch(err => {
      throw err;
    });
    await doUpdatePassword(user, payload.newPassword);
    console.log('success');
  }

  async updateProfile(payload: { userName: string }) {
    const user = auth.currentUser;
    const email = this.getters.getUser ? this.getters.getUser.email : '';
    if (!user || !email) {
      console.log('not signIn');
      return;
    }
    const accountRef = doc(
      collection(db, accountCollectionPath),
      this.getters.accountUID
    );
    await setDoc(accountRef, payload, { merge: true });
    this.commit('setUserName', payload.userName);
    console.log('success');
  }

  sendPasswordResetEmail(payload: { email: string }) {
    return doSendPasswordResetEmail(auth, payload.email);
  }

  async getOneUserInfo() {
    const userOrganization = await getUserOrganization().catch(e => {
      const msg = 'error getUserOrganization';
      import.meta.env.NODE_ENV === 'development'
        ? appLogger.warn(
            `${msg}. SumaiEntryProxy must be started localhost`,
            parseError(e)
          )
        : appLogger.error(msg, parseError(e));
    });
    if (userOrganization) {
      this.commit('setHasMasterRoleFlag', userOrganization.hasMasterRoleFlag);
      this.commit(
        'setOneUserOrganizations',
        userOrganization.userOrganizations.filter(
          organization => organization.customerID !== '0'
        )
      );
    }
    return !!userOrganization;
  }

  async updateEsaUserList() {
    if (
      !this.state.isAuthenticatedWithAuth0 ||
      !this.licenseCollectionModule?.getters.hasToDoKanriLicense
    )
      return [];
    const userList = await this.state.auth0
      ?.getTokenSilently()
      .then(async token => await getEsaUserList(token, this.state.domainUID));
    this.commit('setEsaUserList', userList || null);
  }
}

// TODO: vuex-smart-moduleを最新化して、createMapper() で返すようにする
export const SignInModule = new Module({
  state: SignInState,
  getters: SignInGetters,
  mutations: SignInMutations,
  actions: SignInActions
});
