import { DateString } from 'requestform-types/lib/DateTimeString';
import { isString } from 'requestform-types/lib/TypeGuard';
import { Dictionary } from 'vue-router/types/router';

import { SearchConditionKeys as naikenYoyakuSearchConditionKeys } from './naikenYoyakuSearchConditionsConverter';
import { SearchConditionKeys as RequestSearchConditionKeys } from './requestSearchConditionsConverter';

type SearchConditionKeys =
  | naikenYoyakuSearchConditionKeys
  | RequestSearchConditionKeys;

export const DomainFilter = {
  Ours: '自社物件',
  Theirs: '他社物件'
} as const;
export type DomainFilter = typeof DomainFilter[keyof typeof DomainFilter];
export const isDomainFilter = (v: unknown): v is DomainFilter =>
  (Object.values(DomainFilter) as unknown[]).includes(v);

export const isDateString = (v: unknown): v is DateString => {
  if (!isString(v)) return false;
  const { year, month, day } =
    /^(?<year>\d{4})-(?<month>(0[1-9]|1[012]))-(?<day>(0[1-9]|[12][0-9]|3[01]))$/.exec(
      v
    )?.groups ?? {};
  return !isNaN(Date.parse(`${year}-${month}-${day}`));
};

export const getDateFilterToString = (
  startDate: DateString | undefined,
  endDate: DateString | undefined
) => {
  if (!startDate && !endDate) {
    return undefined;
  }
  return `${startDate ?? ''} ～ ${endDate ?? ''}`;
};

export type Converter<T extends {}, U extends keyof T> = {
  toQuery: (v: Partial<T>) => string | string[] | undefined;
  fromQuery: (v: Dictionary<string | (string | null)[]>) => T[U] | undefined;
};

const getCacheKey = (
  domain: DomainFilter,
  key: SearchConditionKeys,
  isNaikenYoyaku: boolean = false
) => {
  const domainKey = domain === DomainFilter.Theirs ? 'theirs' : 'ours';
  return isNaikenYoyaku
    ? `naiken_searchCondition_${domainKey}_${key}`
    : `searchCondition_${domainKey}_${key}`;
};

export const setCache = (
  domain: DomainFilter,
  key: SearchConditionKeys,
  value: string,
  isNaikenYoyaku: boolean = false
) => localStorage.setItem(getCacheKey(domain, key, isNaikenYoyaku), value);

export const getCache = (
  domain: DomainFilter,
  key: SearchConditionKeys,
  isNaikenYoyaku: boolean = false
): string | null => {
  return localStorage.getItem(getCacheKey(domain, key, isNaikenYoyaku));
};

export const deleteCache = (
  domain: DomainFilter,
  key: SearchConditionKeys,
  isNaikenYoyaku: boolean = false
) => {
  localStorage.removeItem(getCacheKey(domain, key, isNaikenYoyaku));
};
