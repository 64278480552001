import { collection } from 'firebase/firestore';
import { ReactionNeededCounter } from 'requestform-types/lib/FirestoreCounter';
import { domainSubCollectionCounterPath } from 'requestform-types/lib/FirestorePath';
import { Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirebaseUniqueDocumentCollectionBase';

type DomainCounterCollection = {
  reactionNeeded: ReactionNeededCounter;
};

class DomainCounterCollectionState extends FirestoreCollectionState<DomainCounterCollection> {}

export class DomainCounterCollectionGetters extends FirestoreCollectionGetters<
  DomainCounterCollection,
  DomainCounterCollectionState
> {}

class DomainCounterCollectionMutations extends FirestoreCollectionMutations<
  DomainCounterCollection,
  DomainCounterCollectionState
> {}

export class DomainCounterCollectionActions extends FirestoreCollectionActions<
  DomainCounterCollection,
  DomainCounterCollectionState,
  DomainCounterCollectionGetters,
  DomainCounterCollectionMutations
> {
  setDomainCounterRef(domainUID: string) {
    if (!this.state.ref && !!domainUID) {
      return this.setDocumentRef(domainSubCollectionCounterPath(domainUID));
    }
  }
  setDocumentRef(collcetionPath: string) {
    const dRef = collection(db, collcetionPath);
    return this.setRef(dRef);
  }
}

export const DomainCounterCollectionModule = new Module({
  state: DomainCounterCollectionState,
  getters: DomainCounterCollectionGetters,
  mutations: DomainCounterCollectionMutations,
  actions: DomainCounterCollectionActions
});
