export default class CommonProxy {
  private handler = {
    get: (target: any, property: string) => {
      return property in target
        ? target[property]
        : this.defaultValues[property];
    },
    set: (target: any, property: string, value: any) => {
      target[property] = value;
      return true;
    },
    has(target: any, key: string) {
      return key in target;
    }
  };

  private defaultValues: any = {};

  create(payload: any) {
    return new Proxy(payload, this.handler);
  }
}
