import {
  domainDocumentPath,
  informationCollectionPath,
  licenseCollectionPath
} from 'requestform-types/lib/FirestorePath';
import { RouteConfig } from 'vue-router';

import { DomainDocumentModule } from '@/requestform/store/DomainDocumentModule';
import { DomainSettingDocumentModule } from '@/requestform/store/DomainSettingDocumentModule';
import { InformationCollectionModule } from '@/requestform/store/InformationCollectionModule';
import { LicenseCollectionModule } from '@/requestform/store/LicenseCollectionModule';
import { SignInModule } from '@/requestform/store/SignInModule';
import { VersionDocumentModule } from '@/store/VersionDocumentModule';

import store from '../store';
import { DomainMapDocumentModule } from '../store/DomainMapDocumentModule';
import { initRoute } from './';
import { requestRoute } from './requestRoute';

const BizSupportMain = () =>
  import(
    /* webpackChunkName: "bizSupportMain"*/ '../views/bizSupport/BizSupportMain.vue'
  );
const FutaiToritsugiList = () =>
  import(
    /* webpackChunkName: "futaiToritsugiList"*/ '../views/bizSupport/FutaiToritsugiList.vue'
  );

const signInModule = SignInModule.context(store);
const domainMapDocumentModule = DomainMapDocumentModule.context(store);
const domainDocumentModule = DomainDocumentModule.context(store);
const informationCollectionModule = InformationCollectionModule.context(store);
const domainSettingDocumentModule = DomainSettingDocumentModule.context(store);
const licenseCollectionModule = LicenseCollectionModule.context(store);
const versionDocumentModule = VersionDocumentModule.context(store);

const initRefsForBizSupportRoute = (domainUID: string) => {
  const tasks: Promise<any>[] = [];
  if (!domainMapDocumentModule.getters.getRef) {
    tasks.push(domainMapDocumentModule.actions.setDocRef(domainUID));
  }
  if (!domainDocumentModule.getters.getRef) {
    tasks.push(
      domainDocumentModule.actions.setDocumentRef(domainDocumentPath(domainUID))
    );
  }
  if (!informationCollectionModule.getters.getRef) {
    tasks.push(
      informationCollectionModule.actions.setCollectionRef(
        informationCollectionPath
      )
    );
  }
  if (!domainSettingDocumentModule.getters.getRef) {
    tasks.push(
      domainSettingDocumentModule.actions.setDomainDocumentRef(domainUID)
    );
  }
  if (!licenseCollectionModule.getters.getRef) {
    tasks.push(
      licenseCollectionModule.actions.setCollectionRef(
        licenseCollectionPath(domainUID)
      )
    );
  }
  if (!versionDocumentModule.getters.getRef) {
    tasks.push(versionDocumentModule.actions.setVersionDocumentRef());
  }
  if (!tasks.length) return;
  return Promise.all(tasks);
};

export const bizSupportRoute: RouteConfig = {
  path: '/biz-support',
  component: BizSupportMain,
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(
      async (user, domainUID) => {
        const resolvedRoute = await initRoute(
          to,
          user,
          domainUID,
          initRefsForBizSupportRoute
        );
        if (resolvedRoute) {
          return next(resolvedRoute);
        }
        // Bizサポートライセンスを持っていない場合は申込一覧に戻す
        if (!licenseCollectionModule.getters.hasBizSupportLicense) {
          return next(requestRoute);
        }
        next();
      }
    );
  },
  children: [
    {
      path: '',
      name: 'FutaiToritsugiList',
      component: FutaiToritsugiList
    }
  ]
};
