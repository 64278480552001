import {
  doc,
  DocumentReference,
  getDoc,
  setDoc,
  updateDoc
} from 'firebase/firestore';
import { get } from 'lodash-es';
import { domainFormSettingDocumentPath } from 'requestform-types/lib/FirestorePath';
import { IDomainSetting } from 'requestform-types/lib/IDomainSetting';
import {
  configureKey,
  IDomainFormSetting,
  IDomainFormSettingValue,
  IUserConfigureValue,
  visibleConfigureValues
} from 'requestform-types/lib/IFormConfigure';
import { UserRequestType } from 'requestform-types/lib/IRequest';
import { Path } from 'requestform-types/lib/PathQuery';
import { hasProperty, isDefined } from 'requestform-types/lib/TypeGuard';
import { Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';
import { userRequestTypeKeys } from '@/utilities/formSetting';

class DomainFormSettingDocumentState extends FirestoreDocumentState<IDomainFormSetting> {}

export class DomainFormSettingDocumentGetters extends FirestoreDocumentGetters<
  IDomainFormSetting,
  DomainFormSettingDocumentState
> {}

class DomainFormSettingDocumentMutations extends FirestoreDocumentMutations<
  IDomainFormSetting,
  DomainFormSettingDocumentState
> {}

export class DomainFormSettingDocumentActions extends FirestoreDocumentActions<
  IDomainFormSetting,
  DomainFormSettingDocumentState,
  DomainFormSettingDocumentGetters,
  DomainFormSettingDocumentMutations
> {
  // TODO: ベースクラスに移動できるかも
  async update(payload: Partial<IDomainFormSetting>) {
    if (this.state.ref) {
      // TODO: 差分判定して差分のみ変更する？
      await setDoc(this.state.ref, payload, { merge: true });
    }
  }
  setDomainDocumentRef(domainUID: string) {
    if (!this.state.ref && !!domainUID) {
      this.setDocumentRef(domainFormSettingDocumentPath(domainUID));
    }
  }
}

export const DomainFormSettingDocumentModule = new Module({
  state: DomainFormSettingDocumentState,
  getters: DomainFormSettingDocumentGetters,
  mutations: DomainFormSettingDocumentMutations,
  actions: DomainFormSettingDocumentActions
});

export async function fetchDomainFormSetting(
  domainUID: string
): Promise<IDomainFormSetting> {
  const snap = await getDoc(doc(db, domainFormSettingDocumentPath(domainUID)));
  if (snap.exists()) {
    return snap.data() as IDomainFormSetting;
  }
  return {} as IDomainFormSetting;
}

export const setPropertyMap: (
  propertyMap: Map<string, IUserConfigureValue>,
  domainFormSettingData: IDomainFormSetting,
  isUseReviewForm: boolean | undefined,
  requestType: UserRequestType,
  path: Path<IDomainFormSettingValue>
) => void = (propertyMap, formSetting, isUseReviewForm, reqType, path) => {
  const basePath = `${reqType}.${path}`;
  const basePathParam = get(formSetting, basePath);
  const configureValues: configureKey[] = [
    ...visibleConfigureValues,
    'require'
  ];
  const pathMap = new Map<configureKey, string>();
  configureValues.forEach(v => {
    pathMap.set(v, `${basePath}.${v}`);
  });
  const isVisible = get(formSetting, pathMap.get('visible') ?? '');
  // NOTE: 設定済みのvisibleが参照できなければスキップ
  if (!isDefined(isVisible)) {
    return;
  }
  const isRequire = get(formSetting, pathMap.get('require') ?? '');
  const isVisibleAtReview = get(
    formSetting,
    pathMap.get('visibleAtReview') ?? ''
  );
  // NOTE: 既に表示かつ必須設定済ならスキップ
  if ((isVisible || isVisibleAtReview) && isRequire) {
    return;
  }
  if (!isUseReviewForm) {
    // NOTE: 2段階申込設定がOFFなら申込必須とする
    if (isVisible) {
      propertyMap.set(basePath, {
        ...basePathParam,
        require: true
      });
    } else {
      propertyMap.set(basePath, {
        ...basePathParam,
        visible: true,
        require: true
      });
    }
    return;
  }
  // NOTE: 元々申込項目表示ONの項目は申込必須とする
  if (isVisible || isVisibleAtReview) {
    propertyMap.set(basePath, {
      ...basePathParam,
      require: true
    });
    return;
  }
  // NOTE: 2段階申込設定ON、かつ非表示のものは全て審査必須項目とする
  propertyMap.set(basePath, {
    ...basePathParam,
    require: true,
    visibleAtReview: true
  });
  return;
};

export const updateDomainFormSetting: (
  documentRef: DocumentReference<IDomainFormSetting>,
  domainFormSettingData: IDomainFormSetting,
  requestType: UserRequestType,
  pathList: Path<IDomainFormSettingValue>[],
  useReviewFormSetting: IDomainSetting['useReviewForm']
) => Promise<void> = async (
  documentRef,
  domainFormSettingData,
  requestType,
  pathList,
  useReviewFormSetting
) => {
  // NOTE: 2段階設定のON・OFF状態を取得
  const hasUseReviewFormSetting = (
    item: any
  ): item is Record<UserRequestType, boolean> => {
    return hasProperty(item, requestType);
  };
  const isUseReviewForm = hasUseReviewFormSetting(useReviewFormSetting)
    ? useReviewFormSetting[requestType]
    : undefined;
  const propertyMap = new Map<string, IUserConfigureValue>();
  for (const path of pathList) {
    setPropertyMap(
      propertyMap,
      domainFormSettingData,
      isUseReviewForm,
      requestType,
      path
    );
  }
  if (propertyMap.size) {
    await Promise.all(
      Array.from(propertyMap.entries()).map(([path, prop]) =>
        updateDoc(documentRef, path, prop)
      )
    );
  }
};

export const eposRequiredFilter: (
  pathList: Path<IDomainFormSettingValue>[],
  requestType: UserRequestType,
  isVisibleHoshouninName: boolean
) => Path<IDomainFormSettingValue>[] = (
  pathList,
  requestType,
  isVisibleHoshouninName
) => {
  const reqType = requestType.toString().toLowerCase();
  const isParking = reqType.includes('parking');
  if (isParking) {
    // NOTE: 駐車場の場合、使用用途は項目設定に表示しないため、エポス送信時に適切な値をセットする。
    pathList = pathList.filter(path => path !== 'request.yotoDetail');
  }
  if (isVisibleHoshouninName && !isParking) {
    pathList = pathList.filter(
      path => path && !path.toString().includes('kinkyuRenrakusaki')
    );
  } else {
    pathList = pathList.filter(
      path => path && !path.toString().includes('hoshounin')
    );
  }
  return pathList;
};

export const updateDomainFormSettingForEpos: (
  domainUID: string,
  useReviewFormSetting: IDomainSetting['useReviewForm'],
  kojinRequiredPathList: Path<IDomainFormSettingValue>[],
  hojinRequiredPathList: Path<IDomainFormSettingValue>[]
) => Promise<void> = async (
  domainUID,
  useReviewFormSetting,
  kojinRequiredPathList,
  hojinRequiredPathList
) => {
  const domainFormSettingData = await fetchDomainFormSetting(domainUID);
  const documentRef = doc(
    db,
    domainFormSettingDocumentPath(domainUID)
  ) as DocumentReference<IDomainFormSetting>;
  const getPathListByRequestType = (reqType: UserRequestType) => {
    const key = reqType.toString().toLowerCase();
    if (key.includes('kojin')) {
      return kojinRequiredPathList;
    }
    if (key.includes('hojin')) {
      return hojinRequiredPathList;
    }
    return [];
  };
  for (const reqType of userRequestTypeKeys) {
    const pathList = getPathListByRequestType(reqType);
    if (!pathList.length) {
      continue;
    }
    const isVisibleHoshouninName = visibleConfigureValues.some(v =>
      get(domainFormSettingData, `${reqType}.hoshounin.name.${v}`)
    );
    const filteredPathList = eposRequiredFilter(
      pathList,
      reqType,
      isVisibleHoshouninName
    );
    await updateDomainFormSetting(
      documentRef,
      domainFormSettingData,
      reqType,
      filteredPathList,
      useReviewFormSetting
    );
  }
};
