import { IChohyoTemplate, IDocumentBase, IRequest } from '.';

export interface FaxContact {
  /** 宛先FAX番号  */
  toFaxNumber: string;
  /** 宛先名称 */
  toName: string;
  /** 役割 */
  role: '家賃保証会社' | '付帯取次会社' | '火災保険会社' | 'その他';
}

/** FAX送信リクエスト */
export interface SendFaxRequest {
  /** 宛先FAX番号  */
  toFaxNumber: string;
  /** 宛先名称 */
  toName: string;
  /** 役割 */
  role: string;
  /** 出力を指示した法人のUID */
  domainUID: string;
  /** 出力を指示した法人の名称 */
  domainName: string;
  /** 出力を指示したアカウント名 */
  creatorName: string;
  /** 帳票テンプレート */
  template: IChohyoTemplate;
  /** 入居申込のUID */
  requestUID: string;
  /** 入居申込内容 */
  requestData: Partial<IRequest>[];
  /** 結合する法人申込者確認書類のリスト */
  pdfs: { name: string; url: string }[];
  /** レスポンスに出力されたPDFを含めるか */
  isReturnPdf: boolean;
}

export const SEND_FAX_BYTE_SIZE_LIMIT = 8 * Math.pow(1024, 2);
export const SEND_FAX_PAGE_LIMIT = 10;

/** FAX送信リクエスト結果 */
export type SendFaxRequestResult = {
  result: boolean;
  pdf?: string;
};

/** fax送信結果(ステータス) */
export type FaxResultStatuses = '成功' | '失敗' | 'リトライ';

/** FAXと履歴のマッピング */
export type SendingFax = {
  // SE側の送信ID
  faxID: string;
  // interFax側の送信ID
  transactionID: string;
  domainUID: string;
  requestUID: string;
  logUID: string;
  // 削除する帳票ファイルのパス
  fileStoragePath?: string;
  retryCount?: number;
  toName: string;
  toFaxNumber: string;
  role: string;
} & IDocumentBase;

export const bizSupportFaxTo: FaxContact = {
  // TODO: 付帯取次会社の取引先が増加傾向にある場合、マスタ化を検討する
  toFaxNumber: '+81359566164',
  toName: 'ライフイン24',
  role: '付帯取次会社' as const
};

export const sakaiFaxTo: FaxContact = {
  toFaxNumber: '+81722386905',
  toName: 'サカイ引越センター',
  role: '付帯取次会社' as const
};

type LicenseFaxToList = { licenseName: string; faxTo: FaxContact }[];

export const licenseFaxToList: LicenseFaxToList = [
  {
    licenseName: 'bizSupportSakai',
    faxTo: sakaiFaxTo
  }
];
