import {
  collection,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  limit,
  limitToLast,
  onSnapshot,
  query,
  QueryConstraint,
  setDoc,
  startAfter,
  where
} from 'firebase/firestore';
import moment from 'moment';
import { DateString } from 'requestform-types/lib/DateTimeString';
import {
  domainToDoCollectionPath,
  domainToDoDocumentPath
} from 'requestform-types/lib/FirestorePath';
import { IDomainToDo } from 'requestform-types/lib/IToDo';
import { DEFAULT_ITEMS_PER_PAGE } from 'requestform-types/lib/IToDo';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import { FirestoreCollectionRefLike } from '@/store/FirebaseUniqueDocumentCollectionBase';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';
import { appLogger } from '@/utilities/appLogger';
import { getToDoNameList } from '@/utilities/firebaseFunctions';
import {
  SearchConditions,
  toSearchQuery
} from '@/utilities/search/toDoSearchConditionsConverter';

import { LicenseCollectionModule } from './LicenseCollectionModule';
import { SignInModule } from './SignInModule';

export const toDoPresets = [
  'myTask',
  'untilToday',
  'untilTommorow',
  'overdue',
  'uncompleted'
] as const;
export type ToDoPresets = typeof toDoPresets[number];

class ToDoCollectionState extends FirestoreCollectionState<IDomainToDo> {
  requestToDoData: IDomainToDo[] = [];
  requestToDoRef: FirestoreCollectionRefLike | null = null;
  requestToDoUnSubscribe: (() => void) | null = null;
  toDoCount: number | null = null;
  toDoPresetCount: { [key in ToDoPresets]: number } = {
    myTask: 0,
    untilToday: 0,
    untilTommorow: 0,
    overdue: 0,
    uncompleted: 0
  };

  toDoNameList: string[] = [];
  searchConditions: Partial<SearchConditions> = {};
  isToDoNameListLoaded: boolean = false;
}

export class ToDoCollectionGetters extends FirestoreCollectionGetters<
  IDomainToDo,
  ToDoCollectionState
> {
  signInCtx!: Context<typeof SignInModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  // NOTE: 申込詳細を開いたときのToDoデータ
  get requestToDoData() {
    return this.state.requestToDoData;
  }
  get toDoCount() {
    return this.state.toDoCount;
  }
  get toDoPresetCount() {
    return this.state.toDoPresetCount;
  }
  get toDoPresetItems(): {
    [key in ToDoPresets]: {
      text: string;
      searchConditions: Partial<SearchConditions>;
    };
  } {
    const format = 'YYYY-MM-DD';
    const today = moment().format(format) as DateString;
    const tommorow = moment().add(1, 'days').format(format) as DateString;
    const yesterDay = moment().subtract(1, 'days').format(format) as DateString;
    return {
      myTask: {
        text: 'マイタスク',
        searchConditions: {
          tantosha: [this.signInCtx.getters.idProviderUserUID],
          isCompleted: false,
          isActive: true
        }
      },
      untilToday: {
        text: '今日のタスク',
        searchConditions: {
          dueDateStart: today,
          dueDateEnd: today,
          isCompleted: false,
          isActive: true
        }
      },
      untilTommorow: {
        text: '明日のタスク',
        searchConditions: {
          dueDateStart: tommorow,
          dueDateEnd: tommorow,
          isCompleted: false,
          isActive: true
        }
      },
      overdue: {
        text: '期日超過',
        searchConditions: {
          dueDateStart: undefined,
          dueDateEnd: yesterDay,
          isCompleted: false,
          isActive: true
        }
      },
      uncompleted: {
        text: '未完了',
        searchConditions: {
          dueDateStart: undefined,
          dueDateEnd: undefined,
          isCompleted: false,
          isActive: true
        }
      }
    };
  }
  get toDoNameList() {
    return this.state.toDoNameList;
  }
  get searchConditions() {
    return this.state.searchConditions;
  }
  get isToDoNameListLoaded() {
    return this.state.isToDoNameListLoaded;
  }
}

class ToDoCollectionMutations extends FirestoreCollectionMutations<
  IDomainToDo,
  ToDoCollectionState
> {
  setSearchConditions(v: Partial<SearchConditions>) {
    this.state.searchConditions = v;
  }
  setToDoNameList(v: string[]) {
    this.state.toDoNameList = v;
  }
}

export class ToDoCollectionActions extends FirestoreCollectionActions<
  IDomainToDo,
  ToDoCollectionState,
  ToDoCollectionGetters,
  ToDoCollectionMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  licenseCollectionCtx!: Context<typeof LicenseCollectionModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
    this.licenseCollectionCtx = LicenseCollectionModule.context(store);
  }

  async update(data: Partial<IDomainToDo>) {
    const domainUID = this.signInCtx.getters.domainUID;
    if (!data.toDoUID) {
      throw new Error('toDoUIDが存在しません');
    }
    await setDoc(
      doc(db, domainToDoDocumentPath(domainUID, data.toDoUID)),
      data,
      {
        merge: true
      }
    );
  }

  requestToDoUnsetRef() {
    if (this.state.requestToDoUnSubscribe) {
      this.state.requestToDoUnSubscribe();
    }
    this.state.requestToDoData = [];
    this.state.requestToDoRef = null;
    this.state.requestToDoUnSubscribe = null;
  }

  setSearchConditions(v: Partial<SearchConditions>) {
    this.commit('setSearchConditions', v);
  }

  async setToDoNameList() {
    this.state.isToDoNameListLoaded = false;
    await getToDoNameList().then(v => {
      this.commit('setToDoNameList', v.data.toDoNameList);
      this.state.isToDoNameListLoaded = true;
    });
  }

  // NOTE: 申込詳細を開いたときのデータはrequestToDoDataに保持する
  setRef(ref: FirestoreCollectionRefLike): Promise<number> {
    // TODO: バインディング時間をここで計測する必要があるかは検討
    const startTime = performance.now();
    if (ref !== this.state.requestToDoRef) this.requestToDoUnsetRef();
    this.state.requestToDoRef = ref;

    return new Promise((resolve, reject) => {
      this.state.requestToDoUnSubscribe = onSnapshot(ref, {
        next: snap => {
          // NOTE: 各ドキュメントに参照型フィールドが入っていなことを前提としている
          this.state.requestToDoData = snap.docs.map(
            s => s.data() as IDomainToDo
          );
          const duration = Math.floor(performance.now() - startTime);
          resolve(duration);
        },
        error: error => {
          appLogger.error(`Failed onSnapshot ${ref.type}`, error);
          reject(error);
        }
      });
    });
  }

  async getToDoCount(searchQuery: QueryConstraint[]) {
    const domainUID = this.signInCtx.getters.domainUID;
    return await getCountFromServer(
      query(collection(db, domainToDoCollectionPath(domainUID)), ...searchQuery)
    ).then(v => v.data().count);
  }

  async setToDoPresetCount(
    key: ToDoPresets,
    searchConditions: Partial<SearchConditions>
  ) {
    this.state.toDoPresetCount[key] = await this.getToDoCount(
      toSearchQuery(searchConditions)
    );
  }

  setAllToDoPresetCount() {
    if (this.licenseCollectionCtx.getters.hasToDoKanriLicense) {
      toDoPresets.forEach(key =>
        this.setToDoPresetCount(
          key,
          this.getters.toDoPresetItems[key].searchConditions
        )
      );
    }
  }

  setToDoCollectionRef(requestUID: string) {
    const domainUID = this.signInCtx.getters.domainUID;
    const ref = query(
      collection(db, domainToDoCollectionPath(domainUID)),
      where('request.requestUID', '==', requestUID)
    );
    this.setRef(ref);
  }
  async setToDoWithChildrenCollectionRef(
    limitNum: number = DEFAULT_ITEMS_PER_PAGE,
    rel?: 'next' | 'prev'
  ) {
    this.state.isLoaded = false;
    const domainUID = this.signInCtx.getters.domainUID;
    const searchQuery = toSearchQuery(this.state.searchConditions);
    this.getToDoCount(searchQuery).then(v => (this.state.toDoCount = v));
    this.setAllToDoPresetCount();
    if (rel === 'next') {
      const lastToDoUID = this.state.data[this.state.data.length - 1].toDoUID;
      const docSnap = await getDoc(
        doc(collection(db, domainToDoCollectionPath(domainUID)), lastToDoUID)
      );
      searchQuery.push(startAfter(docSnap), limit(limitNum));
    }
    if (rel === 'prev') {
      const firstToDoUID = this.state.data[0].toDoUID;
      const docSnap = await getDoc(
        doc(collection(db, domainToDoCollectionPath(domainUID)), firstToDoUID)
      );
      searchQuery.push(endBefore(docSnap), limitToLast(limitNum));
    } else {
      searchQuery.push(limit(limitNum));
    }
    const ref = query(
      collection(db, domainToDoCollectionPath(domainUID)),
      ...searchQuery
    );
    this.unsetChildrenRef();
    this.setRefWithChildren(ref).then(() => (this.state.isLoaded = true));
  }
  setNextPageRef(limitNum: number = DEFAULT_ITEMS_PER_PAGE) {
    this.setToDoWithChildrenCollectionRef(limitNum, 'next');
  }
  setPrevPageRef(limitNum: number = DEFAULT_ITEMS_PER_PAGE) {
    this.setToDoWithChildrenCollectionRef(limitNum, 'prev');
  }
}

export const ToDoCollectionModule = new Module({
  state: ToDoCollectionState,
  getters: ToDoCollectionGetters,
  mutations: ToDoCollectionMutations,
  actions: ToDoCollectionActions
});
