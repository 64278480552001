import {
  signInWithCustomToken as doSignInWithCustomToken,
  signInWithEmailAndPassword,
  signOut as doSignOut,
  User
} from 'firebase/auth';
import { Store } from 'vuex';
import {
  Actions,
  Context,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module';

import { auth } from '@/firebase/firebase';
import { RequestDocumentModule } from '@/store/RequestDocumentModule';
import { appLogger } from '@/utilities/appLogger';

export class SignInState {
  user: User | null = null;
  domainUID: string = '';
}

export class SignInGetters extends Getters<SignInState> {
  get getUser() {
    return this.state.user;
  }
  get accountUID() {
    return this.state.user ? this.state.user.uid : '';
  }
  get domainUID() {
    return this.state.domainUID;
  }
  get isSignIn() {
    return !!this.state.user;
  }
}

export class SignInMutations extends Mutations<SignInState> {
  setUser(user: User | null) {
    this.state.user = user;
  }
  setDomainUID(uid: string) {
    this.state.domainUID = uid;
  }
}

export class SignInActions extends Actions<
  SignInState,
  SignInGetters,
  SignInMutations
> {
  requestDocumentModule?: Context<typeof RequestDocumentModule>;
  $init(store: Store<any>) {
    this.requestDocumentModule = RequestDocumentModule.context(store);
  }
  async updateCurrentUser() {
    const user = auth.currentUser;
    this.commit('setUser', user);

    if (this.state.user) {
      const domainUID = await this.state.user
        .getIdTokenResult()
        .then(r => r.claims?.domainUID as string | undefined)
        .catch(e => {
          throw new Error(`get domain failed. ${e}`);
        });
      this.commit('setDomainUID', domainUID || '');
    }
  }

  async legacySignIn(payload: { email: string; password: string }) {
    await signInWithEmailAndPassword(
      auth,
      payload.email,
      payload.password
    ).catch(err => {
      console.log('error: ', err);
      throw err;
    });
    console.log('legacySignIn');
  }

  async signInWithCustomToken(customToken: string) {
    await doSignInWithCustomToken(auth, customToken);
    console.log('signInWithCustomToken');
  }

  async signOut() {
    await this.requestDocumentModule?.actions.unsetRef();
    await doSignOut(auth).catch(err => {
      appLogger.error(err);
    });
    await this.updateCurrentUser();
  }
}

// TODO: vuex-smart-moduleを最新化して、createMapper() で返すようにする
export const SignInModule = new Module({
  state: SignInState,
  getters: SignInGetters,
  mutations: SignInMutations,
  actions: SignInActions
});
