import { IRelatedDomain } from 'requestform-types';
import { relatedDomainCollectionPath } from 'requestform-types/lib/FirestorePath';
import { Module } from 'vuex-smart-module';

import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';

class RelatedDomainCollectionState extends FirestoreCollectionState<IRelatedDomain> {}

export class RelatedDomainCollectionGetters extends FirestoreCollectionGetters<
  IRelatedDomain,
  RelatedDomainCollectionState
> {}

class RelatedDomainCollectionMutations extends FirestoreCollectionMutations<
  IRelatedDomain,
  RelatedDomainCollectionState
> {}

class RelatedDomainCollectionActions extends FirestoreCollectionActions<
  IRelatedDomain,
  RelatedDomainCollectionState,
  RelatedDomainCollectionGetters,
  RelatedDomainCollectionMutations
> {
  setRelatedCollectionRef(domainUID: string) {
    if (domainUID && !this.state.ref) {
      this.setCollectionRef(relatedDomainCollectionPath(domainUID));
    }
  }
}

export const RelatedDomainCollectionModule = new Module({
  state: RelatedDomainCollectionState,
  getters: RelatedDomainCollectionGetters,
  mutations: RelatedDomainCollectionMutations,
  actions: RelatedDomainCollectionActions
});
