import {
  collection,
  doc,
  DocumentReference,
  onSnapshot,
  Unsubscribe
} from 'firebase/firestore';
import { isDoc } from 'requestform-types/lib/TypeGuard';
import { Actions, Getters, Mutations } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import { appLogger } from '@/utilities/appLogger';

type Child<T> = { unsubscribe: Unsubscribe; data?: T };

type DocumentData = {} | null;
export class FirestoreDocumentState<T extends DocumentData> {
  data = {} as T;
  ref: DocumentReference | null = null;
  isLoaded: boolean = false;
  // 参照型フィールド用
  children: { [K in keyof T]?: Child<T[K]> } = {};
  unSubscribe: (() => void) | null = null;
}

export class FirestoreDocumentGetters<
  T extends DocumentData,
  S extends FirestoreDocumentState<T>
> extends Getters<S> {
  get getData() {
    return this.state.data;
  }

  get getDB() {
    return this.state.ref ? this.state.ref.firestore : db;
  }

  get getRef() {
    return this.state.ref;
  }

  get getIsLoaded() {
    return this.state.isLoaded;
  }
}

export class FirestoreDocumentMutations<
  T extends DocumentData,
  S extends FirestoreDocumentState<T>
> extends Mutations<S> {}

export class FirestoreDocumentActions<
  T,
  S extends FirestoreDocumentState<T>,
  G extends FirestoreDocumentGetters<T, S>,
  M extends FirestoreDocumentMutations<T, S>
> extends Actions<S, G, M, FirestoreDocumentActions<T, S, G, M>> {
  protected setChildRefs(data: T): (Promise<Partial<T>> | Partial<T>)[] {
    // NOTE: 参照型フィールドが1階層目にしかないことを前提に最適化している
    return (Object.entries(data) as [keyof T, T[keyof T]][]).reduce(
      (ret, [key, v]) => {
        if (isDoc(v)) {
          if (this.state.children[key]?.data) {
            ret.push({ [key]: this.state.children[key]?.data } as Partial<T>);
            return ret;
          }
          ret.push(
            new Promise<Partial<T>>((resolve, reject) => {
              this.state.children[key] = {
                unsubscribe: onSnapshot(v, {
                  next: snap => {
                    const childData = snap.data() as T[typeof key];
                    // NOTE: この時点では this.state.children[key] が必ず存在しているため as で解決
                    (this.state.children[key] as Child<
                      T[typeof key]
                    >).data = childData;
                    this.state.data[key] = childData;
                    resolve({ [key]: childData } as Partial<T>);
                  },
                  error: error => {
                    reject(new Error(`Failed onSnapshot ${v.path}: ${error}`));
                  }
                })
              };
            })
          );
          return ret;
          // TODO: 参照型フィールドが削除された場合にバインディングを解除するようにする
        }
        return ret;
      },
      [] as (Promise<Partial<T>> | Partial<T>)[]
    );
  }
  setRef(ref: DocumentReference) {
    this.state.isLoaded = false;
    if (ref !== this.state.ref) this.unsetRef();
    this.state.ref = ref;

    return new Promise((resolve, reject) => {
      this.state.unSubscribe = onSnapshot(ref, {
        next: doc => {
          const data = doc.data() as T | undefined;
          if (!data) return resolve(true);
          Promise.all(this.setChildRefs(data))
            .then(children => {
              const childrenData = children.reduce((ret, child) => {
                return { ...ret, ...child };
              }, {});
              this.state.data = {
                ...data,
                ...childrenData
              };
              this.state.isLoaded = true;
              resolve(true);
            })
            .catch(error => {
              appLogger.error(error, { id: ref.id });
              reject(error);
            });
        },
        error: error => {
          appLogger.debug(`Failed onSnapshot ${ref.path}`, {
            error,
            path: ref.path
          });
          reject(error);
        }
      });
    });
  }
  unsetRef() {
    if (this.state.unSubscribe) {
      this.state.unSubscribe();
      (Object.values(this.state.children) as Child<unknown>[]).forEach(
        ({ unsubscribe }) => typeof unsubscribe === 'function' && unsubscribe()
      );
    }
    this.state.data = {} as T;
    this.state.ref = null;
    this.state.isLoaded = false;
    this.state.unSubscribe = null;
    this.state.children = {};
  }

  setDocumentRef(documentPath: string) {
    const dRef = doc(db, documentPath);
    return this.setRef(dRef);
  }

  createId() {
    return doc(collection(db, '_')).id;
  }
}
