<template>
  <v-app>
    <v-overlay
      :z-index="999"
      :value="$loading.state || !getIsAuthResolved"
      :absolute="$loading.absolute"
    >
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <latest-version-snack-bar :show="showLatestVersionSnackBar" />
    <div class="information-dialog" style="z-index: 998"></div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <v-dialog
      :value="getIsOpenHojinSelectDialog"
      width="800"
      @input="setIsOpenHojinSelectDialog"
    >
      <hojin-select-dialog v-if="getIsOpenHojinSelectDialog" />
    </v-dialog>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";

import LatestVersionSnackBar from "@/requestform/components/LatestVersionSnackBar.vue";
import HojinSelectDialog from "@/requestform/components/selectDomain/HojinSelectDialog.vue";
import { AppLocalModule } from "@/requestform/store/AppLocalModule";
import { SignInModule } from "@/requestform/store/SignInModule";
import {
  isMajorOrMinorVersionChanged,
  VersionDocumentModule
} from "@/store/VersionDocumentModule";

const Super = Vue.extend({
  computed: {
    ...AppLocalModule.mapGetters(["getIsOpenHojinSelectDialog"]),
    ...SignInModule.mapGetters(["getIsAuthResolved"]),
    ...VersionDocumentModule.mapGetters(["latestVersion"])
  },
  methods: {
    ...AppLocalModule.mapActions(["setIsOpenHojinSelectDialog"])
  }
});

@Component({
  components: {
    LatestVersionSnackBar,
    HojinSelectDialog
  }
})
export default class App extends Super {
  showLatestVersionSnackBar = false;

  mounted() {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("edg") !== -1 && ua.indexOf("edge") === -1) {
      this.$gtag.pageview({ page_path: "/edge-chromium.html" });
    }
  }

  @Watch("latestVersion")
  onUpdateLatestVersion(latestVersion: string) {
    if (!latestVersion) {
      return;
    }
    const currentVersion = import.meta.env.APPLICATION_VERSION;
    if (!currentVersion) {
      return;
    }
    const isVersionChanged = isMajorOrMinorVersionChanged(
      currentVersion,
      latestVersion
    );
    this.showLatestVersionSnackBar =
      isVersionChanged && import.meta.env.NODE_ENV !== "development";
  }
}
</script>

<style>
.theme--light.v-application {
  background: #fff;
}
.subheader {
  background-color: rgba(17, 51, 100, 0.65);
  color: #fff;
  font-weight: bold;
  border-left: 8px solid #07c0d3;
  font-size: large;
}
.prologue {
  padding: 8px 24px;
  border: 1px solid #eee;
  border-left: 4px solid #07c0d3;
}
.prologue .subheader {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-left: none;
}
.anchor-link {
  text-decoration: underline;
  cursor: pointer;
}
.anchor-link-active {
  border-bottom: 2px solid #2196f3;
  padding-bottom: 4px;
}
.header-column {
  background-color: rgba(17, 51, 100, 0.05);
  border-right: 2px solid #07c0d3;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
}
.display-column {
  color: rgba(0, 0, 0, 1);
  font-size: 1rem;
  margin-left: 40px;
}

.subttl-mb {
  background-color: rgba(17, 51, 100, 0.65);
  color: #fff;
  font-weight: bold;
  border-left: 8px solid #07c0d3;
  border-radius: 0 !important;
}
.subttl-mb .mdi:before {
  color: #fff;
}
.pnl-mb {
  border-radius: 0 !important;
}
.v-subheader {
  padding-left: 0;
}
.signin-or-up .catch-copy {
  font-size: 1.4vw;
}
.signin-or-up .btn-options {
  padding: 0 !important;
}
.v-snack {
  z-index: 1001 !important;
}
@media screen and (max-width: 960px) {
  .signin-or-up .catch-copy {
    margin-top: -36px;
    font-size: 3.4vw;
  }
  .signin-or-up .btn-options {
    font-size: 0.78rem;
    height: 24px !important;
  }
}
</style>
