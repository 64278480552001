import { UnionToIntersection } from '../PathQuery';

export type ValueOf<T extends object> = T[keyof T];

type LastOf<T> = UnionToIntersection<
  T extends unknown ? () => T : never
> extends () => infer R
  ? R
  : never;
type Push<T extends unknown[], V> = [...T, V];

export type UnionToTuple<
  T,
  L = LastOf<T>,
  N = [T] extends [never] ? true : false
> = true extends N ? [] : Push<UnionToTuple<Exclude<T, L>>, L>;

export type ObjectValuesToTuple<
  T,
  KS extends unknown[] = UnionToTuple<keyof T>,
  R extends unknown[] = []
> = KS extends [infer K, ...infer KT]
  ? ObjectValuesToTuple<T, KT, [...R, T[K & keyof T]]>
  : R;

// 型に制約をかけつつ定数を定義するためのラッパー
export const createConstDef = <T>() => <V extends Readonly<T>>(v: V): V => v;

export type CheckKeys<T, KeyUnion extends keyof T> = Exclude<
  keyof T,
  KeyUnion
> extends never
  ? T
  : never;
