import { initializeApp } from 'firebase/app';
import {
  connectAuthEmulator,
  getAuth,
  signInWithCustomToken,
  signOut as _signOut,
  UserCredential
} from 'firebase/auth';
import {
  connectFirestoreEmulator,
  initializeFirestore
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const app = initializeApp({
  apiKey: import.meta.env.VITE_API_KEY,
  authDomain: import.meta.env.VITE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_DATABASE_URL,
  projectId: import.meta.env.VITE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_MESSAGEING_SENDER_ID,
  appId: import.meta.env.VITE_APP_ID,
  measurementId: import.meta.env.VITE_MEASUREMENT_ID
});

export const perf = getPerformance(app);

export const auth = getAuth(app);

export const db = initializeFirestore(app, {
  experimentalAutoDetectLongPolling: true
});

export const functions = getFunctions(app);
export const functionsAsiaNortheast1 = getFunctions(app, 'asia-northeast1');

export const storage = getStorage(app);

if (import.meta.env.VITE_CONNECT_EMULATOR_STORE === 'true') {
  connectFirestoreEmulator(db, 'localhost', 9000);
}
if (import.meta.env.VITE_CONNECT_EMULATOR_FUNCTIONS === 'true') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFunctionsEmulator(functionsAsiaNortheast1, 'localhost', 5001);
}
if (import.meta.env.VITE_CONNECT_EMULATOR_AUTH === 'true') {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
}
// NOTE: devのバックアップデータを閲覧するために、デフォルトではfalseにしている
if (import.meta.env.VITE_CONNECT_EMULATOR_STORAGE === 'true') {
  connectStorageEmulator(storage, 'localhost', 9199);
}

export const signInWithCustomTokenSilently = async (
  token: string
): Promise<UserCredential> => {
  return await signInWithCustomToken(auth, token);
};

export const signOut = async () => {
  await _signOut(auth);
};

export default app;
