import { doc, getDoc } from 'firebase/firestore';
import { monthlyCountDocumentPath } from 'requestform-types/lib/FirestorePath';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { auth, db } from '@/firebase/firebase';
import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';

// 他の場所からも参照するならtypesに移動を検討する
export interface IMonthlyCount {
  bizSupportCount: number;
}

class MonthlyCountDocumentState extends FirestoreDocumentState<IMonthlyCount> {}

export class MonthlyCountDocumentGetters extends FirestoreDocumentGetters<
  IMonthlyCount,
  MonthlyCountDocumentState
> {}

class MonthlyCountDocumentMutations extends FirestoreDocumentMutations<
  IMonthlyCount,
  MonthlyCountDocumentState
> {}

class MonthlyCountDocumentActions extends FirestoreDocumentActions<
  IMonthlyCount,
  MonthlyCountDocumentState,
  MonthlyCountDocumentGetters,
  MonthlyCountDocumentMutations
> {
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  async getCount(yyyymm: string): Promise<IMonthlyCount | undefined> {
    const domainUID = this.signInCtx.getters.domainUID;
    const user = auth.currentUser;
    if (domainUID && user) {
      const monthlyCountDocuemntSnap = await getDoc(
        doc(db, monthlyCountDocumentPath(domainUID, yyyymm))
      );
      if (monthlyCountDocuemntSnap.exists()) {
        return monthlyCountDocuemntSnap.data() as IMonthlyCount;
      }
      return undefined;
    }
  }
}

export const MonthlyCountDocumentModule = new Module({
  state: MonthlyCountDocumentState,
  getters: MonthlyCountDocumentGetters,
  mutations: MonthlyCountDocumentMutations,
  actions: MonthlyCountDocumentActions
});
