import { Store } from 'vuex';
import {
  Actions,
  Context,
  Getters,
  Module,
  Mutations
} from 'vuex-smart-module';

import { VFormObj } from '@/plugins/vuetify';
import { RequestDocumentModule } from '@/store/RequestDocumentModule';

export class AppLocalState {
  isAccessAllowed: boolean = false;
  // DomainSettingModuleに移設するか検討 ====================
  isTrackUserActivity: boolean | null = null;
  isAllowedShopVisibleRestriction: boolean | null = null;
  isAllowedHousemateChukaiSys: boolean | null = null;
  kanriKaishaSystemName: string = '';
  // ======================================================
  drawer: boolean = true;
  naikenYoyakuDrawer: boolean = true;
  bizSupportDrawer: boolean = true;
  isOpenCreateRequestInStandAloneWizard: boolean = false;
  isOpenSelectMoshikomiTypeDialog: boolean = false;
  isOpenEditDialog: boolean = false;
  isOpenStatusChangeDialog: boolean = false;
  isOpenConfirmDialog: boolean = false;
  isOpenPrintRequestDataDialog: boolean = false;
  isOpenInternalMemoInputDialog: boolean = false;
  isOpenBoshuDomeDialog: boolean = false;
  isOpenLinkageOneDialog: boolean = false;
  isOpenMoshikomiDialog: boolean = false;
  isOpenMoshikomiCancelDialog: boolean = false;
  isOpenRequestEditerAlertDialog: boolean = false;
  isOpenDeleteCommentAlertDialog: boolean = false;
  isOpenFutaiToritsugiDetailDialog: boolean = false;
  isOpenFutaiToritsugiConfirmDialog: boolean = false;
  isEditModeRequest: boolean = false;
  isValid: boolean = false;
  requestForm: VFormObj | null = null;
  formSettingForm: VFormObj | null = null;
  isOpenNaikenYoyakuDetailDialog: boolean = false;
  currentDisplayedContentId: string = '';
  isOpenRequestThanksDialog: boolean = false;
  isOpenOneKokyakuLinkedDialog: boolean = false;
  toScrollId: string = '';
  isOpenSignInHelpDialog: boolean = false;
  isOpenWelcomeDialog: boolean = false;
  isOpenRequestWizardDialog: boolean = false;
  requestWizardStatus: number = 1;
  isOpenChangeBanteDialog: boolean = false;
  isOpenHojinSelectDialog: boolean = false;
}

export class AppLocalGetters extends Getters<AppLocalState> {
  get getDrawer() {
    return this.state.drawer;
  }
  get getNaikenYoyakuDrawer() {
    return this.state.naikenYoyakuDrawer;
  }
  get getBizSupportDrawer() {
    return this.state.bizSupportDrawer;
  }
  get getIsOpenCreateRequestInStandAloneWizard() {
    return this.state.isOpenCreateRequestInStandAloneWizard;
  }
  get getIsOpenSelectMoshikomiTypeDialog() {
    return this.state.isOpenSelectMoshikomiTypeDialog;
  }
  get getIsTrackUserActivity() {
    return this.state.isTrackUserActivity;
  }
  get getIsAllowedShopVisibleRestriction() {
    return this.state.isAllowedShopVisibleRestriction;
  }
  get getIsAllowedHousemateChukaiSys() {
    return this.state.isAllowedHousemateChukaiSys;
  }
  get getKanriKaishaSystemName() {
    return this.state.kanriKaishaSystemName;
  }
  get getIsAccessAllowed() {
    return this.state.isAccessAllowed;
  }
  get getIsOpenEditDialog() {
    return this.state.isOpenEditDialog;
  }
  get getIsOpenStatusChangeDialog() {
    return this.state.isOpenStatusChangeDialog;
  }
  get getIsOpenConfirmDialog() {
    return this.state.isOpenConfirmDialog;
  }
  get getIsOpenPrintRequestDataDialog() {
    return this.state.isOpenPrintRequestDataDialog;
  }
  get getIsOpenInternalMemoInputDialog() {
    return this.state.isOpenInternalMemoInputDialog;
  }
  get getIsOpenBoshuDomeDialog() {
    return this.state.isOpenBoshuDomeDialog;
  }
  get getIsOpenLinkageOneDialog() {
    return this.state.isOpenLinkageOneDialog;
  }
  get getIsOpenMoshikomiDialog() {
    return this.state.isOpenMoshikomiDialog;
  }
  get getIsOpenMoshikomiCancelDialog() {
    return this.state.isOpenMoshikomiCancelDialog;
  }
  get getIsOpenRequestEditerAlertDialog() {
    return this.state.isOpenRequestEditerAlertDialog;
  }
  get getIsOpenDeleteCommentAlertDialog() {
    return this.state.isOpenDeleteCommentAlertDialog;
  }
  get getIsOpenFutaiToritsugiDetailDialog() {
    return this.state.isOpenFutaiToritsugiDetailDialog;
  }
  get getIsOpenFutaiToritsugiConfirmDialog() {
    return this.state.isOpenFutaiToritsugiConfirmDialog;
  }
  get getIsEditModeRequest() {
    return this.state.isEditModeRequest;
  }
  get getIsValid() {
    return this.state.isValid;
  }
  get getIsOpenNaikenYoyakuDetailDialog() {
    return this.state.isOpenNaikenYoyakuDetailDialog;
  }
  get getCurrentDisplayedContentId() {
    return this.state.currentDisplayedContentId;
  }
  get getIsOpenRequestThanksDialog() {
    return this.state.isOpenRequestThanksDialog;
  }
  get getIsOpenOneKokyakuLinkedDialog() {
    return this.state.isOpenOneKokyakuLinkedDialog;
  }
  get getToScrollId() {
    return this.state.toScrollId;
  }
  get getIsOpenSignInHelpDialog() {
    return this.state.isOpenSignInHelpDialog;
  }
  get getIsOpenWelcomeDialog() {
    return this.state.isOpenWelcomeDialog;
  }
  get getIsOpenRequestWizardDialog() {
    return this.state.isOpenRequestWizardDialog;
  }
  get getRequestWizardStatus() {
    return this.state.requestWizardStatus;
  }
  get getIsOpenChangeBanteDialog() {
    return this.state.isOpenChangeBanteDialog;
  }
  get getIsOpenHojinSelectDialog() {
    return this.state.isOpenHojinSelectDialog;
  }
}

export class AppLocalMutations extends Mutations<AppLocalState> {
  setIsAccessAllowed(val: boolean) {
    this.state.isAccessAllowed = val;
  }
  setIsTrackUserActivity(val: boolean) {
    this.state.isTrackUserActivity = val;
  }
  setIsAllowedShopVisibleRestriction(val: boolean) {
    this.state.isAllowedShopVisibleRestriction = val;
  }
  setIsAllowedHousemateChukaiSys(val: boolean) {
    this.state.isAllowedHousemateChukaiSys = val;
  }
  setKanriKaishaSystemName(name: string) {
    this.state.kanriKaishaSystemName = name;
  }
  setDrawer(drawerStatus: boolean) {
    this.state.drawer = drawerStatus;
  }
  toggleDrawer() {
    this.state.drawer = !this.state.drawer;
  }
  setNaikenYoyakuDrawer(drawerStatus: boolean) {
    this.state.naikenYoyakuDrawer = drawerStatus;
  }
  toggleNaikenYoyakuDrawer() {
    this.state.naikenYoyakuDrawer = !this.state.naikenYoyakuDrawer;
  }
  setBizSupportDrawer(drawerStatus: boolean) {
    this.state.bizSupportDrawer = drawerStatus;
  }
  toggleBizSupportDrawer() {
    this.state.bizSupportDrawer = !this.state.bizSupportDrawer;
  }
  setIsOpenCreateRequestInStandAloneWizard(dialogStatus: boolean) {
    this.state.isOpenCreateRequestInStandAloneWizard = dialogStatus;
  }
  setIsOpenSelectMoshikomiTypeDialog(dialogStatus: boolean) {
    this.state.isOpenSelectMoshikomiTypeDialog = dialogStatus;
  }
  setIsOpenEditDialog(dialogStatus: boolean) {
    this.state.isOpenEditDialog = dialogStatus;
  }
  setIsOpenStatusChangeDialog(dialogStatus: boolean) {
    this.state.isOpenStatusChangeDialog = dialogStatus;
  }
  setIsOpenConfirmDialog(dialogStatus: boolean) {
    this.state.isOpenConfirmDialog = dialogStatus;
  }
  setIsOpenPrintRequestDataDialog(dialogStatus: boolean) {
    this.state.isOpenPrintRequestDataDialog = dialogStatus;
  }
  setIsOpenInternalMemoInputDialog(dialogStatus: boolean) {
    this.state.isOpenInternalMemoInputDialog = dialogStatus;
  }
  setIsOpenBoshuDomeDialog(dialogStatus: boolean) {
    this.state.isOpenBoshuDomeDialog = dialogStatus;
  }
  setIsOpenLinkageOneDialog(dialogStatus: boolean) {
    this.state.isOpenLinkageOneDialog = dialogStatus;
  }
  setIsOpenMoshikomiDialog(dialogStatus: boolean) {
    this.state.isOpenMoshikomiDialog = dialogStatus;
  }
  setIsOpenMoshikomiCancelDialog(dialogStatus: boolean) {
    this.state.isOpenMoshikomiCancelDialog = dialogStatus;
  }
  setIsOpenRequestEditerAlertDialog(dialogStatus: boolean) {
    this.state.isOpenRequestEditerAlertDialog = dialogStatus;
  }
  setIsOpenDeleteCommentAlertDialog(dialogStatus: boolean) {
    this.state.isOpenDeleteCommentAlertDialog = dialogStatus;
  }
  setIsOpenFutaiToritsugiDetailDialog(dialogStatus: boolean) {
    this.state.isOpenFutaiToritsugiDetailDialog = dialogStatus;
  }
  setIsOpenFutaiToritsugiConfirmDialog(dialogStatus: boolean) {
    this.state.isOpenFutaiToritsugiConfirmDialog = dialogStatus;
  }
  setIsEditModeRequest(dialogStatus: boolean) {
    this.state.isEditModeRequest = dialogStatus;
  }
  setIsValid(isValid: boolean) {
    this.state.isValid = isValid;
  }
  setRequestForm(ref: VFormObj) {
    this.state.requestForm = ref;
  }
  setFormSettingForm(ref: VFormObj) {
    this.state.formSettingForm = ref;
  }
  setIsOpenNaikenYoyakuDetailDialog(dialogStatus: boolean) {
    this.state.isOpenNaikenYoyakuDetailDialog = dialogStatus;
  }
  setCurrentDisplayedContentId(id: string) {
    this.state.currentDisplayedContentId = id;
  }
  setIsOpenRequestThanksDialog(dialogStatus: boolean) {
    this.state.isOpenRequestThanksDialog = dialogStatus;
  }
  setIsOpenOneKokyakuLinkedDialog(dialogStatus: boolean) {
    this.state.isOpenOneKokyakuLinkedDialog = dialogStatus;
  }
  setToScrollId(toScrollId: string) {
    this.state.toScrollId = toScrollId;
  }
  setIsOpenSignInHelpDialog(dialogStatus: boolean) {
    this.state.isOpenSignInHelpDialog = dialogStatus;
  }
  setIsOpenWelcomeDialog(dialogStatus: boolean) {
    this.state.isOpenWelcomeDialog = dialogStatus;
  }
  setIsOpenRequestWizardDialog(dialogStatus: boolean) {
    this.state.isOpenRequestWizardDialog = dialogStatus;
  }
  setRequestWizardStatus(status: number) {
    this.state.requestWizardStatus = status;
  }
  setIsOpenChangeBanteDialog(dialogStatus: boolean) {
    this.state.isOpenChangeBanteDialog = dialogStatus;
  }
  setIsOpenHojinSelectDialog(dialogStatus: boolean) {
    this.state.isOpenHojinSelectDialog = dialogStatus;
  }
}

export class AppLocalActions extends Actions<
  AppLocalState,
  AppLocalGetters,
  AppLocalMutations,
  AppLocalActions
> {
  store!: Store<any>;
  requestDocumentModule!: Context<typeof RequestDocumentModule>;

  $init(store: Store<any>): void {
    this.store = store;
    this.requestDocumentModule = RequestDocumentModule.context(store);
  }
  setIsAccessAllowed(val: boolean) {
    this.commit('setIsAccessAllowed', val);
  }
  setIsTrackUserActivity(val: boolean) {
    this.commit('setIsTrackUserActivity', val);
  }
  setIsAllowedShopVisibleRestriction(val: boolean) {
    this.commit('setIsAllowedShopVisibleRestriction', val);
  }
  setIsAllowedHousemateChukaiSys(val: boolean) {
    this.commit('setIsAllowedHousemateChukaiSys', val);
  }
  setKanriKaishaSystemName(name: string) {
    this.commit('setKanriKaishaSystemName', name);
  }
  setDrawer(drawerStatus: boolean) {
    this.commit('setDrawer', drawerStatus);
  }
  toggleDrawer() {
    this.commit('toggleDrawer', null);
  }
  setNaikenYoyakuDrawer(drawerStatus: boolean) {
    this.commit('setNaikenYoyakuDrawer', drawerStatus);
  }
  toggleNaikenYoyakuDrawer() {
    this.commit('toggleNaikenYoyakuDrawer', null);
  }
  setBizSupportDrawer(drawerStatus: boolean) {
    this.commit('setBizSupportDrawer', drawerStatus);
  }
  toggleBizSupportDrawer() {
    this.commit('toggleBizSupportDrawer', null);
  }
  setIsOpenCreateRequestInStandAloneWizard(dialogStatus: boolean) {
    this.commit('setIsOpenCreateRequestInStandAloneWizard', dialogStatus);
  }
  setIsOpenSelectMoshikomiTypeDialog(dialogStatus: boolean) {
    this.commit('setIsOpenSelectMoshikomiTypeDialog', dialogStatus);
  }
  setIsOpenEditDialog(dialogStatus: boolean) {
    if (!dialogStatus) {
      this.requestDocumentModule.dispatch({ type: 'unsetRef' });
    }
    this.commit('setIsOpenEditDialog', dialogStatus);
  }
  setIsOpenStatusChangeDialog(dialogStatus: boolean) {
    this.commit('setIsOpenStatusChangeDialog', dialogStatus);
  }
  setIsOpenConfirmDialog(dialogStatus: boolean) {
    this.commit('setIsOpenConfirmDialog', dialogStatus);
  }
  setIsOpenPrintRequestDataDialog(dialogStatus: boolean) {
    this.commit('setIsOpenPrintRequestDataDialog', dialogStatus);
  }
  setIsOpenInternalMemoInputDialog(dialogStatus: boolean) {
    this.commit('setIsOpenInternalMemoInputDialog', dialogStatus);
  }
  setIsOpenBoshuDomeDialog(dialogStatus: boolean) {
    this.commit('setIsOpenBoshuDomeDialog', dialogStatus);
  }
  setIsOpenLinkageOneDialog(dialogStatus: boolean) {
    this.commit('setIsOpenLinkageOneDialog', dialogStatus);
  }
  setIsOpenMoshikomiDialog(dialogStatus: boolean) {
    this.commit('setIsOpenMoshikomiDialog', dialogStatus);
  }
  setIsOpenMoshikomiCancelDialog(dialogStatus: boolean) {
    this.commit('setIsOpenMoshikomiCancelDialog', dialogStatus);
  }
  setIsOpenRequestEditerAlertDialog(dialogStatus: boolean) {
    this.commit('setIsOpenRequestEditerAlertDialog', dialogStatus);
  }
  setIsOpenDeleteCommentAlertDialog(dialogStatus: boolean) {
    this.commit('setIsOpenDeleteCommentAlertDialog', dialogStatus);
  }
  setIsOpenFutaiToritsugiDetailDialog(dialogStatus: boolean) {
    this.commit('setIsOpenFutaiToritsugiDetailDialog', dialogStatus);
  }
  setIsOpenFutaiToritsugiConfirmDialog(dialogStatus: boolean) {
    this.commit('setIsOpenFutaiToritsugiConfirmDialog', dialogStatus);
  }
  setIsEditModeRequest(dialogStatus: boolean) {
    this.commit('setIsEditModeRequest', dialogStatus);
  }
  setIsValid(isValid: boolean) {
    this.commit('setIsValid', isValid);
  }
  setRequestForm(ref: VFormObj) {
    this.commit('setRequestForm', ref);
  }
  requestFormValidate() {
    const requestForm = this.state.requestForm;
    if (!requestForm) return false;
    return this.state.requestForm?.validate();
  }
  setFormSettingForm(ref: VFormObj) {
    this.commit('setFormSettingForm', ref);
  }
  formSettingFormValidate() {
    const formSettingForm = this.state.formSettingForm;
    if (!formSettingForm) return false;
    return this.state.formSettingForm?.validate();
  }
  setIsOpenNaikenYoyakuDetailDialog(dialogStatus: boolean) {
    this.commit('setIsOpenNaikenYoyakuDetailDialog', dialogStatus);
  }
  setCurrentDisplayedContentId(id: string) {
    this.commit('setCurrentDisplayedContentId', id);
  }
  setIsOpenRequestThanksDialog(dialogStatus: boolean) {
    this.commit('setIsOpenRequestThanksDialog', dialogStatus);
  }
  setIsOpenOneKokyakuLinkedDialog(dialogStatus: boolean) {
    this.commit('setIsOpenOneKokyakuLinkedDialog', dialogStatus);
  }
  setToScrollId(toScrollId: string) {
    this.commit('setToScrollId', toScrollId);
  }
  setIsOpenSignInHelpDialog(dialogStatus: boolean) {
    this.commit('setIsOpenSignInHelpDialog', dialogStatus);
  }
  setIsOpenWelcomeDialog(dialogStatus: boolean) {
    this.commit('setIsOpenWelcomeDialog', dialogStatus);
  }
  setIsOpenRequestWizardDialog(dialogStatus: boolean) {
    this.commit('setIsOpenRequestWizardDialog', dialogStatus);
  }
  resetRequestWizardStatus() {
    this.commit('setRequestWizardStatus', 1);
  }
  nextRequestWizardStatus() {
    this.commit('setRequestWizardStatus', this.state.requestWizardStatus + 1);
  }
  prevRequestWizardStatus() {
    this.commit('setRequestWizardStatus', this.state.requestWizardStatus - 1);
  }
  setIsOpenChangeBanteDialog(dialogStatus: boolean) {
    this.commit('setIsOpenChangeBanteDialog', dialogStatus);
  }
  setIsOpenHojinSelectDialog(dialogStatus: boolean) {
    this.commit('setIsOpenHojinSelectDialog', dialogStatus);
  }
}

export const AppLocalModule = new Module({
  state: AppLocalState,
  getters: AppLocalGetters,
  mutations: AppLocalMutations,
  actions: AppLocalActions
});
