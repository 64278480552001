import { Timestamp } from 'firebase/firestore';
import moment, { Moment } from 'moment-timezone';
import { INaikenYoyakuDatetime } from 'requestform-types';
import { naikenTimeRange } from 'requestform-types/lib/naikenYoyaku/INaikenYoyaku';
import { NaikenYoyakuDatetimePropType } from 'requestform-types/lib/naikenYoyaku/INaikenYoyakuDatetime';
import {
  defaultEndTime,
  defaultStartTime,
  INaikenYoyakuDomainSetting,
  maxSelectableTimeRange,
  naikenTimeSpanMinutes
} from 'requestform-types/lib/naikenYoyaku/INaikenYoyakuDomainSetting';
import { isNumber } from 'requestform-types/lib/TypeGuard';

export const getForbidTimes: (
  targetDate: Timestamp | null,
  domainSetting: Partial<INaikenYoyakuDomainSetting>
) => NaikenYoyakuDatetimePropType[] = (targetDate, setting) => {
  const {
    enableForbidDateTime,
    forbidStartDate,
    forbidStartTime,
    forbidEndDate,
    forbidEndTime,
    naikenStartTime: startTime = defaultStartTime,
    naikenEndTime: endTime = defaultEndTime
  } = setting;
  if (
    !targetDate ||
    !enableForbidDateTime ||
    !forbidStartDate ||
    !isNumber(forbidStartTime) ||
    !forbidEndDate ||
    !isNumber(forbidEndTime)
  ) {
    return [];
  }
  const result: NaikenYoyakuDatetimePropType[] = [];
  const startDateMoment = moment(forbidStartDate.toDate());
  const endDateMoment = moment(forbidEndDate.toDate());
  const targetDateMoment = moment(targetDate.toDate());
  const targetAddOneDay = targetDateMoment.clone().add(1, 'day');
  for (
    const target = targetDateMoment.clone();
    target.isSameOrBefore(targetAddOneDay);
    target.add(30, 'minute')
  ) {
    const timeProp = target.format('HHmm') as NaikenYoyakuDatetimePropType;
    // NOTE: ループの最終実行時はtargetが翌日0時となるため、その場合のみ24時という扱いにしている
    const time = target.isSame(targetAddOneDay) ? 2400 : Number(timeProp);
    // NOTE: 一時停止開始日=終了日=希望日なら一時停止開始時間〜一時停止終了時間
    if (
      startDateMoment.isSame(endDateMoment) &&
      startDateMoment.isSame(targetDateMoment)
    ) {
      if (time >= forbidStartTime && time <= forbidEndTime) {
        result.push(timeProp);
      }
      continue;
    }
    // NOTE: 一時停止期間初日なら一時停止開始時間〜内見可能な終了時間
    if (targetDateMoment.isSame(startDateMoment)) {
      if (time >= forbidStartTime && time <= endTime) {
        result.push(timeProp);
      }
      continue;
    }
    // NOTE: 一時停止期間最終日なら内見可能な開始時間〜一時停止終了時間
    if (targetDateMoment.isSame(endDateMoment)) {
      if (time >= startTime && time <= forbidEndTime) {
        result.push(timeProp);
      }
      continue;
    }
    // NOTE: 一時停止期間中なら内見可能な開始時間〜内見可能な終了時間
    if (
      targetDateMoment.isAfter(startDateMoment) &&
      targetDateMoment.isBefore(endDateMoment)
    ) {
      if (time >= startTime && time <= endTime) {
        result.push(timeProp);
        continue;
      }
    }
  }
  result.pop();
  return result;
};

/**
 * 内見予約可能時間帯のArrayを取得する
 * @param domainSetting 内見予約可能時間帯を保存している法人設定
 * @param startDate 内見日
 * @param naikenYoyakuDatetime 内見日の内見予約のObject
 */
export function calcNaikenYoyakuStartTimeRangeList(
  domainSetting: Partial<INaikenYoyakuDomainSetting>,
  startDate: Timestamp,
  naikenYoyakuDatetime: INaikenYoyakuDatetime,
  tatemonoReservedTimes: NaikenYoyakuDatetimePropType[]
): naikenTimeRange[] {
  if (!domainSetting) {
    return [];
  }
  const result: naikenTimeRange[] = [];
  const {
    naikenStartTime: startTime = defaultStartTime,
    naikenEndTime: endTime = defaultEndTime,
    isForbidDuplicates: isForbidDuplicates
  } = domainSetting;
  const nowMoment = moment();
  const sMoment = moment({
    day: 1,
    hour: startTime / 100,
    minute: startTime - ((startTime / 100) | 0) * 100
  });
  const eMoment = moment({
    day: 1,
    hour: endTime / 100,
    minute: endTime - ((endTime / 100) | 0) * 100
  });

  const isToday = nowMoment.isSame(moment(startDate.toDate()), 'day');
  // NOTE: 当日の内見予約時間を返すときは現在時刻以降の選択肢を返すようにする
  const minMoment = isToday
    ? moment({
        day: 1,
        hour: nowMoment.hour(),
        minutes: nowMoment.minutes()
      })
    : moment({
        day: 1,
        hour: 0,
        minutes: 0
      });
  const forbidTimes = getForbidTimes(startDate, domainSetting);

  while (sMoment < eMoment) {
    if (minMoment > sMoment && !isToday) {
      sMoment.add(naikenTimeSpanMinutes, 'm');
      continue;
    } else if (minMoment >= sMoment && isToday) {
      sMoment.add(naikenTimeSpanMinutes, 'm');
      continue;
    }
    let naikenDuplicate = false;
    let naikenForbid = false;
    const key = sMoment.format('HHmm') as NaikenYoyakuDatetimePropType;

    if (Object.keys(naikenYoyakuDatetime).length) {
      const naikenYoyakuUIDs = naikenYoyakuDatetime[key] || [];
      naikenDuplicate = naikenYoyakuUIDs.length > 0;
    }
    if (!naikenDuplicate && tatemonoReservedTimes?.length) {
      naikenDuplicate = tatemonoReservedTimes.includes(key);
    }
    if (forbidTimes.length) {
      naikenForbid = forbidTimes.includes(key);
    }

    result.push({
      text: getText(
        sMoment,
        naikenDuplicate,
        naikenForbid,
        !!isForbidDuplicates
      ),
      value: sMoment.format('HH:mm:00'),
      disabled: naikenForbid || (!!isForbidDuplicates && naikenDuplicate)
    });
    sMoment.add(naikenTimeSpanMinutes, 'm');
  }
  return result;
}

function getText(
  startMoment: moment.Moment,
  naikenDuplicate: boolean,
  naikenForbid: boolean,
  isForbidDuplicates: boolean
) {
  const endMoment = startMoment.clone();
  endMoment.add(naikenTimeSpanMinutes, 'm');

  const formatStr = 'HH:mm';
  const startTimeStr = startMoment.format(formatStr);
  const endTimeStr = endMoment.format('HH:mm');
  return `${startTimeStr} - ${endTimeStr} ${getMark(
    naikenDuplicate,
    naikenForbid,
    isForbidDuplicates
  )}`;
}

function getMark(
  naikenDuplicate: boolean,
  naikenForbid: boolean,
  isForbidDuplicates: boolean
) {
  if (naikenForbid || (isForbidDuplicates && naikenDuplicate)) {
    return 'Ｘ';
  }
  if (!isForbidDuplicates && naikenDuplicate) {
    return '△';
  }
  return '○';
}

/**
 * 最大選択可能範囲内であるか、および連続した時間帯であるかチェックする
 * @param items 選択した内見予約希望時間帯
 */
export function validateTimeRange(items: string[]): string {
  if (items.length > maxSelectableTimeRange) {
    items.pop();
    return '希望時間帯は最大3枠まで選択可能です';
  }

  const timeList = items.slice();
  const lastSelectTime = timeList[timeList.length - 1];
  timeList.pop();
  if (!timeList.length) return '';

  const baseDate = '2020-07-02';
  let allowMinTime: any = undefined;
  let allowMaxTime: any = undefined;
  const lastSelectTimeMoment = moment(`${baseDate} ${lastSelectTime}`);

  timeList.forEach(time => {
    const tmpMoment = moment(`${baseDate}T${time}`);
    const tmpMinMoment = tmpMoment.clone().add(-naikenTimeSpanMinutes, 'm');
    const tmpMaxMoment = tmpMoment.clone().add(naikenTimeSpanMinutes, 'm');
    if (tmpMoment.day() !== tmpMinMoment.day()) {
      allowMinTime = moment(`${baseDate}T00:00:00`);
    } else if (!allowMinTime || tmpMinMoment < allowMinTime) {
      allowMinTime = tmpMinMoment.clone();
    }
    if (tmpMoment.day() !== tmpMaxMoment.day()) {
      allowMaxTime = moment(`${baseDate}T23:59:59`);
    } else if (!allowMaxTime || allowMaxTime < tmpMaxMoment) {
      allowMaxTime = tmpMaxMoment.clone();
    }
  });
  if (
    lastSelectTimeMoment < allowMinTime ||
    allowMaxTime < lastSelectTimeMoment
  ) {
    items.pop();
    return '連続した希望時間帯を選択してください';
  }

  return '';
}

export function toStartDateTimeList(
  startDateTime: Moment,
  endDateTime: Moment
): Array<Moment> {
  const startDateTimeList: Array<Moment> = [];
  const tmpDateTime = startDateTime.clone();
  while (tmpDateTime < endDateTime) {
    startDateTimeList.push(tmpDateTime.clone());
    tmpDateTime.add(naikenTimeSpanMinutes, 'm');
  }
  return startDateTimeList;
}

export function toStartTimeList(
  startDateTime: Moment,
  endDateTime: Moment
): string[] {
  const startTimeList: string[] = [];
  const startDateTimeList = toStartDateTimeList(startDateTime, endDateTime);
  startDateTimeList.forEach(startDateTime => {
    startTimeList.push(startDateTime.format('HH:mm:ss'));
  });
  return startTimeList;
}
