import { functionsPayload } from 'requestform-types';
import { hasProperty } from 'requestform-types/lib/TypeGuard';

import { callAppLogger } from './firebaseFunctions';

type Severity = functionsPayload.AppLoggerPayload['severity'];
type Message = functionsPayload.AppLoggerPayload['message'];

const isError = (v: unknown): v is Error => hasProperty(v, 'message');

export const parseError = (
  v: string | Error
): { message: string; stack?: string } =>
  isError(v) ? { message: v.message, stack: v.stack } : { message: v };

const getLogger = (severity: Severity) => (
  content: Message | Error,
  args?: { [key: string]: any }
) => {
  const occurredTime = new Date().toString();
  // ローカル・dev環境ではコンソールに、それ以外の環境ではGCPロギングに出力する
  if (import.meta.env.NODE_ENV !== 'production') {
    console[severity](content, args, 'from appLogger');
  } else {
    const parsed = parseError(content);
    return callAppLogger({ severity, occurredTime, args, ...parsed }).catch(
      () => false
    );
  }
};

export const appLogger: Record<Severity, ReturnType<typeof getLogger>> = {
  debug: getLogger('debug'),
  info: getLogger('info'),
  warn: getLogger('warn'),
  error: getLogger('error')
};

// vm => 'current > parent > ... > App'
const getComponentTree = (vm: Vue): string => {
  const tree: string[] = [];
  const getComponentName = (target: Vue | undefined) => {
    const componentName = target?.$options?.name;
    if (!componentName) return;
    tree.push(componentName);
    getComponentName(target?.$parent);
  };
  getComponentName(vm);
  return tree.join(' < ');
};

export const errorHandler = (err: Error, vm: Vue, info: string) => {
  appLogger.error(err.message, {
    stack: err.stack,
    component: getComponentTree(vm),
    info,
    from: 'vue errorHandler'
  });
};
export const warnHandler = (msg: string, vm: Vue, trace: string) => {
  appLogger.warn(msg, {
    component: getComponentTree(vm),
    trace,
    from: 'vue warnHandler'
  });
};
