
<v-snackbar
  v-model="show"
  :timeout="-1"
  color="error"
  text
  transition="slide-y-transition"
  top
>
  <v-row class="pr-4">
    <v-col cols="10" class="pr-0">
      「いい生活Square 内見/申込管理機能」最新版が公開されました
      <br />
      画面を更新してください
      <br />
      未保存のデータがある場合は破棄されますのでご注意ください
    </v-col>
    <v-col cols="2" class="pl-0" align-self="center">
      <v-btn outlined color="error" @click="$router.go(0)">更新する</v-btn>
    </v-col>
  </v-row>
</v-snackbar>
