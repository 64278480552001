<template>
  <v-snackbar
    v-model="show"
    :timeout="-1"
    color="error"
    text
    transition="slide-y-transition"
    top
  >
    <v-row class="pr-4">
      <v-col cols="10" class="pr-0">
        「いい生活Square 内見/申込管理機能」最新版が公開されました
        <br />
        画面を更新してください
        <br />
        未保存のデータがある場合は破棄されますのでご注意ください
      </v-col>
      <v-col cols="2" class="pl-0" align-self="center">
        <v-btn outlined color="error" @click="$router.go(0)">更新する</v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LatestVersionSnackBar extends Vue {
  @Prop({ type: Boolean, default: false }) show?: boolean;
}
</script>

<style scoped>
.v-snack {
  z-index: 1000 !important;
  padding-top: 0 !important;
}
/** textプロパティを適用するとelevation */
.v-snack:not(.v-snack--has-background) >>> .v-snack__wrapper {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%) !important;
}
</style>
