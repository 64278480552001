import { IVersion } from 'requestform-types';
import { versionDocumentPath } from 'requestform-types/lib/FirestorePath';
import { isString } from 'requestform-types/lib/TypeGuard';
import { Module } from 'vuex-smart-module';

import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';
import { appLogger } from '@/utilities/appLogger';

class VersionDocumentState extends FirestoreDocumentState<IVersion> {}

export class VersionDocumentGetters extends FirestoreDocumentGetters<
  IVersion,
  VersionDocumentState
> {
  get latestVersion() {
    return this.state.data.version;
  }
}

class VersionDocumentMutations extends FirestoreDocumentMutations<
  IVersion,
  VersionDocumentState
> {}

export class VersionDocumentActions extends FirestoreDocumentActions<
  IVersion,
  VersionDocumentState,
  VersionDocumentGetters,
  VersionDocumentMutations
> {
  setVersionDocumentRef() {
    this.setDocumentRef(versionDocumentPath);
  }
}

export const isMajorOrMinorVersionChanged: (
  current: string,
  newest: string
) => boolean = (current, newest) => {
  if (![current, newest].every(v => v && isString(v))) {
    appLogger.error(
      `version type or parameter is invalid. current: ${current}. newest: ${newest}`,
      { current, newest }
    );
    return false;
  }
  const currents = current.split('.');
  const newests = newest.split('.');
  if (![currents.length, newests.length].every(len => len === 3)) {
    appLogger.error(
      `version format is invalid. current: ${current}. newest: ${newest}`,
      { current, newest }
    );
    return false;
  }
  const [currentMajor, currentMinor] = currents;
  const [newestMajor, newestMinor] = newests;
  // NOTE: メジャー、またはマイナーバージョンがクライアント側とDB側で異なっていたらtrueを返す
  return currentMajor !== newestMajor || currentMinor !== newestMinor;
};

export const VersionDocumentModule = new Module({
  state: VersionDocumentState,
  getters: VersionDocumentGetters,
  mutations: VersionDocumentMutations,
  actions: VersionDocumentActions
});
