import { IRequest, IShop } from 'requestform-types';
import { IDomainSetting } from 'requestform-types/lib/IDomainSetting';
import {
  isOursRequest,
  isTheirsRequest
} from 'requestform-types/lib/TypeGuard';

import { OneUserOrganization } from './proxy';

type IsShowRequestToOneUserBase = (
  request: Partial<IRequest>,
  domainUID: string,
  oneUserOrganizations: OneUserOrganization[]
) => boolean;

export const isShowOursRequestToOneUser: IsShowRequestToOneUserBase = (
  request,
  domainUID,
  oneUserOrganizations
) => {
  if (!isOursRequest(request, domainUID)) return false;
  const shop = request.kanriKaishaShop as Partial<IShop>;
  if (!shop?.customerID) return true;
  return !!oneUserOrganizations.find(o => o.customerID === shop.customerID);
};

export const isShowTheirsRequestToOneUser: IsShowRequestToOneUserBase = (
  request,
  domainUID,
  oneUserOrganizations
) => {
  if (!isTheirsRequest(request, domainUID)) return false;
  const shopCustomerID = request.chukaiKaishaTenpoCustomerID;
  if (!shopCustomerID) return true;
  return !!oneUserOrganizations.find(o => o.customerID === shopCustomerID);
};

type AddParameters<F extends (...any: any) => any, T extends [...args: any]> = (
  ...args: [...Parameters<F>, ...T]
) => ReturnType<F>;

export const isShowRequestToOneUser: AddParameters<
  IsShowRequestToOneUserBase,
  [
    hasMasterRoleFlag: boolean | null,
    domainSetting: Partial<IDomainSetting> | undefined
  ]
> = (
  request,
  domainUID,
  oneUserOrganizations,
  hasMasterRoleFlag,
  domainSetting
): boolean => {
  if (!domainSetting?.shopVisibleRestriction) return true;
  if (hasMasterRoleFlag === null) return false;
  return [isShowOursRequestToOneUser, isShowTheirsRequestToOneUser].some(x =>
    x(request, domainUID, oneUserOrganizations)
  );
};
