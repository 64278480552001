import { onAuthStateChanged, User } from 'firebase/auth';
import { isString } from 'requestform-types/lib/TypeGuard';
import Vue from 'vue';
import { config } from 'vue-gtag';
import Router, { Location, RawLocation, Route, RouteConfig } from 'vue-router';

import { getRedirectUri } from '@/auth0/client';
import { auth } from '@/firebase/firebase';
import { AppLocalModule } from '@/requestform/store/AppLocalModule';
import { SignInModule } from '@/requestform/store/SignInModule';
import { appLogger, parseError } from '@/utilities/appLogger';
import * as fullstar from '@/utilities/fullstar';
import { getIsAccessAllowed } from '@/utilities/getIsAccessAllowed';

import store from '../store';
import { DomainDocumentModule } from '../store/DomainDocumentModule';
import { LicenseCollectionModule } from '../store/LicenseCollectionModule';
import { bizSupportRoute } from './bizSupportRoute';
import { naikenYoyakuRoute } from './naikenYoyakuRoute';
import { requestRoute } from './requestRoute';

Vue.use(Router);

const SignIn = () =>
  import(/* webpackChunkName: "signIn"*/ '../views/SignIn.vue');
const SignUp = () =>
  import(/* webpackChunkName: "signUp"*/ '../views/SignUp.vue');
const ErrorPage = () =>
  import(/* webpackChunkName: "errorPage"*/ '../views/ErrorPage.vue');
const InvalidUserGuidePage = () =>
  import(
    /* webpackChunkName: "InvalidUserGuidePage"*/ '../views/InvalidUserGuidePage.vue'
  );
const MaintenancePage = () =>
  import(
    /* webpackChunkName: "maintenancePage"*/ '../views/MaintenancePage.vue'
  );

const signInModule = SignInModule.context(store);
const appLocalModule = AppLocalModule.context(store);
const domainDocumentModule = DomainDocumentModule.context(store);
const licenseCollectionModule = LicenseCollectionModule.context(store);

onAuthStateChanged(auth, async user => {
  await signInModule.actions.changeAuthCallback(user).catch(e => {
    appLogger.error('Failed changeAuthCallback', parseError(e));
  });
});

// 通ったRouteを保持しておく
const history: Route[] = [];

export const parseRoute = (route?: Route) => {
  if (!route) return {};
  const isNaikenYoyaku = !!route.query['n'];
  const requestUID =
    route.params['requestUID'] || (!isNaikenYoyaku ? route.params['UID'] : '');
  const naikenYoyakuUID =
    route.params['naikenYoyakuUID'] ||
    (isNaikenYoyaku ? route.params['UID'] : '');
  return {
    requestUID,
    naikenYoyakuUID,
    isKanriKaisha: !!route.query['k'],
    isNaikenYoyaku
  };
};

export const signInRoute: RouteConfig = {
  name: 'signIn',
  path: '/signIn',
  component: SignIn,
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(async user => {
      const redirectPath = isString(to.query['redirect'])
        ? to.query['redirect']
        : '/';

      if (!user) {
        const auth0 = await signInModule.actions.getOrInitAuth0();
        //リダイレクト時は auth0 でログイン後にリダイレクトする
        const isAuthenticated = await auth0.isAuthenticated();
        if (isAuthenticated) {
          return await auth0.loginWithRedirect({
            appState: { targetUrl: redirectPath },
            redirect_uri: `${getRedirectUri()}`
          });
        }
        // 未ログインならそのまま通す
        return next();
      }
      // ログイン中なら自動遷移する
      next({ path: redirectPath });
    });
  }
};

export const redirectToSignIn = (
  route: Partial<Route>,
  optionQuery?: { [key: string]: string | (string | null)[] }
) => {
  return {
    path: signInRoute.path,
    // NOTE: Auth0で日本語エンコードが2重にされてしまう問題があるため、URLパラメータまでは含めない
    query: { redirect: route.path, ...optionQuery }
  };
};

export const redirectSignUpToDetail = (route: Route) => {
  const { requestUID, naikenYoyakuUID, isKanriKaisha } = parseRoute(route);
  if (isKanriKaisha) return { path: '/' };
  if (requestUID) return { path: `/request/${requestUID}` };
  if (naikenYoyakuUID) return { path: `/reserved/${naikenYoyakuUID}` };
  return { path: '/' };
};

export const signUpRoute: RouteConfig = {
  name: 'signUp',
  path: '/signUp/:UID',
  component: SignUp,
  props: route => parseRoute(route),
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(user => {
      if (!user) {
        // 未ログインならそのまま通す
        return next();
      }
      // ログイン中なら自動遷移する
      next(redirectSignUpToDetail(to));
    });
  }
};

export const signOutRoute: RouteConfig = {
  path: '/signOut',
  name: 'SignOut',
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(async user => {
      if (signInModule.getters.isIDProviderAccount) {
        // NOTE: Auth0で日本語エンコードが2重にされてしまう問題があるため、URLパラメータまでは含めない
        const redirectTo = `${signInRoute.path}?redirect=${from.path}`;
        await signInModule.actions.signOut(redirectTo);
        // いい生活アカウントのときはリダイレクトをAuth0に委ねる
        next();
        return;
      }
      if (user) {
        // ログイン中ならサインアウトさせる
        await signInModule.actions.signOut();
      }
      // 未ログインならサインイン画面へ遷移させる
      next(redirectToSignIn(from));
    });
  }
};

export const InvalidUserGuideRoute: RouteConfig = {
  path: '/invalidUserGuide',
  name: 'InvalidUserGuide',
  component: InvalidUserGuidePage,
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(user => {
      // 未ログインならサインイン画面へ
      if (!user) {
        return next(signInRoute);
      }
      return next();
    });
  }
};

export const forcedSignOut = async (
  to: Route
): Promise<RawLocation | undefined> => {
  const email = signInModule.getters.getUser?.email;
  if (email) {
    localStorage.setItem('userEmail', email);
  }
  signInModule.actions.setIsAuthResolved(false);
  // いい生活アカウントの場合
  if (signInModule.getters.isIDProviderAccount) {
    const redirectTo = `${signInRoute.path}?redirect=${to.path}&retry=true`;
    // SEから離れてAuth0にサインアウト処理を移譲
    await signInModule.actions.signOut(redirectTo);
    return;
  }
  await signInModule.actions.signOut();
  const recentRoute = history
    .reverse()
    .find(x => x.name === signInRoute.name || x.name === signUpRoute.name);
  // サインインとサインアップの内、直近の方へ遷移する
  if (recentRoute && recentRoute.name === signUpRoute.name) {
    const signUpRetryRoute: Location = {
      path: recentRoute.path,
      params: recentRoute.params,
      query: { ...recentRoute.query, retry: 'true' }
    };
    return signUpRetryRoute;
  }
  return redirectToSignIn(to, { retry: 'true' });
};

export const accessCheck = async (): Promise<boolean> => {
  if (appLocalModule.getters.getIsAccessAllowed) {
    return true;
  }
  const isAccessAllowed = await getIsAccessAllowed(store);
  appLocalModule.actions.setIsAccessAllowed(isAccessAllowed);
  return isAccessAllowed;
};

export const errorPageRoute: RouteConfig = {
  path: '/error',
  name: 'ErrorPage',
  component: ErrorPage,
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(
      async (user, domainUID) => {
        // 未ログインならサインイン画面へ
        if (!user) {
          return next(signInRoute);
        }

        if (!domainUID) {
          return next(InvalidUserGuideRoute);
        }

        const isAccessAllowed = await accessCheck();
        if (!isAccessAllowed) {
          // 許可されていなければそのまま通す
          console.warn('Unauthorized');
          return next();
        }
        // 許可されているならルートに遷移
        next('/');
      }
    );
  }
};

export const maintenancePageRoute: RouteConfig = {
  path: '/maintenance',
  name: 'maintenancePage',
  component: MaintenancePage
};

/* 認証が必要なRouteの共通処理
 * 判定の結果、別Routeに遷移させる必要がある場合はRouteを返し、そのまま通せる場合はundefinedを返す
 */
export const initRoute = async (
  to: Route,
  user: User | null,
  domainUID: string,
  initRefs: (domainUID: string) => Promise<unknown[]> | undefined
): Promise<RawLocation | undefined> => {
  // 未ログインならサインイン画面へ
  if (!user) {
    return redirectToSignIn(to);
  }
  // いい生活アカウントユーザーだがAuth0の認証情報が無い場合は強制ログアウト
  if (
    signInModule.getters.idProviderUserUID &&
    !signInModule.getters.isIDProviderAccount
  ) {
    await signInModule.actions.signOut();
    return redirectToSignIn(to);
  }

  if (!domainUID) {
    return InvalidUserGuideRoute;
  }

  await initRefs(domainUID)
    ?.then(() => {
      // GAのセットアップ
      config({
        domain_uid: domainUID,
        account_uid: user.uid,
        has_license: licenseCollectionModule.getters.hasLicense
      });
      // NOTE: 申込詳細を閉じずにサイトから離脱した際に残留するGA送信用キャッシュをクリア
      localStorage.removeItem('gaRequestUid');
      localStorage.removeItem('gaRequestDisplayTime');
      localStorage.removeItem('gaNaikenYoyakuUID');
      // fullstarのセットアップ
      fullstar.setup(user.uid, {
        companyName: domainDocumentModule.getters.name,
        userName: signInModule.getters.userName,
        userEmail: signInModule.getters.email,
        haslicense: licenseCollectionModule.getters.hasLicense,
        hasNaikenYoyakuLicense:
          licenseCollectionModule.getters.hasNaikenYoyakuKanriLicense
      });
      if (licenseCollectionModule.getters.hasToDoKanriLicense) {
        signInModule.actions.updateEsaUserList();
      }
    })
    .catch(e => {
      appLogger.error('Failed init', { e: parseError(e), to: to.fullPath });
      // 初期化に失敗したら再ログインさせる;
      return forcedSignOut(to);
    });
  const isAccessAllowed = await accessCheck();
  if (!isAccessAllowed) {
    // 許可されていなければエラーページに遷移
    console.warn('Unauthorized');
    return errorPageRoute;
  }
  // 許可されているなら各Routeの処理に委ねる
  return;
};

const routes: RouteConfig[] = [
  requestRoute,
  naikenYoyakuRoute,
  bizSupportRoute,
  signInRoute,
  signUpRoute,
  signOutRoute,
  errorPageRoute,
  InvalidUserGuideRoute,
  maintenancePageRoute
];

const router = new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior() {
    const selector = location.hash;
    if (selector) {
      return { selector };
    }
  }
});

router.beforeEach((to, from, next) => {
  signInModule.actions.pushAfterAuthResolvedTask(() =>
    signInModule.actions.auth0Redirect(next)
  );
  history.push(to);
  next();
});

export default router;
