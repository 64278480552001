import { IShop } from 'requestform-types';
import { shopCollectionPath } from 'requestform-types/lib/FirestorePath';
import { Module } from 'vuex-smart-module';

import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';

class ShopCollectionState extends FirestoreCollectionState<IShop> {}

export class ShopCollectionGetters extends FirestoreCollectionGetters<
  IShop,
  ShopCollectionState
> {}

class ShopCollectionMutations extends FirestoreCollectionMutations<
  IShop,
  ShopCollectionState
> {}

class ShopCollectionActions extends FirestoreCollectionActions<
  IShop,
  ShopCollectionState,
  ShopCollectionGetters,
  ShopCollectionMutations
> {
  setDomainCollectionRef(domainUID: string) {
    if (!this.state.ref && !!domainUID) {
      this.setCollectionRef(shopCollectionPath(domainUID));
    }
  }
}

export const ShopCollectionModule = new Module({
  state: ShopCollectionState,
  getters: ShopCollectionGetters,
  mutations: ShopCollectionMutations,
  actions: ShopCollectionActions
});
