import { PluginFunction } from 'vue';

type LoadingState = {
  state: boolean;
  absolute: boolean;
  start: (options?: { absolute: boolean }) => void;
  end: () => void;
};

const install: PluginFunction<any> = vm => {
  vm.prototype.$loading = vm.observable<LoadingState>({
    state: false,
    absolute: true,
    start: function (options = { absolute: true }) {
      this.absolute = options.absolute;
      this.state = true;
    },
    end: function () {
      this.state = false;
    }
  });
};

export default {
  install
};
