import { IInformation } from 'requestform-types';
import { Store } from 'vuex';
import { Module } from 'vuex-smart-module';

import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';

class InformationCollectionState extends FirestoreCollectionState<IInformation> {}

export class InformationCollectionGetters extends FirestoreCollectionGetters<
  IInformation,
  InformationCollectionState
> {
  store!: Store<any>;
  $init(store: Store<any>): void {
    this.store = store;
  }

  get sortedList() {
    const slice = this.state.data.slice();
    return slice
      .sort((a, b) => {
        if (a.informationUID < b.informationUID) return -1;
        if (a.informationUID > b.informationUID) return 1;
        return 0;
      })
      .reverse();
  }
}

class InformationCollectionMutations extends FirestoreCollectionMutations<
  IInformation,
  InformationCollectionState
> {}

class InformationCollectionActions extends FirestoreCollectionActions<
  IInformation,
  InformationCollectionState,
  InformationCollectionGetters,
  InformationCollectionMutations
> {}

export const InformationCollectionModule = new Module({
  state: InformationCollectionState,
  getters: InformationCollectionGetters,
  mutations: InformationCollectionMutations,
  actions: InformationCollectionActions
});
