import axios from 'axios';
import { decode, encode } from 'base64-arraybuffer';
import { httpsCallable } from 'firebase/functions';
import fileDownload from 'js-file-download';
import { functionsPayload, IChohyoTemplate } from 'requestform-types';
import {
  SendFaxRequest,
  SendFaxRequestResult
} from 'requestform-types/lib/IFaxDataType';
import { TemporaryReserved } from 'requestform-types/lib/naikenYoyaku/INaikenYoyakuTatemonoGuidMap';
import { CreateRequestNaikenYoyakuBody } from 'requestform-types/lib/PublicDataType';

import { functions, functionsAsiaNortheast1 } from '@/firebase/firebase';

import { appLogger } from './appLogger';
import { getIdToken } from './proxy';

export const httpsCallableUserApi = <Payload = unknown, Response = unknown>(
  funcName: string
) => async (payload: Payload, customAuthToken?: string): Promise<Response> => {
  const url = `${import.meta.env.VITE_USER_API_URL}/${funcName}`;
  const authToken = customAuthToken ?? (await getIdToken());
  const headers = {
    authorization: `Bearer ${authToken}`
  };
  const {
    status,
    data: { data }
  } = await axios.post<{ data: Response }>(url, payload, {
    headers
  });
  if (status !== 200) {
    throw new Error(`response status is not 200. status: ${status}`);
  }
  return data;
};

export const publishFirebaseToken = async (auth0Token: string) => {
  const response = await httpsCallableUserApi<
    unknown,
    functionsPayload.CreateCustomTokenResult
  >('token/firebase')(undefined, auth0Token);
  return response.customToken;
};

export const changeDomainUID = httpsCallableUserApi<functionsPayload.ChangeDomainUIDPayload>(
  'changeDomainUID'
);

export const changeDomainUIDWithRequestUID = httpsCallableUserApi<
  functionsPayload.ChangeDomainUIDWithRequestUIDPayload,
  functionsPayload.ChangeDomainUIDWithRequestUIDResult
>('changeDomainUIDWithRequestUID');

export const changeDomainUIDWithNaikenYoyakuUID = httpsCallableUserApi<
  functionsPayload.ChangeDomainUIDWithNaikenYoyakuUIDPayload,
  functionsPayload.ChangeDomainUIDWithNaikenYoyakuUIDResult
>('changeDomainUIDWithNaikenYoyakuUID');
export const changeCurrentDomainUID = httpsCallableUserApi<functionsPayload.ChangeCurrentDomainUIDPayload>(
  'changeCurrentDomainUID'
);

export const isIncludeKanriKaishaInDomainMap = httpsCallableUserApi<functionsPayload.isIncludeKanriKaishaInDomainMapPayload>(
  'isIncludeKanriKaishaInDomainMap'
);

export const recoverReactionNeededCount = () =>
  httpsCallableUserApi('recoverReactionNeededCount')(undefined).catch(e => {
    appLogger.error('recoverReactionNeededCount error', e);
  });

export const extendDeadlineDatetime = () =>
  httpsCallableUserApi('extendDeadlineDatetime')(undefined).catch(e => {
    appLogger.error('extendDeadlineDatetime error', e);
    throw e;
  });

export const httpsCallableCreateApi = <Payload = unknown, Response = unknown>(
  funcName: string
) => async (payload: Payload, customAuthToken?: string): Promise<Response> => {
  const url = `${import.meta.env.VITE_CREATE_API_URL}/${funcName}`;
  const authToken = customAuthToken ?? (await getIdToken());
  const headers = {
    authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json'
  };
  const { status, data } = await axios.post<Response>(url, payload, {
    headers
  });
  if (status !== 200) {
    throw new Error(`response status is not 200. status: ${status}`);
  }
  return data;
};

export const createRequest = httpsCallableCreateApi<
  CreateRequestNaikenYoyakuBody,
  { redirectUrl: string }
>('request');

export const getDomainTantoshaMailAddressByRequestUID = httpsCallable<
  functionsPayload.GetDomainTantoshaMailAddressByRequestUIDPayload,
  functionsPayload.GetDomainTantoshaMailAddressByRequestUIDResult
>(functionsAsiaNortheast1, 'userApi/getDomainTantoshaMailAddressByRequestUID');

export const getDomainTantoshaMailAddressByNaikenYoyakuUID = (
  payload: functionsPayload.GetDomainTantoshaMailAddressByNaikenYoyakuUIDPayload
) => {
  const func = httpsCallable<
    typeof payload,
    functionsPayload.GetDomainTantoshaMailAddressByNaikenYoyakuUIDResult
  >(
    functionsAsiaNortheast1,
    'naikenYoyakuUserApi/getDomainTantoshaMailAddressByNaikenYoyakuUID'
  );
  return func(payload);
};

export const createDomainAccount = httpsCallable<
  functionsPayload.CreateDomainAccountPayload,
  functionsPayload.CreateDomainAccountResult
>(functionsAsiaNortheast1, 'userApi/createDomainAccount');

/**
 * 帳票テンプレートファイルを登録する
 * @param templateUID テンプレートID
 * @param template テンプレート名称
 * @param comment テンプレートに対するコメント
 * @param binaryFile 登録するバイナリファイル
 * @returns 登録成否
 */
export const updateChohyoTemplate = async (
  template: IChohyoTemplate,
  binaryFile: ArrayBuffer
) => {
  const func = httpsCallable<
    functionsPayload.UpdateChohyoTemplatePayload,
    functionsPayload.UpdateChohyoTemplateResult
  >(functionsAsiaNortheast1, 'userApi/updateChohyoTemplate');
  const file = encode(binaryFile);
  const payload = {
    template,
    file
  };
  const result = await func(payload);
  return result.data === true;
};

/**
 * 帳票テンプレートファイルを削除する
 * @param template テンプレート
 * @returns 削除成否
 */
export const deleteChohyoTemplate = async (template: IChohyoTemplate) => {
  const func = httpsCallable<
    functionsPayload.DeleteChohyoTemplatePayload,
    functionsPayload.DeleteChohyoTemplateResult
  >(functionsAsiaNortheast1, 'userApi/deleteChohyoTemplate');
  const payload = {
    templateUID: template.templateUID,
    template: template.name
  };
  const result = await func(payload);
  return result.data === true;
};

/**
 * 帳票テンプレートファイルをダウンロードする
 * @param template テンプレート
 * @returns ダウンロード成否
 */
export const downloadChohyoTemplate = async (template: IChohyoTemplate) => {
  const func = httpsCallable<
    functionsPayload.DownloadChohyoTemplatePayload,
    functionsPayload.DownloadChohyoTemplateResult
  >(functionsAsiaNortheast1, 'userApi/downloadChohyoTemplate');
  const payload = {
    templateUID: template.templateUID
  };
  const result = await func(payload);

  try {
    fileDownload(decode(result.data), `${template.name}.xlsx`);
  } catch (err) {
    appLogger.error(err as Error);
    return false;
  }
  return true;
};

/**
 * FAXを送信する
 * @param data FAX送信データ
 * @returns 出力成否
 */
export const sendFax = async (data: SendFaxRequest) => {
  const func = httpsCallable<SendFaxRequest, SendFaxRequestResult>(
    functionsAsiaNortheast1,
    'userApi4/sendFax'
  );
  const result = await func(data);
  if (data.isReturnPdf && result.data.pdf) {
    fileDownload(decode(result.data.pdf), `${data.template.name}.pdf`);
  }
  return result;
};

/**
 * 申込CSVデータを取得する
 * @param requestUIDs requestUIDのリスト
 * @returns CSVデータリスト
 */
export const getRequestCsvDataByUID = httpsCallable<
  functionsPayload.GetRequestCsvDataByUIDPayload,
  functionsPayload.GetRequestCsvDataByUIDResult
>(functionsAsiaNortheast1, 'userApi/getRequestCsvDataByUID');

export const deleteRequest = httpsCallable<
  functionsPayload.DeleteDummyRequestPayload,
  functionsPayload.DeleteDummyRequestResult
>(functionsAsiaNortheast1, 'userApi/deleteDummyRequest');

export const createMoshikomiUserAccount = httpsCallable<
  functionsPayload.CreateMoshikomiUserAccountPayload,
  functionsPayload.CreateMoshikomiUserAccountResult
>(functionsAsiaNortheast1, 'userApi/createMoshikomiUserAccount');

export const createMoshikomiUserAccountAndSendMail = httpsCallable<
  functionsPayload.CreateMoshikomiUserAndSendMailPayload,
  functionsPayload.CreateMoshikomiUserAndSendMailResult
>(functionsAsiaNortheast1, 'userApi/createMoshikomiUserAccountAndSendMail');

export const reissuePassword = httpsCallable<
  functionsPayload.ReissuePasswordPayload,
  functionsPayload.ReissuePasswordResult
>(functionsAsiaNortheast1, 'userApi/reissuePassword');

export const getUserNameMapByAccountUIDs = httpsCallable<
  functionsPayload.GetUserNameMapByAccountUIDsPayload,
  functionsPayload.GetUserNameMapByAccountUIDsResult
>(functionsAsiaNortheast1, 'userApi/getUserNameMapByAccountUIDs');

export function sendNaikenYoyakuMail(naikenYoyakuUIDs: string[]) {
  const payload: functionsPayload.SendNaikenYoyakuMailPayload = {
    naikenYoyakuUIDs
  };
  const func = httpsCallable<
    typeof payload,
    functionsPayload.SendNaikenYoyakuMailResult
  >(functionsAsiaNortheast1, 'naikenYoyakuUserApi/sendNaikenYoyakuMail');
  return func(payload);
}

export function sendNaikenYoyakuMailCancel(naikenYoyakuUIDs: string[]) {
  const payload: functionsPayload.SendNaikenYoyakuMailPayload = {
    naikenYoyakuUIDs
  };
  const func = httpsCallable<
    typeof payload,
    functionsPayload.SendNaikenYoyakuMailResult
  >(functionsAsiaNortheast1, 'naikenYoyakuUserApi/sendNaikenYoyakuMailCancel');
  return func(payload);
}

export function sendNaikenYoyakuMailReject(naikenYoyakuUIDs: string[]) {
  const payload: functionsPayload.SendNaikenYoyakuMailPayload = {
    naikenYoyakuUIDs
  };
  const func = httpsCallable<
    typeof payload,
    functionsPayload.SendNaikenYoyakuMailResult
  >(functionsAsiaNortheast1, 'naikenYoyakuUserApi/sendNaikenYoyakuMailReject');
  return func(payload);
}

/**
 * 内見予約CSVデータを取得する
 * @param naikenYoyakuUIDs naikenYoyakuUIDのリスト
 * @returns CSVデータリスト
 */
export function getNaikenYoyakuCsvDataByUID(naikenYoyakuUIDs: string[]) {
  const func = httpsCallable<
    functionsPayload.GetNaikenYoyakuCsvDataByUIDPayload,
    functionsPayload.GetNaikenYoyakuCsvDataByUIDResult
  >(functionsAsiaNortheast1, 'naikenYoyakuUserApi/getNaikenYoyakuCsvDataByUID');
  return func({
    naikenYoyakuUIDs
  });
}

export function getTatemonoReservedTimeRanges(
  targetDate: string,
  kanrikaishaDomainUID: string,
  bukkenUID: string,
  temporaryReserved?: Omit<TemporaryReserved, 'createdAt'>
) {
  const payload: functionsPayload.GetTatemonoReservedTimeRangesPayload = {
    targetDate,
    kanrikaishaDomainUID,
    bukkenUID,
    temporaryReserved
  };
  const func = httpsCallable<
    typeof payload,
    functionsPayload.GetTatemonoReservedTimeRangesResult
  >(
    functionsAsiaNortheast1,
    'naikenYoyakuUserApi/getTatemonoReservedTimeRanges'
  );
  return func(payload);
}

/**
 * 住所情報を取得する
 * @param zipCode 郵便番号
 * @returns 住所情報のリスト
 */
export const searchAddress = httpsCallable<
  functionsPayload.SearchAddressPaylod,
  functionsPayload.SearchAddressResult
>(functionsAsiaNortheast1, 'userApi/searchAddress');

export const fillingOnDomainGuid = () =>
  httpsCallable<undefined, functionsPayload.FillingOnDomainGuidResult>(
    functionsAsiaNortheast1,
    'userApi/fillingOnDomainGuid'
  )(undefined);

export const createKokyaku = httpsCallable<
  functionsPayload.CreateKokyakuPayload,
  functionsPayload.CreateKokyakuResult
>(functionsAsiaNortheast1, 'userApi/createKokyaku');

export const getIsAllowIp = () =>
  httpsCallable<undefined, functionsPayload.GetIsAllowIpResult>(
    functionsAsiaNortheast1,
    'userApi/getIsAllowIp'
  )(undefined);

export const getCurrentIp = () =>
  httpsCallable<undefined, functionsPayload.GetCurrentIpResult>(
    functionsAsiaNortheast1,
    'userApi/getCurrentIp'
  )(undefined);

export const createCustomToken = () =>
  httpsCallable<undefined, functionsPayload.CreateCustomTokenResult>(
    functionsAsiaNortheast1,
    'userApi/createCustomToken'
  )(undefined);

export function reviewEsStandard(
  payload: functionsPayload.ReviewEsStandardPayload
) {
  const func = httpsCallable<
    typeof payload,
    functionsPayload.ReviewEsStandardResult
  >(functions, 'api/externalService/esStandard/review');
  return func(payload);
}

export function reviewEpos(payload: functionsPayload.ReviewEposPayload) {
  const func = httpsCallable<typeof payload, functionsPayload.ReviewEposResult>(
    functions,
    'api/externalService/epos/review'
  );
  return func(payload);
}

export function reviewJaccs(payload: functionsPayload.ReviewJaccsPayload) {
  const func = httpsCallable<
    typeof payload,
    functionsPayload.ReviewJaccsResult
  >(functions, 'api/externalService/jaccs/review');
  return func(payload);
}

export const sendInputForm = httpsCallable<
  functionsPayload.SendInputFormPayload,
  functionsPayload.SendInputFormResult
>(functionsAsiaNortheast1, 'userApi/sendInputForm');

export const addReadDomains = httpsCallable<
  functionsPayload.AddReadDomainsPayload,
  undefined
>(functionsAsiaNortheast1, 'userApi/addReadDomains');

export const deletePreparationRequest = httpsCallable<
  functionsPayload.DeletePreparationRequestPayload,
  functionsPayload.DeletePreparationRequestResult
>(functionsAsiaNortheast1, 'userApi/deletePreparationRequest');

export const callAppLogger = (payload: functionsPayload.AppLoggerPayload) =>
  httpsCallable<typeof payload, void>(functions, 'appLogger/logging')(payload);

export const analyticsUserMAU = (
  payload: functionsPayload.UpdateAnalyticsUserMAUPayload
) => httpsCallable(functions, 'appLogger/analyticsUserMAU')(payload);

export const sendToSplunkOnOpenRequestDetailMAU = (
  payload: functionsPayload.SendToSplunkMAUPayload
) =>
  httpsCallable(functions, 'appLogger/splunk/onOpenRequestDetailMAU')(payload);

export const sendToSplunkOnOpenNaikenYoyakuDetailMAU = (
  payload: functionsPayload.SendToSplunkMAUPayload
) =>
  httpsCallable(
    functions,
    'appLogger/splunk/onOpenNaikenYoyakuDetailMAU'
  )(payload);

export const sendToSplunkOnOpenFutaiToritsugiDetailMAU = (
  payload: functionsPayload.SendToSplunkMAUPayload
) =>
  httpsCallable(
    functions,
    'appLogger/splunk/onOpenFutaiToritsugiDetailMAU'
  )(payload);

export const sendToSplunkOnSendFutaiToritsugiToLifeIn = (
  payload: functionsPayload.SendToSplunkOnSendFutaiToritsugiPayload
) =>
  httpsCallable(
    functions,
    'appLogger/splunk/onSendFutaiToritsugiToLifeIn'
  )(payload);

export const sendToSplunkOnCancelFutaiToritsugiToLifeIn = (
  payload: functionsPayload.SendToSplunkOnSendFutaiToritsugiPayload
) =>
  httpsCallable(
    functions,
    'appLogger/splunk/onCancelFutaiToritsugiToLifeIn'
  )(payload);

export const sendToSplunkOnRestartFutaiToritsugiToLifeIn = (
  payload: functionsPayload.SendToSplunkOnSendFutaiToritsugiPayload
) =>
  httpsCallable(
    functions,
    'appLogger/splunk/onRestartFutaiToritsugiToLifeIn'
  )(payload);

export const sendToSplunkLinkToOneMoshikomi = (
  payload: functionsPayload.SendToSplunkLinkToOnePayload
) =>
  httpsCallable(functions, 'appLogger/splunk/sendLinkToOneMoshikomi')(payload);

export const sendToSplunkLinkToOneMoshikomiCancel = (
  payload: functionsPayload.SendToSplunkLinkToOnePayload
) =>
  httpsCallable(
    functions,
    'appLogger/splunk/sendLinkToOneMoshikomiCancel'
  )(payload);

export const sendToSplunkLinkToOneBoshudome = (
  payload: functionsPayload.SendToSplunkLinkToOnePayload
) =>
  httpsCallable(functions, 'appLogger/splunk/sendLinkToOneBoshudome')(payload);

export const sendToSplunkOnChangeRequestDomainSetting = (
  payload: functionsPayload.SendToSplunkOnChangeRequestDomainSettingPayload
) =>
  httpsCallable(
    functions,
    `appLogger/splunk/onChangeRequestDomainSetting`
  )(payload);

export const sendToSplunkOnChangeNaikenYoyakuSetting = (
  payload: functionsPayload.SendToSplunkOnChangeNaikenYoyakuSettingPayload
) =>
  httpsCallable(
    functions,
    'appLogger/splunk/onChangeNaikenYoyakuSetting'
  )(payload);

export const sendToSplunkOnChangeSubStatusSetting = (
  payload: functionsPayload.SendToSplunkOnChangeSubStatusSettingPayload
) =>
  httpsCallable(
    functions,
    `appLogger/splunk/onChangeSubStatusSetting`
  )(payload);

export const updateRequestStatusInBulk = httpsCallable<
  functionsPayload.UpdateRequestStatusInBulkPayload,
  functionsPayload.UpdateRequestStatusInBulkPayload
>(functionsAsiaNortheast1, 'userApi/updateRequestStatusInBulk');

export const updateRequestTagInBulk = httpsCallable<
  functionsPayload.UpdateRequestTagInBulkPayload,
  functionsPayload.UpdateRequestTagInBulkPayload
>(functionsAsiaNortheast1, 'userApi/updateRequestTagInBulk');

export const getFeatureFlag = httpsCallable<
  functionsPayload.FeatureFlagPayload,
  functionsPayload.FeatureFlagResult
>(functionsAsiaNortheast1, 'userApi/getFeatureFlag');

export const getToDoNameList = httpsCallable<
  undefined,
  functionsPayload.GetToDoNameListResult
>(functionsAsiaNortheast1, 'userApi/getToDoNameList');
