import flatten from 'lodash-es/flatten';
import get from 'lodash-es/get';
import {
  categoryNameType,
  IDomainFormSettingValue,
  IFormConfigure,
  ISystemConfigure,
  IUserConfigureValue,
  parentPropertyNameType
} from 'requestform-types/lib/IFormConfigure';
import { kokyakuKubun } from 'requestform-types/lib/IPerson';
import {
  bukkenYotoTypes,
  getUserRequestTypeKey,
  UserRequestType
} from 'requestform-types/lib/IRequest';
import { configs } from 'requestform-types/lib/IRequestItemConfigure';
import { isDefined } from 'requestform-types/lib/TypeGuard';

const foreignerLabelMap = new Map<string, string>([
  ['連帯保証人', '家族緊急連絡先'],
  ['連帯保証人勤務先', '家族緊急連絡先の勤務先'],
  ['緊急連絡先', '国内緊急連絡先'],
  ['緊急連絡先の勤務先', '国内緊急連絡先の勤務先']
]);

export const userRequestTypeKeys: UserRequestType[] = [
  'foreignerKojinHouse',
  'foreignerKojinParking',
  'hojinHouse',
  'hojinParking',
  'kojinHouse',
  'kojinParking'
];

const isForeigner = (moshikomishaType: kokyakuKubun): boolean => {
  return [kokyakuKubun.foreignerKojin, kokyakuKubun.foreignerHojin].includes(
    moshikomishaType
  );
};

export function isSystemRequired(
  parentPropertyName: parentPropertyNameType,
  propertyName: string
): boolean {
  const filteredConfig = configs.filter(
    x => x.parentPropertyName === parentPropertyName
  );
  if (filteredConfig.length === 0) return false;

  const systemConfigures = flatten<ISystemConfigure<any>>(
    filteredConfig.map(x => x.values)
  );
  const propertyConfig = systemConfigures.filter(
    x => x.propertyName === propertyName
  )[0];
  return !!propertyConfig?.systemRequire;
}

export function getInterlockPropertyName(
  parentPropertyName: parentPropertyNameType,
  propertyName: string
) {
  const filteredConfig = configs.filter(
    x => x.parentPropertyName === parentPropertyName
  );
  if (filteredConfig.length === 0) return;

  const systemConfigures = flatten<ISystemConfigure<any>>(
    filteredConfig.map(x => x.values)
  );
  const propertyConfig = systemConfigures.filter(
    x => x.propertyName === propertyName
  )[0];
  return propertyConfig?.interlockPropertyName;
}

export function isInterlockRequired(
  settings: IDomainFormSettingValue<any> | undefined,
  parentPropertyName: parentPropertyNameType,
  interlockPropertyName: string
) {
  // 連動先のsystemRequire: trueならrequire: true
  if (isSystemRequired(parentPropertyName, interlockPropertyName)) {
    return true;
  }
  const v = get(settings, [parentPropertyName, interlockPropertyName], null);
  if (!v) return false;
  return v.require;
}

export function isInterlockVisible(
  settings: IDomainFormSettingValue<any> | undefined,
  parentPropertyName: parentPropertyNameType,
  interlockPropertyName: string,
  options?: { isReview?: boolean }
) {
  if (!interlockPropertyName) return false;
  // 連動先のsystemRequire: trueならvisible: true
  if (isSystemRequired(parentPropertyName, interlockPropertyName)) {
    return true;
  }
  const v = get(settings, [parentPropertyName, interlockPropertyName], null);
  if (!v) return false;
  // オプションがある場合
  if (isDefined(options)) {
    if (options.isReview) return !!(v.visible || v.visibleAtReview);
    return v.visible;
  }
  // オプションがない場合
  return !!(v.visible || v.visibleAtReview);
}

export function isSystemUnrequired(
  parentPropertyName: parentPropertyNameType,
  propertyName: string
) {
  const filteredConfig = configs.filter(
    x => x.parentPropertyName === parentPropertyName
  );
  if (filteredConfig.length === 0) return true;

  const systemConfigures = flatten<ISystemConfigure<any>>(
    filteredConfig.map(x => x.values)
  );
  const propertyConfig = systemConfigures.filter(
    x => x.propertyName === propertyName
  )[0];
  return !propertyConfig || propertyConfig.systemUnrequire;
}

export function getCustomText(
  settings: IDomainFormSettingValue<any> | undefined,
  parentPropertyName: string,
  propertyName: string
) {
  if (!settings) return '';

  const v = get(settings, [parentPropertyName, propertyName], null);
  if (!v) return '';
  return (v as IUserConfigureValue).customText || '';
}

export function visibleConfigsFilter(
  config: IFormConfigure<any>,
  moshikomishaType: kokyakuKubun,
  bukkenType: bukkenYotoTypes
) {
  const isParkingCar = bukkenType === bukkenYotoTypes.parkingCar;
  const isKojin = [kokyakuKubun.kojin, kokyakuKubun.foreignerKojin].includes(
    moshikomishaType
  );

  if (isParkingCar) {
    let parkingCarNotVisibleCond: boolean;
    if (isForeigner(moshikomishaType)) {
      parkingCarNotVisibleCond = new Set([
        '付帯サービス',
        'アンケート',
        'その他入居条件等'
      ]).has(config.category);
    } else {
      parkingCarNotVisibleCond =
        new Set<categoryNameType>([
          '付帯サービス',
          'アンケート',
          'その他入居条件等',
          '入居者概要'
        ]).has(config.category) || config.parentPropertyName === 'hoshounin';
    }
    if (parkingCarNotVisibleCond) {
      return false;
    }
  } else {
    const jyukyoNotVisibleCond = new Set(['車両情報']).has(config.category);
    if (jyukyoNotVisibleCond) {
      return false;
    }
  }
  if (
    isKojin &&
    new Set(['hojinMoshikomisha', 'shatakuDaikoKaisha']).has(
      config.parentPropertyName
    )
  ) {
    return false;
  }
  return true;
}

export function visibleConfigsDetailFilter(
  config: IFormConfigure<any>,
  moshikomishaType: kokyakuKubun,
  bukkenType: bukkenYotoTypes
) {
  const foreignerKubun = [
    kokyakuKubun.foreignerKojin,
    kokyakuKubun.foreignerHojin
  ];
  if (!foreignerKubun.includes(moshikomishaType)) {
    // 申込者・緊急連絡先・保証人の希望言語、在留資格、滞在歴は外国人のときのみ表示する
    const targetCategories1 = ['申込者', '緊急連絡先', '連帯保証人'];
    if (targetCategories1.includes(config.category)) {
      const removeProperties = new Set([
        'languageTypes',
        'zairyuShikaku',
        'taizaiNensu',
        'taizaiTsukisu'
      ]);
      const values = config.values.filter(
        x => !removeProperties.has(x.propertyName as string)
      );
      config = {
        ...config,
        values
      };
    }
  }
  if (bukkenType === bukkenYotoTypes.parkingCar) {
    if (config.category === '保証会社・火災保険') {
      const removeProperties = new Set(['getDesignatedFireInsurance']);
      config = {
        ...config,
        values: config.values.filter(
          x => !removeProperties.has(x.propertyName as string)
        )
      };
    }
    if (config.category === 'その他入居条件等') {
      // 不要な項目の削除
      const removeProperties = new Set([
        'isGakkiPlaying',
        'gakkiType',
        'isPetBleeding',
        'petType'
      ]);
      // visible: falseにできないようにする
      const addSystemRequireProperties = new Set([
        'parkingCars',
        'parkingCarInfoText',
        'parkingMotorcicles',
        'parkingMotorcicleInfoText'
      ]);
      const values = config.values
        .filter(x => !removeProperties.has(x.propertyName as string))
        .map(x => {
          if (addSystemRequireProperties.has(x.propertyName as string)) {
            return {
              ...x,
              editable: false
            };
          }
          return x;
        });
      config = {
        ...config,
        values
      };
    }
    if (config.category === '誓約・承諾') {
      // 不要な項目の削除
      const removeProperties = new Set([
        'agreeCallToRentaihoshonin',
        'agreeGuaranteeCompanyExamination'
      ]);
      config = {
        ...config,
        values: config.values.filter(
          x => !removeProperties.has(x.propertyName as string)
        )
      };
    }
  }
  const hojinKubun = [kokyakuKubun.hojin, kokyakuKubun.foreignerHojin];
  if (
    !(
      hojinKubun.includes(moshikomishaType) &&
      bukkenType === bukkenYotoTypes.jukyo
    )
  ) {
    if (config.category === 'その他入居条件等') {
      // 不要な項目の削除
      const removeProperties = new Set(['chinryoBankTransfer']);
      const values = config.values.filter(
        x => !removeProperties.has(x.propertyName as string)
      );
      config = {
        ...config,
        values
      };
    }
  }
  if (!hojinKubun.includes(moshikomishaType)) {
    // 不要な項目の削除
    const removeProperties = ['standPoint', 'moshikomishaFillingNotes'];
    config = {
      ...config,
      values: config.values.filter(
        x => !removeProperties.includes(x.propertyName as string)
      )
    };
  }
  return config;
}

export interface PageError {
  bukken: { count: number; errors: string[] };
  moshikomisha: { count: number; errors: string[] };
  nyukyosha: { count: number; errors: string[] };
  hoshounin: { count: number; errors: string[] };
  shodakuJiko: { count: number; errors: string[] };
  unknown: { count: number; errors: string[] };
}

export const isExistReviewItem = (
  formSetting: Partial<IDomainFormSettingValue<any>>
) => {
  const formSettingValueList = Object.values(formSetting).filter(isDefined);
  for (const formSettingValue of formSettingValueList) {
    const isVisibleAtReview = Object.values(formSettingValue).some(
      x => x && x.visibleAtReview
    );
    if (isVisibleAtReview) {
      return true;
    }
  }
  return false;
};

export function isExistRequireReviewItem(
  formSetting: Partial<IDomainFormSettingValue<any>>
) {
  const formSettingValueList = Object.values(formSetting).filter(isDefined);
  for (const formSettingValue of formSettingValueList) {
    const isRequiredAtReview = Object.values(formSettingValue).some(
      x => x && x.visibleAtReview && x.require
    );
    if (isRequiredAtReview) {
      return true;
    }
  }
  return false;
}

export function getDefaultProperty() {
  return {
    visible: false,
    visibleAtReview: false,
    require: false,
    customText: ''
  };
}

/**
 * configs,UserRequestTypeをもとに
 * FormSetting.vueに表示するconfigを生成する
 * @param kokyakuKubunLabels
 * @param bukkenYotoLabels
 */
export function getSortedFilteredConfigsObj(
  kokyakuKubunLabels: { value: kokyakuKubun }[],
  bukkenYotoLabels: { value: bukkenYotoTypes }[]
) {
  const sortedFilteredConfigsObj = {} as { [key in UserRequestType]: any };
  kokyakuKubunLabels.forEach(k => {
    bukkenYotoLabels.forEach(b => {
      const requestTypeKey = getUserRequestTypeKey(k.value, b.value);
      sortedFilteredConfigsObj[requestTypeKey] = calcSortedFilteredConfigs(
        k.value,
        b.value
      ) as any;
    });
  });
  return sortedFilteredConfigsObj;
}

export function calcSortedFilteredConfigs(
  kubun: kokyakuKubun,
  yoto: bukkenYotoTypes
) {
  // 設定可能項目が存在しないcategoryは表示しない
  const filterFnRemoveCategoryBySystemRequireAndPlaceholderText = (
    x: IFormConfigure<any>
  ) => {
    return x.values.some(y => !y.systemRequire || y.placeholderText);
  };
  // 表示順が負のcategoryは表示しない
  const filterFnRemoveCategoryByMinusOrder = (x: IFormConfigure<any>) => {
    return x.categoryOrder >= 0;
  };
  // 申込種別でcategoryの表示・非表示を切り替える
  const filterFnRemoveCategoryByByRequestType = (x: IFormConfigure<any>) => {
    return visibleConfigsFilter(x, kubun, yoto);
  };
  const filterFnRemoveMultipleTenkyoRiyuCategory = (x: IFormConfigure<any>) => {
    return !(
      x.category === '転居理由・使用目的' && x.parentPropertyName === 'request'
    );
  };
  // 申込種別でcategory.valuesの表示・非表示を切り替える
  const mapFnEditValuesByRequestType = (x: IFormConfigure<any>) => {
    return visibleConfigsDetailFilter(x, kubun, yoto);
  };
  const mapFnReplaceCategoryNameByRequestType = (x: IFormConfigure<any>) => {
    const nyukyoStr = '入居';
    if (yoto === bukkenYotoTypes.parkingCar && x.category === '契約情報') {
      const nyukyoKiboDateIndex = x.values.findIndex(
        x => x.propertyName === 'nyukyoKiboDate'
      );
      if (nyukyoKiboDateIndex === -1) return x;
      const nyukyoKiboDate = x.values[nyukyoKiboDateIndex];
      const riyoKiboDate = {
        ...nyukyoKiboDate,
        displayName: '利用開始希望日'
      };
      const replaced = [...x.values];
      replaced.splice(nyukyoKiboDateIndex, 1, riyoKiboDate);
      return {
        ...x,
        values: replaced
      };
    }
    if (
      yoto === bukkenYotoTypes.parkingCar &&
      x.category.indexOf(nyukyoStr) !== -1
    ) {
      return {
        ...x,
        category: x.category.replace(nyukyoStr, '利用')
      };
    }
    if (isForeigner(kubun) && foreignerLabelMap.has(x.category)) {
      return {
        ...x,
        category: foreignerLabelMap.get(x.category)
      };
    }
    return x;
  };
  const compareNumber = (a: number, b: number) =>
    a < b ? -1 : a === b ? 0 : 1;

  // NOTE: 別オブジェクトで定義した「使用目的」を結合
  const tenkyoRiyuCategory = configs.filter(x => {
    return x.category === '転居理由・使用目的';
  });
  if (!tenkyoRiyuCategory[0].values.some(x => x.displayName === '使用目的')) {
    tenkyoRiyuCategory[0].values = tenkyoRiyuCategory[0].values.concat(
      tenkyoRiyuCategory[1].values
    );
  }

  return configs
    .filter(filterFnRemoveCategoryBySystemRequireAndPlaceholderText)
    .filter(filterFnRemoveCategoryByMinusOrder)
    .filter(filterFnRemoveCategoryByByRequestType)
    .filter(filterFnRemoveMultipleTenkyoRiyuCategory)
    .map(mapFnEditValuesByRequestType)
    .map(mapFnReplaceCategoryNameByRequestType)
    .map(x => {
      x.values = x.values.sort((a, b) => compareNumber(a.order, b.order));
      return x;
    })
    .sort((a, b) => compareNumber(a.categoryOrder, b.categoryOrder));
}
