import { FirestoreError } from '@firebase/firestore';
import {
  domainDocumentPath,
  informationCollectionPath,
  licenseCollectionPath,
  naikenYoyakuDocumentPath
} from 'requestform-types/lib/FirestorePath';
import { RouteConfig } from 'vue-router';

import { DomainDocumentModule } from '@/requestform/store/DomainDocumentModule';
import { DomainSettingDocumentModule } from '@/requestform/store/DomainSettingDocumentModule';
import { InformationCollectionModule } from '@/requestform/store/InformationCollectionModule';
import { LicenseCollectionModule } from '@/requestform/store/LicenseCollectionModule';
import { DomainForNaikenYoyakuSettingDocumentModule } from '@/requestform/store/naikenYoyaku/DomainForNaikenYoyakuSettingDocumentModule';
import { NaikenYoyakuDocumentModule } from '@/requestform/store/naikenYoyaku/NaikenYoyakuDocumentModule';
import { SignInModule } from '@/requestform/store/SignInModule';
import { VersionDocumentModule } from '@/store/VersionDocumentModule';
import { appLogger } from '@/utilities/appLogger';
import { changeDomainUIDWithNaikenYoyakuUID } from '@/utilities/firebaseFunctions';

import store from '../store';
import { DomainMapDocumentModule } from '../store/DomainMapDocumentModule';
import { forcedSignOut, initRoute, parseRoute } from './';

const NaikenYoyakuMain = () =>
  import(
    /* webpackChunkName: "main"*/ '../views/naikenYoyaku/NaikenYoyakuMain.vue'
  );
const NaikenYoyakuList = () =>
  import(
    /* webpackChunkName: "naikenYoyakuList"*/ '../views/naikenYoyaku/NaikenYoyakuList.vue'
  );
const NaikenYoyakuSetting = () =>
  import(
    /* webpackChunkName: "naikenYoyakuSetting"*/ '../views/naikenYoyaku/NaikenYoyakuSetting.vue'
  );

const signInModule = SignInModule.context(store);
const domainMapDocumentModule = DomainMapDocumentModule.context(store);
const domainDocumentModule = DomainDocumentModule.context(store);
const informationCollectionModule = InformationCollectionModule.context(store);
const domainSettingDocumentModule = DomainSettingDocumentModule.context(store);
const licenseCollectionModule = LicenseCollectionModule.context(store);
const naikenYoyakuDocumentModule = NaikenYoyakuDocumentModule.context(store);
const domainForNaikenYoyakuSettingDocumentModule = DomainForNaikenYoyakuSettingDocumentModule.context(
  store
);
const versionDocumentModule = VersionDocumentModule.context(store);

const initRefsForNaikenYoyakuRoute = (domainUID: string) => {
  const tasks: Promise<any>[] = [];
  if (!domainMapDocumentModule.getters.getRef) {
    tasks.push(domainMapDocumentModule.actions.setDocRef(domainUID));
  }
  if (!domainDocumentModule.getters.getRef) {
    tasks.push(
      domainDocumentModule.actions.setDocumentRef(domainDocumentPath(domainUID))
    );
  }
  if (!informationCollectionModule.getters.getRef) {
    tasks.push(
      informationCollectionModule.actions.setCollectionRef(
        informationCollectionPath
      )
    );
  }
  if (!domainForNaikenYoyakuSettingDocumentModule.getters.getRef) {
    tasks.push(
      domainForNaikenYoyakuSettingDocumentModule.actions.setDomainDocumentRef(
        domainUID
      )
    );
  }
  if (!domainSettingDocumentModule.getters.getRef) {
    tasks.push(
      domainSettingDocumentModule.actions.setDomainDocumentRef(domainUID)
    );
  }
  if (!licenseCollectionModule.getters.getRef) {
    tasks.push(
      licenseCollectionModule.actions.setCollectionRef(
        licenseCollectionPath(domainUID)
      )
    );
  }
  if (!versionDocumentModule.getters.getRef) {
    tasks.push(versionDocumentModule.actions.setVersionDocumentRef());
  }
  if (!tasks.length) return;
  return Promise.all(tasks);
};

export const naikenYoyakuRoute: RouteConfig = {
  path: '/reserved',
  component: NaikenYoyakuMain,
  beforeEnter: async (to, from, next) => {
    await signInModule.actions.pushAfterAuthResolvedTask(
      async (user, domainUID) => {
        const resolvedRoute = await initRoute(
          to,
          user,
          domainUID,
          initRefsForNaikenYoyakuRoute
        );
        next(resolvedRoute);
      }
    );
  },
  children: [
    {
      path: '',
      name: 'NaikenYoyakuList',
      component: NaikenYoyakuList
      // TODO: ここで内見予約一覧をバインドするか検討 #1924
    },
    {
      path: '/reserved-setting',
      name: 'NaikenYoyakuSetting',
      component: NaikenYoyakuSetting
    },
    {
      path: ':naikenYoyakuUID',
      name: 'NaikenYoyakuDetail',
      props: true,
      component: NaikenYoyakuList,
      beforeEnter: async (to, from, next) => {
        await signInModule.actions.pushAfterAuthResolvedTask(async () => {
          const naikenYoyakuUID = parseRoute(to).naikenYoyakuUID;
          const ref = naikenYoyakuDocumentModule.getters.getRef;
          // バインド済みならそのまま通す
          if (!naikenYoyakuUID || (ref && ref.id === naikenYoyakuUID)) {
            return next();
          }
          await naikenYoyakuDocumentModule.actions
            .setDocumentRef(naikenYoyakuDocumentPath(naikenYoyakuUID))
            .then(() => {
              // バインドできたらそのまま通す
              next();
            })
            .catch(async (e: Partial<FirestoreError>) => {
              if (e.code === 'permission-denied') {
                // マージ済みの場合は、別の法人に切り替えてリロード(自動再ログイン)
                const isAllowChangedDomain = domainMapDocumentModule.getters
                  .getData
                  ? await changeDomainUIDWithNaikenYoyakuUID({
                      naikenYoyakuUID
                    })
                      .then(r => r.result)
                      .catch(() => false)
                  : false;
                if (isAllowChangedDomain) {
                  await appLogger.info(
                    `閲覧可能な法人に切り替えて再ログイン: reserved/${naikenYoyakuUID}`,
                    {
                      naikenYoyakuUID
                    }
                  );
                  location.reload();
                  return;
                }
                appLogger.info(`強制ログアウト: reserved/${naikenYoyakuUID}`, {
                  naikenYoyakuUID
                });
                // 権限がなければ再ログインさせる
                return next(await forcedSignOut(to));
              }
              appLogger.error(
                '内見予約詳細へ直接アクセス時に内見予約データのバインディングに失敗',
                {
                  e,
                  naikenYoyakuUID
                }
              );
              // 例外の場合はルートに遷移
              next('/');
            });
          // TODO: 裏で内見予約一覧のバインド処理をしておくか検討 #1924
        });
      }
    }
  ]
};
