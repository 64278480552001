import { doc, runTransaction } from 'firebase/firestore';
import moment from 'moment-timezone';
import { INaikenYoyaku, INaikenYoyakuDatetime } from 'requestform-types';
import { naikenYoyakuDatetimeDocumentPath } from 'requestform-types/lib/FirestorePath';
import { isBukken, isDomain } from 'requestform-types/lib/TypeGuard';
import { Module } from 'vuex-smart-module';

import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';
import { toStartDateTimeList } from '@/utilities/naikenYoyakuDetail';

class NaikenYoyakuDatetimeDocumentState extends FirestoreDocumentState<INaikenYoyakuDatetime> {}

export class NaikenYoyakuDatetimeDocumentGetters extends FirestoreDocumentGetters<
  INaikenYoyakuDatetime,
  NaikenYoyakuDatetimeDocumentState
> {}

export class NaikenYoyakuDatetimeDocumentMutations extends FirestoreDocumentMutations<
  INaikenYoyakuDatetime,
  NaikenYoyakuDatetimeDocumentState
> {}

export class NaikenYoyakuDatetimeDocumentActions extends FirestoreDocumentActions<
  INaikenYoyakuDatetime,
  NaikenYoyakuDatetimeDocumentState,
  NaikenYoyakuDatetimeDocumentGetters,
  NaikenYoyakuDatetimeDocumentMutations
> {
  async update(data: Partial<INaikenYoyaku>) {
    const domain = data.kanriKaisha;
    const bukken = data.bukken;
    const startDateTime = data.startDateTime;
    const endDateTime = data.endDateTime;
    const naikenYoyakuUID = data.naikenYoyakuUID;
    if (
      !isDomain(domain) ||
      !isBukken(bukken) ||
      !startDateTime ||
      !endDateTime ||
      !naikenYoyakuUID
    ) {
      return;
    }
    const domainUID = domain.domainUID;
    const bukkenUID = bukken.bukkenUID;
    const startMoment = moment.utc(startDateTime.toDate()).tz('Asia/Tokyo');
    const endMoment = moment.utc(endDateTime.toDate()).tz('Asia/Tokyo');
    const dateTimeList = toStartDateTimeList(startMoment, endMoment);
    const db = this.getters.getDB;
    const docPath = naikenYoyakuDatetimeDocumentPath(
      domainUID,
      bukkenUID,
      startMoment.format('YYYYMMDD')
    );
    const ref = doc(db, docPath);

    await runTransaction(db, async t => {
      const doc = await t.get(ref);
      let canReserve: boolean = true;
      dateTimeList.some(dateTime => {
        const time = dateTime.format('HHmm');
        const dateTimes = doc.data() || {};
        const naikenYoyakuUIDs: string[] = dateTimes[time] || [];
        if (naikenYoyakuUIDs.length > 0) {
          canReserve = false;
          return true;
        }
      });
      if (canReserve) {
        dateTimeList.forEach(dateTime => {
          const time = dateTime.format('HHmm');
          const dateTimes = doc.data() || {};
          const naikenYoyakuUIDs: string[] = dateTimes[time] || [];
          naikenYoyakuUIDs.push(naikenYoyakuUID);
          const payload = {} as any;
          payload[time] = naikenYoyakuUIDs;
          t.set(ref, payload, { merge: true });
          console.log('Success KariYoyaku');
        });
        console.log('Success All KariYoyaku');
      } else {
        return Promise.reject('既に内見予約があります');
      }
    })
      .then(() => {
        console.log('Transaction Success');
      })
      .catch(e => {
        console.log('Transaction Failed', e);
        return Promise.reject(e);
      });
  }
}

export const NaikenYoyakuDatetimeDocumentModule = new Module({
  state: NaikenYoyakuDatetimeDocumentState,
  getters: NaikenYoyakuDatetimeDocumentGetters,
  mutations: NaikenYoyakuDatetimeDocumentMutations,
  actions: NaikenYoyakuDatetimeDocumentActions
});
