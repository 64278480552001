import {
  collection,
  CollectionReference,
  onSnapshot,
  Query
} from 'firebase/firestore';
import Vue from 'vue';
import { Actions, Getters, Mutations } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import { appLogger } from '@/utilities/appLogger';

export type FirestoreCollectionRefLike = Query | CollectionReference;

export class FirestoreCollectionState<T extends Record<string, unknown>> {
  data: T = {} as T;
  ref: FirestoreCollectionRefLike | null = null;
  handler: any = {};
  isLoaded: boolean = false;
  unSubscribe: (() => void) | null = null;
}

export class FirestoreCollectionGetters<
  T extends Record<string, unknown>,
  S extends FirestoreCollectionState<T>
> extends Getters<S> {
  get getData() {
    return this.state.data;
  }

  get getRef() {
    return this.state.ref;
  }

  get getIsLoaded() {
    return this.state.isLoaded;
  }
}

export class FirestoreCollectionMutations<
  T extends Record<string, unknown>,
  S extends FirestoreCollectionState<T>
> extends Mutations<S> {}

export class FirestoreCollectionActions<
  T extends Record<string, unknown>,
  S extends FirestoreCollectionState<T>,
  G extends FirestoreCollectionGetters<T, S>,
  M extends FirestoreCollectionMutations<T, S>
> extends Actions<S, G, M, FirestoreCollectionActions<T, S, G, M>> {
  setRef(ref: FirestoreCollectionRefLike): Promise<number> {
    // TODO: バインディング時間をここで計測する必要があるかは検討
    const startTime = performance.now();
    if (ref !== this.state.ref) this.unsetRef();
    this.state.ref = ref;

    return new Promise((resolve, reject) => {
      this.state.unSubscribe = onSnapshot(ref, {
        next: snap => {
          snap.docChanges().forEach(change => {
            const doc = change.doc;
            if (change.type === 'added' || change.type === 'modified') {
              if (!(this.state.data as Record<string, unknown>)[doc.id]) {
                Vue.set(this.state.data, doc.id, doc.data());
              }
              (this.state.data as Record<string, unknown>)[doc.id] = doc.data();
            }
            if (change.type === 'removed') {
              delete this.state.data[doc.id];
            }
          });
          this.state.isLoaded = true;
          const duration = Math.floor(performance.now() - startTime);
          resolve(duration);
        },
        error: error => {
          appLogger.error(`Failed onSnapshot ${ref.type}`, error);
          reject(error);
        }
      });
    });
  }
  unsetRef() {
    if (this.state.unSubscribe) {
      this.state.unSubscribe();
    }
    this.state.data = {} as T;
    this.state.ref = null;
    this.state.isLoaded = false;
    this.state.unSubscribe = null;
  }

  setCollectionRef(collectionPath: string) {
    const cRef = collection(db, collectionPath);
    return this.setRef(cRef);
  }
}
