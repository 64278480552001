import { Timestamp } from 'firebase/firestore';
import moment from 'moment-timezone';
import { isTimestamp } from 'requestform-types/lib/TypeGuard';

// TODO: Vue3.0でdisplayFilter機能は廃止されるので、各利用箇所はメソッドに置き換える
export default class Filters {
  static MoneyDelimiter(value: any): string {
    try {
      if (!(typeof value === 'number')) {
        return '';
      }
      return value.toLocaleString() + ' 円';
    } catch (ex) {
      return '';
    }
  }

  static MonthAlignment(value: any): string {
    try {
      if (!(typeof value === 'number')) {
        return '';
      }
      // NOTE: 小数点第2位で四捨五入している
      return `${Math.round(value / 0.01) * 0.01} ヶ月`;
    } catch (ex) {
      return '';
    }
  }

  static isToday(date: moment.Moment) {
    const dateFormat = 'YYYY/MM/DD';
    return date.format(dateFormat) === moment().format(dateFormat);
  }

  static isYesterday(date: moment.Moment) {
    const dateFormat = 'YYYY/MM/DD';
    return (
      date.format(dateFormat) === moment().add(-1, 'days').format(dateFormat)
    );
  }

  static MonthDayDisplay(v: unknown): string {
    if (!isTimestamp(v)) return '';
    const date = moment.unix(v.seconds);
    if (Filters.isToday(date)) return '';
    if (Filters.isYesterday(date)) return '昨日';
    return date.format('MM/DD');
  }

  static DateDisplay(value: any): string {
    try {
      if (!value || value.seconds === undefined) {
        return '';
      }
      const dateFormat = 'YYYY/MM/DD';
      const date = moment.unix(value.seconds);
      if (Filters.isToday(date)) {
        return date.format('HH:mm');
      }
      if (Filters.isYesterday(date)) {
        return `昨日 ${date.format('HH:mm')}`;
      }
      return date.format(dateFormat);
    } catch (ex) {
      return 'Unknown';
    }
  }

  static DateTimeDisplay(value: any): string {
    try {
      if (!value || value.seconds === undefined) {
        return '';
      }
      return moment.unix(value.seconds).format('YYYY/MM/DD HH:mm');
    } catch (ex) {
      return 'Unknown';
    }
  }

  static TimeDisplay(value: any): string {
    try {
      if (!value || value.seconds === undefined) {
        return '';
      }
      return moment.unix(value.seconds).format('HH:mm');
    } catch (ex) {
      return 'Unknown';
    }
  }

  static DateInputField(value?: Timestamp): string {
    try {
      const dateFormat = 'YYYY-MM-DD';
      return !value ? '' : moment(value.toDate()).format(dateFormat);
    } catch (ex) {
      return '';
    }
  }

  static CommentDateTimeDisplay(value: any): string {
    try {
      if (!value || value.seconds === undefined) {
        return '';
      }
      return moment.unix(value.seconds).format('MM/DD HH:mm');
    } catch (ex) {
      return 'Unknown';
    }
  }

  static DeadlineDateTimeDisplay(value: any): string {
    try {
      if (!value || value.seconds === undefined) {
        return '';
      }
      return moment.unix(value.seconds).format('M/D HH:mm');
    } catch (ex) {
      return 'Unknown';
    }
  }
}

/**
 * 分割されている住所データを結合する
 * TODO: functions の Csv.ts とコードが重複しているので、共通化する(不要になるテストも合わせて削除する)
 */
export function concatJushoText(
  jushoText?: string,
  nokoriJusho?: string,
  gaikuGoto?: string
) {
  if (
    jushoText?.match(/[0-9０-９]+$/gi) &&
    nokoriJusho?.match(/^[0-9０-９]+/gi)
  ) {
    jushoText = jushoText + '-';
  }
  return `${jushoText || ''}${nokoriJusho || ''}${
    gaikuGoto ? ' ' + gaikuGoto : ''
  }`;
}

/**
 * 分割されている氏名データを結合する
 * TODO: functions の Csv.ts とコードが重複しているので、共通化する(不要になるテストも合わせて削除する)
 */
export function concatNameText(sei?: string, mei?: string) {
  return `${sei || ''}${mei ? ' ' + mei : ''}`;
}
