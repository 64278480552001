import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { INaikenYoyakuDomainSetting } from 'requestform-types';
import { domainForNaikenYoyakuSettingDocumentPath } from 'requestform-types/lib/FirestorePath';
import { CustomConfirmationSettings } from 'requestform-types/lib/naikenYoyaku/INaikenYoyakuDomainSetting';
import { Store } from 'vuex';
import { Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';

class DomainForNaikenYoyakuSettingDocumentState extends FirestoreDocumentState<INaikenYoyakuDomainSetting> {}

export class DomainForNaikenYoyakuSettingDocumentGetters extends FirestoreDocumentGetters<
  INaikenYoyakuDomainSetting,
  DomainForNaikenYoyakuSettingDocumentState
> {}

class DomainForNaikenYoyakuSettingDocumentMutations extends FirestoreDocumentMutations<
  INaikenYoyakuDomainSetting,
  DomainForNaikenYoyakuSettingDocumentState
> {}

export class DomainForNaikenYoyakuSettingDocumentActions extends FirestoreDocumentActions<
  INaikenYoyakuDomainSetting,
  DomainForNaikenYoyakuSettingDocumentState,
  DomainForNaikenYoyakuSettingDocumentGetters,
  DomainForNaikenYoyakuSettingDocumentMutations
> {
  store!: Store<any>;
  $init(store: Store<any>): void {
    this.store = store;
  }
  async update(payload: Partial<INaikenYoyakuDomainSetting>) {
    if (this.state.ref) {
      await setDoc(this.state.ref, payload, { merge: true });
    }
  }
  async updateCustomConfirmations(
    payload: Partial<CustomConfirmationSettings>
  ) {
    if (this.state.ref) {
      await updateDoc(this.state.ref, 'customConfirmations', payload);
    }
  }
  async getDomainSettingDocument(domainUID?: string) {
    const uid = domainUID
      ? domainUID
      : (this.state as any).SignInModule.domainUID;
    if (!uid) return;
    const dDoc = await getDoc(
      doc(db, domainForNaikenYoyakuSettingDocumentPath(uid))
    );
    if (!dDoc.exists()) {
      console.warn(domainUID, ' does not exist');
      return;
    }
    return dDoc.data();
  }
  setDomainDocumentRef(domainUID: string) {
    if (!this.state.ref && !!domainUID) {
      this.setDocumentRef(domainForNaikenYoyakuSettingDocumentPath(domainUID));
    }
  }
}

export const DomainForNaikenYoyakuSettingDocumentModule = new Module({
  state: DomainForNaikenYoyakuSettingDocumentState,
  getters: DomainForNaikenYoyakuSettingDocumentGetters,
  mutations: DomainForNaikenYoyakuSettingDocumentMutations,
  actions: DomainForNaikenYoyakuSettingDocumentActions
});
