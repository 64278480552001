import { setDoc, Timestamp } from 'firebase/firestore';
import { IFutaiToritsugi } from 'requestform-types';
import { PartialRequired } from 'requestform-types/src/TypeGuard';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import {
  FirestoreDocumentActions,
  FirestoreDocumentGetters,
  FirestoreDocumentMutations,
  FirestoreDocumentState
} from '@/store/FirestoreDocumentBase';

import { SignInModule } from '../SignInModule';

class FutaiToritsugiDocumentState extends FirestoreDocumentState<IFutaiToritsugi> {}

export class FutaiToritsugiDocumentGetters extends FirestoreDocumentGetters<
  IFutaiToritsugi,
  FutaiToritsugiDocumentState
> {}

export class FutaiToritsugiDocumentMutations extends FirestoreDocumentMutations<
  IFutaiToritsugi,
  FutaiToritsugiDocumentState
> {}

export class FutaiToritsugiDocumentActions extends FirestoreDocumentActions<
  IFutaiToritsugi,
  FutaiToritsugiDocumentState,
  FutaiToritsugiDocumentGetters,
  FutaiToritsugiDocumentMutations
> {
  store!: Store<any>;
  signInCtx!: Context<typeof SignInModule>;
  $init(store: Store<any>): void {
    this.store = store;
    this.signInCtx = SignInModule.context(store);
  }

  async updateDocument(
    data: PartialRequired<IFutaiToritsugi, 'futaiToritsugiUID'>
  ) {
    const now = Timestamp.now();
    if (this.state.ref) {
      await setDoc(
        this.state.ref,
        {
          ...data,
          ...(data.createdAt ? {} : { createdAt: now }),
          ...(data.creatorUID
            ? {}
            : { creatorUID: this.signInCtx.getters.accountUID }),
          ...(data.domainUID
            ? {}
            : { domainUID: this.signInCtx.getters.domainUID }),
          userModified: now,
          modifiedAt: now,
          modifierUID: this.signInCtx.getters.accountUID
        } as IFutaiToritsugi,
        {
          merge: true
        }
      );
    }
  }
}

export const FutaiToritsugiDocumentModule = new Module({
  state: FutaiToritsugiDocumentState,
  getters: FutaiToritsugiDocumentGetters,
  mutations: FutaiToritsugiDocumentMutations,
  actions: FutaiToritsugiDocumentActions
});
