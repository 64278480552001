// https://gist.github.com/Yimiprod/7ee176597fef230d1451
import isEqual from 'lodash-es/isEqual';
import isObject from 'lodash-es/isObject';
import transform from 'lodash-es/transform';
import { isTimestamp } from 'requestform-types/lib/TypeGuard';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference<T, B>(object: T, base: B) {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key: any) {
      if (Array.isArray(value)) {
        value.sort();
      }
      if (Array.isArray(base[key])) {
        base[key].sort();
      }
      if (isArrayInProperties(value, base[key])) {
        if (!isEqual(value, base[key])) {
          result[key] = value;
        }
        return;
      }
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key]) && !isTimestamp(value)
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base) as Partial<T & B>;
}

function isArrayInProperties(...args: any[]) {
  return args.some(arg => Array.isArray(arg));
}
