import {
  collection,
  CollectionReference,
  doc,
  onSnapshot,
  writeBatch
} from 'firebase/firestore';
import { cloneDeep } from 'lodash-es';
import {
  toDoCollectionPath,
  toDoDocumentPath,
  toDoSettingCollectionPath
} from 'requestform-types/lib/FirestorePath';
import {
  ParentStatusKeys,
  parentStatusKeys,
  requestStatusKeyMap
} from 'requestform-types/lib/IRequest';
import {
  IToDoBase,
  IToDoSetting,
  UserRequestTypeKeys,
  userRequestTypeKeys
} from 'requestform-types/lib/IToDo';
import { isUserRequestType } from 'requestform-types/lib/TypeGuard';
import { Store } from 'vuex';
import { Context } from 'vuex-smart-module';
import { Actions, Getters, Module, Mutations } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import { appLogger } from '@/utilities/appLogger';

import { SignInModule } from './SignInModule';

const initialToDo: Record<
  ParentStatusKeys,
  IToDoBase[]
> = parentStatusKeys.reduce((acc, key) => {
  acc[key] = [];
  return acc;
}, {} as Record<ParentStatusKeys, IToDoBase[]>);

class ToDoSettingCollectionState {
  toDo = {} as Record<
    UserRequestTypeKeys,
    Record<ParentStatusKeys, IToDoBase[]>
  >;
  toDoRef: CollectionReference | null = null;
  unSubscribeToDo: (() => void) | null = null;
  toDoSetting = {} as Record<UserRequestTypeKeys, IToDoSetting>;
  toDoSettingRef: CollectionReference | null = null;
  unSubscribeToDoSetting: (() => void) | null = null;
  isLoaded: boolean = false;
}

export class ToDoSettingCollectionGetters extends Getters<ToDoSettingCollectionState> {
  get getToDo() {
    return this.state.toDo;
  }

  get getToDoSetting() {
    return this.state.toDoSetting;
  }

  get getIsLoaded() {
    return this.state.isLoaded;
  }

  get getToDoRef() {
    return this.state.toDoRef;
  }

  get getToDoSettingRef() {
    return this.state.toDoSettingRef;
  }
}

class ToDoSettingCollectionMutations extends Mutations<ToDoSettingCollectionState> {}

class ToDoSettingCollectionActions extends Actions<
  ToDoSettingCollectionState,
  ToDoSettingCollectionGetters,
  ToDoSettingCollectionMutations,
  ToDoSettingCollectionActions
> {
  signInCtx!: Context<typeof SignInModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  setToDoRef(domainUID: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const startTime = performance.now();
      userRequestTypeKeys.map(v => {
        this.state.toDoRef = collection(db, toDoCollectionPath(domainUID, v));
        this.state.unSubscribeToDo = onSnapshot(this.state.toDoRef, {
          next: snap => {
            // NOTE: 各ドキュメントに参照型フィールドが入っていなことを前提としている
            this.state.toDo = {
              ...this.state.toDo,
              [v]: {
                ...snap.docs
                  .sort((a, b) => {
                    return (
                      a.data().parentStatus - b.data().parentStatus ||
                      a.data().sortOrder - b.data().sortOrder
                    );
                  })
                  .reduce((acc, v) => {
                    const toDo = v.data() as IToDoBase;
                    const parentStatusKey = requestStatusKeyMap.get(
                      toDo.parentStatus
                    );
                    if (!parentStatusKey) {
                      return acc;
                    }
                    acc[parentStatusKey].push(toDo);
                    return acc;
                  }, cloneDeep(initialToDo))
              }
            };
          },
          error: error => {
            appLogger.error(`Failed onSnapshot ${this.state.toDoRef}`, error);
            reject(error);
          }
        });
      });
      this.state.toDoSettingRef = collection(
        db,
        toDoSettingCollectionPath(domainUID)
      );
      this.state.unSubscribeToDoSetting = onSnapshot(
        this.state.toDoSettingRef,
        {
          next: snap => {
            // NOTE: 各ドキュメントに参照型フィールドが入っていないことを前提としている
            snap.docs.forEach(s => {
              this.state.toDoSetting = {
                ...this.state.toDoSetting,
                [s.id]: s.data() as IToDoSetting
              };
            });
          },
          error: error => {
            reject(error);
          }
        }
      );
      const duration = Math.floor(performance.now() - startTime);
      this.state.isLoaded = true;
      resolve(duration);
    });
  }

  async update(payload: {
    toDoSetting: Record<UserRequestTypeKeys, IToDoSetting>;
    toDoList: Record<UserRequestTypeKeys, IToDoBase[]>;
    deletedItems: Record<UserRequestTypeKeys, IToDoBase>[];
  }) {
    const { toDoSetting, toDoList, deletedItems } = payload;
    const domainUID = this.signInCtx.getters.domainUID;
    const batch = writeBatch(db);

    if (!domainUID) {
      return Promise.reject('domainUID does not exits');
    }

    // delete
    for (const item of deletedItems) {
      for (const [userRequestTypeKey, toDoItem] of Object.entries(item)) {
        if (!isUserRequestType(userRequestTypeKey)) {
          return;
        }
        const docRef = doc(
          db,
          toDoDocumentPath(domainUID, userRequestTypeKey, toDoItem.toDoUID)
        );
        batch.delete(docRef);
      }
    }

    // update
    if (this.state.toDoSettingRef && this.state.toDoRef) {
      for (const userRequestTypeKey of userRequestTypeKeys) {
        const docRef = doc(this.state.toDoSettingRef, userRequestTypeKey);
        batch.set(docRef, toDoSetting[userRequestTypeKey], { merge: true });

        for (const toDoItem of toDoList[userRequestTypeKey]) {
          const docRef = doc(
            db,
            toDoDocumentPath(domainUID, userRequestTypeKey, toDoItem.toDoUID)
          );
          batch.set(docRef, toDoItem, { merge: true });
        }
      }

      await batch.commit();
    }
  }

  unsetRef() {
    if (this.state.unSubscribeToDo) {
      this.state.unSubscribeToDo();
    }
    if (this.state.unSubscribeToDoSetting) {
      this.state.unSubscribeToDoSetting();
    }

    this.state.toDo = {} as Record<
      UserRequestTypeKeys,
      Record<ParentStatusKeys, IToDoBase[]>
    >;
    this.state.toDoSetting = {} as Record<UserRequestTypeKeys, IToDoSetting>;

    this.state.toDoRef = null;
    this.state.toDoSettingRef = null;
    this.state.isLoaded = false;
    this.state.unSubscribeToDo = null;
    this.state.unSubscribeToDoSetting = null;
  }
}

export const ToDoSettingCollectionModule = new Module({
  state: ToDoSettingCollectionState,
  getters: ToDoSettingCollectionGetters,
  mutations: ToDoSettingCollectionMutations,
  actions: ToDoSettingCollectionActions
});
