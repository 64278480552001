import {
  collection,
  CollectionReference,
  onSnapshot,
  Query,
  query,
  QuerySnapshot,
  where
} from 'firebase/firestore';
import moment from 'moment-timezone';
import { IDomain, IRequest, IShop } from 'requestform-types';
import { domainRequestCollectionPath } from 'requestform-types/lib/FirestorePath';
import { RequestStatus } from 'requestform-types/lib/IRequest';
import { SystemTags } from 'requestform-types/lib/ITag';
import {
  isBukken,
  isDefined,
  isDomain,
  isOursRequest,
  isRequest,
  isString,
  isTheirsRequest,
  isTimestamp,
  PartialRequired
} from 'requestform-types/lib/TypeGuard';
import { IBukken } from 'requestform-types/src';
import { Store } from 'vuex';
import { Context, Module } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import RequestProxy from '@/model/RequestProxy';
import { SignInModule } from '@/requestform/store/SignInModule';
import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';
import { appLogger } from '@/utilities/appLogger';
import {
  isShowOursRequestToOneUser,
  isShowTheirsRequestToOneUser
} from '@/utilities/isShowRequestToOneUser';
import {
  BanteSearchItem,
  getCachedSearchConditions,
  SearchConditions
} from '@/utilities/search/requestSearchConditionsConverter';
import { DomainFilter } from '@/utilities/search/searchConditionsBase';
import {
  getDisplaySystemTags,
  getSystemTagKeys
} from '@/utilities/systemTagging';

import { DomainSettingDocumentModule } from './DomainSettingDocumentModule';

type FirestoreCollectionRefLike = Query | CollectionReference;

export type tagSearchPropType = {
  isKanrikaisha: boolean;
  keys: string[];
};

export const NO_TAGS = 'no';

export const NO_ORIGINAL_TAGS = 'no_original';

export const isContainsFilteredSystemTags: (
  request: Partial<IRequest>,
  filteredSystemTagKeys: string[]
) => boolean = (request, keys) => {
  const systemTagKeys = getSystemTagKeys(request.tag ?? {}).map(tag =>
    tag.toString()
  );
  return keys.every(key => systemTagKeys.includes(key));
};

export const isContainsFilteredDomainTags: (
  request: Partial<IRequest>,
  filteredDomainTagKeys: string[],
  isKanrikaisha: boolean
) => boolean = (request, keys, isKanrikaisha) => {
  return keys.every(key => {
    return isKanrikaisha
      ? request.kanrikaishaTags && request.kanrikaishaTags.includes(key)
      : request.chukaikaishaTags && request.chukaikaishaTags.includes(key);
  });
};

export const isContainsDomainTags: (
  request: Partial<IRequest>,
  isKanrikaisha: boolean
) => boolean = (request, isKanrikaisha) => {
  return isKanrikaisha
    ? !!(request.kanrikaishaTags && request.kanrikaishaTags.length)
    : !!(request.chukaikaishaTags && request.chukaikaishaTags.length);
};

export const requestFilterSearchTags: (payload: {
  requests: Partial<IRequest>[];
  filteredTagKeys: string[];
  domainUID: string;
}) => Partial<IRequest>[] = payload => {
  const { requests, filteredTagKeys, domainUID } = payload;
  const divideKeys: {
    domain: string[];
    system: string[];
    noTag: boolean;
    noOriginalTag: boolean;
  } = {
    domain: [],
    system: [],
    noTag: false,
    noOriginalTag: false
  };
  const filteredKeys = filteredTagKeys.reduce((keys, key) => {
    if (key === NO_TAGS) {
      keys.noTag = true;
      return keys;
    }
    if (key === NO_ORIGINAL_TAGS) {
      keys.noOriginalTag = true;
      return keys;
    }
    // NOTE: 引当てに成功したらシステムタグとする
    if ((Object.values(SystemTags) as number[]).includes(+key)) {
      keys.system.push(key);
      return keys;
    }
    keys.domain.push(key);
    return keys;
  }, divideKeys);

  const filteredRequests = requests.filter(r => {
    const isKanriKaisha = isOursRequest(r, domainUID);

    // NOTE: タグなし選択時
    if (filteredKeys.noTag) {
      return !(
        isContainsDomainTags(r, isKanriKaisha) ||
        !!getDisplaySystemTags({
          systemTags: r.tag,
          isKanriKaisha: isKanriKaisha
        }).length
      );
    }

    const isMatchSystemTags = isContainsFilteredSystemTags(
      r,
      filteredKeys.system
    );
    const isMatchDomainTags = filteredKeys.noOriginalTag
      ? !isContainsDomainTags(r, isKanriKaisha)
      : isContainsFilteredDomainTags(r, filteredKeys.domain, isKanriKaisha);

    return isMatchSystemTags && isMatchDomainTags;
  });
  return filteredRequests;
};

export const replaceRequestDomainTag: (
  request: PartialRequired<IRequest, 'requestUID'>,
  tags: Record<'addTags' | 'removeTags', string[]>,
  domainUID: string
) => PartialRequired<IRequest, 'requestUID'> | undefined = (
  request,
  { addTags, removeTags },
  domainUID
) => {
  const replace = (tagProp: string[] | undefined) => {
    const replaced = [...(tagProp ?? []), ...addTags].filter(
      tag => !removeTags.includes(tag)
    );
    const result = Array.from(new Set(replaced));
    // NOTE: 元々タグが付与されておらず、新たに追加もされない場合はundefinedを返す
    if (!tagProp?.length && !result?.length) {
      return undefined;
    }
    // NOTE: 前後で変更がなかった場合にもundefinedを返す
    if (
      tagProp?.every(p => result.includes(p)) &&
      tagProp.length === result.length
    ) {
      return undefined;
    }
    // NOTE: 元々付与されていたタグが全て外れた場合には空の配列が返る
    return result;
  };
  const requestUID = request.requestUID;
  const prop: Pick<
    PartialRequired<IRequest, 'requestUID'>,
    'requestUID' | 'kanrikaishaTags' | 'chukaikaishaTags'
  > = { requestUID };
  if ((request.kanriKaisha as IDomain)?.domainUID === domainUID) {
    prop.kanrikaishaTags = replace(request.kanrikaishaTags);
  } else if ((request.chukaiKaisha as IDomain)?.domainUID === domainUID) {
    prop.chukaikaishaTags = replace(request.chukaikaishaTags);
  }
  if (!prop.kanrikaishaTags && !prop.chukaikaishaTags) {
    return undefined;
  }
  return prop;
};

// TODO: 手動で「番手なし」にする機能を追加する際にここの条件も再検討する
const banteFilterMap: Record<
  BanteSearchItem,
  (r: Partial<IRequest>) => boolean
> = {
  [BanteSearchItem.None]: r => !isDefined(r.bante),
  [BanteSearchItem.First]: r => r.bante === 1,
  [BanteSearchItem.AfterSecond]: r => isDefined(r.bante) && r.bante >= 2
};

class RequestCollectionState extends FirestoreCollectionState<IRequest> {
  domainFilter: DomainFilter = DomainFilter.Ours;
  // 自社物件メニュー用
  oursSearchConditions: Partial<SearchConditions> = getCachedSearchConditions(
    DomainFilter.Ours
  );
  // 他社物件メニュー用
  theirsSearchConditions: Partial<SearchConditions> = getCachedSearchConditions(
    DomainFilter.Theirs
  );
  isBoundAll: boolean = false;
  handler: any = new RequestProxy().readonlyHandler;
  // NOTE: 終了・キャンセル&未対応メッセージ有り申込リストのバインディングデータ。専用にstoreModuleを用意する程でもないのでここに置いている
  dataForReactionNeededInactive: Partial<IRequest>[] = [];
  isBoundForReactionNeededInactive: boolean = false;
  unSubscribeForReactionNeeded: (() => void) | null = null;
  isLoadedForReactionNeededInactive = true;
  banteChangeBukkenObj: Partial<IBukken> | null = null;
}

export class RequestCollectionGetters extends FirestoreCollectionGetters<
  IRequest,
  RequestCollectionState
> {
  signInCtx!: Context<typeof SignInModule>;
  domainSettingCtx?: Context<typeof DomainSettingDocumentModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
    this.domainSettingCtx = DomainSettingDocumentModule.context(store);
  }

  get domainFilter() {
    return this.state.domainFilter;
  }

  get searchConditions() {
    if (this.domainFilter === DomainFilter.Theirs) {
      return this.state.theirsSearchConditions;
    }
    return this.state.oursSearchConditions;
  }

  get getIsBoundAll() {
    return this.state.isBoundAll;
  }

  get getIsBound() {
    return !!this.state.ref;
  }

  get getIsBoundForReactionNeededInactive() {
    return this.state.isBoundForReactionNeededInactive;
  }

  get getIsLoadedForReactionNeededInactive() {
    return this.state.isLoadedForReactionNeededInactive;
  }

  get getFilteredList(): Partial<IRequest>[] {
    let target =
      this.searchConditions.domain === DomainFilter.Ours
        ? this.getOurData
        : this.getTheirData;
    const kanriKaishaFilter = this.searchConditions.kanriKaisha;
    if (kanriKaishaFilter) {
      const filter: (names: Partial<string[]>) => boolean = this
        .searchConditions.kanriKaishaExactSearch
        ? // 完全一致
          names => names.some(x => x === kanriKaishaFilter)
        : // 部分一致
          names =>
            names.some(x =>
              x?.toLowerCase().includes(kanriKaishaFilter.toLowerCase())
            );
      target = target.filter(r => {
        // TODO: 一覧データ用の型を用意した方が良さそう
        // NOTE: 速度優先でアサーションしている
        const kanriKaisha = r.kanriKaisha as Partial<IDomain> | undefined;
        const kanriKaishaShop = r.kanriKaishaShop as Partial<IShop> | undefined;
        return filter([kanriKaisha?.name, kanriKaishaShop?.name]);
      });
    }
    if (this.searchConditions.kanriKaishaTantoshaName) {
      const filter = this.searchConditions.kanriKaishaTantoshaName.toLowerCase();
      target = target.filter(r =>
        r.kanriKaishaTantoshaName?.toLowerCase().includes(filter)
      );
    }
    if (this.searchConditions.bukken) {
      const filter = this.searchConditions.bukken.toLowerCase();
      target = target.filter(
        r => isBukken(r.bukken) && r.bukken.name?.toLowerCase().includes(filter)
      );
    }
    if (this.searchConditions.heyaKukakuNumber) {
      const filter = this.searchConditions.heyaKukakuNumber.toLowerCase();
      target = target.filter(
        r =>
          isBukken(r.bukken) &&
          r.bukken.heyaKukakuNumber?.toLowerCase().includes(filter)
      );
    }
    if (this.searchConditions.moshikomisha) {
      const filter = this.searchConditions.moshikomisha.toLowerCase();
      target = target.filter(r =>
        r.hojinMoshikomisha?.companyName
          ? r.hojinMoshikomisha?.companyName.toLowerCase().includes(filter)
          : r.moshikomisha?.name?.toLowerCase().includes(filter)
      );
    }
    if (this.searchConditions.chukaiKaisha) {
      const filter = (name?: string) =>
        this.searchConditions.chukaiKaishaExactSearch
          ? name === this.searchConditions.chukaiKaisha
          : name
              ?.toLowerCase()
              .includes(
                this.searchConditions.chukaiKaisha?.toLowerCase() || ''
              );
      target = target.filter(
        r => isDomain(r.chukaiKaisha) && filter(r.chukaiKaisha.name)
      );
    }
    if (this.searchConditions.chukaiKaishaTantoshaName) {
      const filter = this.searchConditions.chukaiKaishaTantoshaName.toLowerCase();
      target = target.filter(r =>
        r.chukaiKaishaTantoshaName?.toLowerCase().includes(filter)
      );
    }
    if (this.searchConditions.chukaiKaishaTenpoName) {
      const filter = (name?: string) =>
        this.searchConditions.chukaiKaishaTenpoNameExactSearch
          ? name === this.searchConditions.chukaiKaishaTenpoName
          : name
              ?.toLowerCase()
              .includes(
                this.searchConditions.chukaiKaishaTenpoName?.toLowerCase() || ''
              );
      target = target.filter(r => filter(r.chukaiKaishaTenpoName));
    }
    if (this.searchConditions.tags?.length) {
      target = requestFilterSearchTags({
        requests: target,
        filteredTagKeys: this.searchConditions.tags,
        domainUID: this.signInCtx.getters.domainUID
      }) as IRequest[];
    }

    if (this.searchConditions.modifiedAtStart) {
      target = target.filter(
        r =>
          r.modifiedAt &&
          isTimestamp(r.modifiedAt) &&
          moment(r.modifiedAt.toDate()).isSameOrAfter(
            moment(this.searchConditions.modifiedAtStart),
            'day'
          )
      );
    }

    if (this.searchConditions.modifiedAtEnd) {
      target = target.filter(
        r =>
          r.modifiedAt &&
          isTimestamp(r.modifiedAt) &&
          moment(r.modifiedAt.toDate()).isSameOrBefore(
            moment(this.searchConditions.modifiedAtEnd),
            'day'
          )
      );
    }

    if (this.searchConditions.reviewRequestedAtStart) {
      target = target.filter(
        r =>
          r.reviewRequestedAt &&
          isTimestamp(r.reviewRequestedAt) &&
          moment(r.reviewRequestedAt.toDate()).isSameOrAfter(
            moment(this.searchConditions.reviewRequestedAtStart),
            'day'
          )
      );
    }

    if (this.searchConditions.reviewRequestedAtEnd) {
      target = target.filter(
        r =>
          r.reviewRequestedAt &&
          isTimestamp(r.reviewRequestedAt) &&
          moment(r.reviewRequestedAt.toDate()).isSameOrBefore(
            moment(this.searchConditions.reviewRequestedAtEnd),
            'day'
          )
      );
    }

    if (this.searchConditions.reactionNeeded) {
      target = target.filter(r => r.isReactionNeeded);
    } else if (this.searchConditions.active === false) {
      target = target.filter(r => !r.isActive);
    } else {
      target = target.filter(r => r.isActive);
    }
    if (
      this.searchConditions.status?.length ||
      this.searchConditions.subStatus?.length
    ) {
      target = target.filter(
        r =>
          (r.status && this.searchConditions.status?.includes(r.status)) ||
          (r.subStatusUID &&
            this.searchConditions.subStatus?.includes(r.subStatusUID)) ||
          (!isString(r.subStatusUID) &&
            this.searchConditions.subStatus?.includes(
              `no_${r.status?.toString()}`
            ))
      );
    }

    if (this.searchConditions.bante) {
      target = target.filter(banteFilterMap[this.searchConditions.bante]);
    }
    return target;
  }

  get mergedData() {
    const data = this.getIsBoundAll
      ? []
      : this.state.dataForReactionNeededInactive;
    // NOTE: 重複が混入することはない想定だが、念の為排除している
    return data.reduce((merged, data) => {
      if (merged.find(m => m.requestUID === data.requestUID)) {
        return merged;
      }
      return [...merged, data];
    }, this.state.data as Partial<IRequest>[]);
  }

  get getOurData() {
    const domainUID = this.signInCtx.getters.domainUID;
    if (!this.domainSettingCtx?.getters.getData?.shopVisibleRestriction) {
      return this.mergedData
        .filter(r => isOursRequest(r, domainUID))
        .map(x =>
          typeof x === 'object' ? new Proxy(x, this.state.handler) : x
        );
    }
    const oneUserOrganizations = this.signInCtx.getters.getOneUserOrganizations;
    const hasMasterRoleFlag = this.signInCtx.getters.getHasMasterRoleFlag;
    if (hasMasterRoleFlag === null) return [];
    return this.mergedData
      .filter(r =>
        isShowOursRequestToOneUser(r, domainUID, oneUserOrganizations)
      )
      .map(x => (typeof x === 'object' ? new Proxy(x, this.state.handler) : x));
  }

  get getTheirData() {
    const domainUID = this.signInCtx.getters.domainUID;
    if (!this.domainSettingCtx?.getters.getData?.shopVisibleRestriction) {
      return this.mergedData
        .filter(r => isTheirsRequest(r, domainUID))
        .map(x =>
          typeof x === 'object' ? new Proxy(x, this.state.handler) : x
        );
    }
    const oneUserOrganizations = this.signInCtx.getters.getOneUserOrganizations;
    const hasMasterRoleFlag = this.signInCtx.getters.getHasMasterRoleFlag;
    if (hasMasterRoleFlag === null) return [];
    return this.mergedData
      .filter(r =>
        isShowTheirsRequestToOneUser(r, domainUID, oneUserOrganizations)
      )
      .map(x => (typeof x === 'object' ? new Proxy(x, this.state.handler) : x));
  }

  get getBanteChangeBukkenObj() {
    return this.state.banteChangeBukkenObj;
  }

  get getIncomingRequests() {
    if (!this.getBanteChangeBukkenObj) {
      return [];
    }
    return (this.getOurData.filter(
      v =>
        isRequest(v) &&
        v.isActive !== false &&
        v.status !== RequestStatus.Disapproval &&
        this.state.banteChangeBukkenObj?.incomingRequests?.includes(
          v.requestUID
        )
    ) as PartialRequired<IRequest, 'requestUID'>[]).sort((a, b) => {
      {
        const aBante = a.bante;
        const bBante = b.bante;
        if (aBante === undefined) {
          return 1;
        } else if (bBante === undefined) {
          return -1;
        } else {
          return aBante > bBante ? 1 : -1;
        }
      }
    });
  }
}

class RequestCollectionMutations extends FirestoreCollectionMutations<
  IRequest,
  RequestCollectionState
> {
  setDomainFilter(v: DomainFilter) {
    this.state.domainFilter = v;
  }
  setSearchConditions(v: Partial<SearchConditions>) {
    if (v.domain === DomainFilter.Theirs) {
      this.state.theirsSearchConditions = v;
    } else {
      this.state.oursSearchConditions = v;
    }
  }
  setBanteChangeBukkenObj(v: Partial<IBukken>) {
    this.state.banteChangeBukkenObj = v;
  }
}

export class RequestCollectionActions extends FirestoreCollectionActions<
  IRequest,
  RequestCollectionState,
  RequestCollectionGetters,
  RequestCollectionMutations
> {
  setSearchConditions(v: Partial<SearchConditions>) {
    if (v.domain !== this.getters.domainFilter) {
      this.commit('setDomainFilter', v.domain ?? DomainFilter.Ours);
    }
    this.commit('setSearchConditions', v);
  }
  updateSearchConditions(v: Partial<SearchConditions>) {
    const before =
      v.domain === DomainFilter.Theirs
        ? this.state.theirsSearchConditions
        : this.state.oursSearchConditions;
    this.setSearchConditions({ ...before, ...v });
  }

  setCollectionRef(collectionPath: string) {
    const cRef = collection(db, collectionPath);
    if (this.getters.searchConditions.active !== false) {
      return this.setRef(
        query(
          cRef,
          where('isActive', '==', true),
          where('status', '!=', RequestStatus.Archived)
        )
      );
    } else {
      return this.setRef(
        query(cRef, where('status', '!=', RequestStatus.Archived))
      );
    }
  }

  setCollectionRefForReactionNeed(domainUID: string) {
    if (
      this.getters.getIsBoundAll ||
      this.getters.getIsBoundForReactionNeededInactive ||
      !this.getters.searchConditions.reactionNeeded ||
      !domainUID
    ) {
      return;
    }
    this.state.isLoadedForReactionNeededInactive = false;
    const cRef = collection(db, domainRequestCollectionPath(domainUID));
    const ref = query(
      cRef,
      where('isActive', '==', false),
      where('isReactionNeeded', '==', true),
      where('status', '!=', RequestStatus.Archived)
    );
    this.state.unSubscribeForReactionNeeded = onSnapshot(ref, {
      next: (snap: QuerySnapshot) => {
        this.state.dataForReactionNeededInactive = snap.docs.map(
          s => s.data() as Partial<IRequest>
        );
        this.state.isLoadedForReactionNeededInactive = true;
        this.state.isBoundForReactionNeededInactive = true;
      },
      error: error => {
        appLogger.error(`Failed onSnapshot ${ref.type}`, error);
      }
    });
  }

  unsetRefForReactionNeed() {
    if (this.state.unSubscribeForReactionNeeded) {
      this.state.unSubscribeForReactionNeeded();
    }
    this.state.dataForReactionNeededInactive = [];
    this.state.unSubscribeForReactionNeeded = null;
  }

  setDomainCollectionRef(domainUID: string) {
    if (domainUID) {
      return this.setCollectionRef(domainRequestCollectionPath(domainUID));
    }
  }

  setRef(ref: FirestoreCollectionRefLike): Promise<number> {
    return new Promise((resolve, reject) => {
      super
        .setRef(ref)
        .then(duration => {
          if (this.getters.searchConditions.active === false) {
            this.state.isBoundAll = true;
          }
          resolve(duration);
        })
        .catch(() => {
          reject();
        });
    });
  }

  setBanteChangeBukkenObj(v: Partial<IBukken>) {
    this.commit('setBanteChangeBukkenObj', v);
  }
}

export const RequestCollectionModule = new Module({
  state: RequestCollectionState,
  getters: RequestCollectionGetters,
  mutations: RequestCollectionMutations,
  actions: RequestCollectionActions
});
