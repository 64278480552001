import { IDomain, IRequest } from 'requestform-types';
import { domainCollectionPath } from 'requestform-types/lib/FirestorePath';
import { Store } from 'vuex';
import { Module } from 'vuex-smart-module';

import {
  FirestoreCollectionActions,
  FirestoreCollectionGetters,
  FirestoreCollectionMutations,
  FirestoreCollectionState
} from '@/store/FirestoreCollectionBase';

class DomainCollectionState extends FirestoreCollectionState<IDomain> {
  relatedDomainList: IDomain[] = [];
}

export class DomainCollectionGetters extends FirestoreCollectionGetters<
  IDomain,
  DomainCollectionState
> {}

class DomainCollectionMutations extends FirestoreCollectionMutations<
  IDomain,
  DomainCollectionState
> {}

class DomainCollectionActions extends FirestoreCollectionActions<
  IDomain,
  DomainCollectionState,
  DomainCollectionGetters,
  DomainCollectionMutations
> {
  store!: Store<any>;
  $init(store: Store<any>): void {
    this.store = store;
  }

  setDomainCollectionRef() {
    if (!this.state.ref) {
      this.setCollectionRef(domainCollectionPath);
    }
  }
}

/**
 * domainListからIRequest.allowDomainのdomainを集めて返す
 * @param domainList
 * @param domainRequestList
 * @param domainUID
 */
export function collectAllowDomain(
  domainList: IDomain[],
  domainRequestList: IRequest[],
  domainUID: string
) {
  const allowDomainUIDs: string[] = [];
  domainRequestList.forEach(r => {
    if (r.allowDomain) {
      allowDomainUIDs.push(...r.allowDomain);
    }
  });
  const relatedDomainUIDsSet = new Set<string>(allowDomainUIDs);
  relatedDomainUIDsSet.delete(domainUID); // 自分のdomainUIDは含めない

  return domainList.filter(d => relatedDomainUIDsSet.has(d.domainUID));
}

export const DomainCollectionModule = new Module({
  state: DomainCollectionState,
  getters: DomainCollectionGetters,
  mutations: DomainCollectionMutations,
  actions: DomainCollectionActions
});
