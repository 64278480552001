import { isString } from 'markdown-it/lib/common/utils';
import { DateString } from 'requestform-types/lib/DateTimeString';
import {
  naikenPurposeTypes,
  naikenYoyakuStatus
} from 'requestform-types/lib/naikenYoyaku/INaikenYoyaku';
import { isDefined } from 'requestform-types/lib/TypeGuard';
import { CheckKeys } from 'requestform-types/lib/utilities';

import {
  Converter,
  deleteCache,
  DomainFilter,
  getCache,
  setCache
} from './searchConditionsBase';

export const SearchConditionKeys = [
  'domain',
  'bukken',
  'heyaKukakuNumber',
  'naikenDateFrom',
  'naikenDateTo',
  'createdAtFrom',
  'createdAtTo',
  'status',
  'purpose',
  'kanriKaisha',
  'kanriKaishaExactSearch',
  'kanriKaishaLocked',
  'chukaiKaisha',
  'chukaiKaishaTenpoName',
  'chukaiKaishaExactSearch',
  'chukaiKaishaLocked',
  'chukaiKaishaTenpoNameExactSearch'
] as const;
export type SearchConditionKeys = typeof SearchConditionKeys[number];
export type SearchConditions = CheckKeys<
  {
    domain: DomainFilter;
    bukken: string;
    heyaKukakuNumber: string;
    naikenDateFrom: DateString;
    naikenDateTo: DateString;
    createdAtFrom: DateString;
    createdAtTo: DateString;
    status: naikenYoyakuStatus[];
    purpose: naikenPurposeTypes[];
    kanriKaisha: string;
    kanriKaishaExactSearch: boolean;
    kanriKaishaLocked: boolean;
    chukaiKaisha: string;
    chukaiKaishaTenpoName: string;
    chukaiKaishaExactSearch: boolean;
    chukaiKaishaLocked: boolean;
    chukaiKaishaTenpoNameExactSearch: boolean;
  },
  SearchConditionKeys
>;

const kanriKaishaLockedProps = [
  'kanriKaisha',
  'kanriKaishaExactSearch'
] as const;
const chukaiKaishaLockedProps = [
  'chukaiKaisha',
  'chukaiKaishaExactSearch',
  'chukaiKaishaTenpoName',
  'chukaiKaishaTenpoNameExactSearch'
] as const;

type cacheKey =
  | typeof kanriKaishaLockedProps[number]
  | typeof chukaiKaishaLockedProps[number];

// NOTE: 検索条件をURLパラメータに含める際は以下の定義を追加する
export const converter: {
  [K in cacheKey]: Converter<SearchConditions, K>;
} = {
  kanriKaisha: {
    toQuery: v => v.kanriKaisha,
    fromQuery: v => {
      if (isString(v.kanriKaisha)) return v.kanriKaisha;
    }
  },
  kanriKaishaExactSearch: {
    toQuery: v => (v.kanriKaishaExactSearch === true ? 'true' : undefined),
    fromQuery: v => {
      if (v.kanriKaishaExactSearch === 'true') return true;
    }
  },
  chukaiKaisha: {
    toQuery: v => v.chukaiKaisha,
    fromQuery: v => {
      if (isString(v.chukaiKaisha)) return v.chukaiKaisha;
    }
  },
  chukaiKaishaExactSearch: {
    toQuery: v => (v.chukaiKaishaExactSearch === true ? 'true' : undefined),
    fromQuery: v => {
      if (v.chukaiKaishaExactSearch === 'true') return true;
    }
  },
  chukaiKaishaTenpoName: {
    toQuery: v => v.chukaiKaishaTenpoName,
    fromQuery: v => {
      if (isString(v.chukaiKaishaTenpoName)) return v.chukaiKaishaTenpoName;
    }
  },
  chukaiKaishaTenpoNameExactSearch: {
    toQuery: v =>
      v.chukaiKaishaTenpoNameExactSearch === true ? 'true' : undefined,
    fromQuery: v => {
      if (v.chukaiKaishaTenpoNameExactSearch === 'true') return true;
    }
  }
};

export const getCachedSearchConditions = (
  domain: DomainFilter
): Partial<SearchConditions> => {
  const lockConditions: Partial<SearchConditions> = {
    kanriKaishaLocked: kanriKaishaLockedProps.some(p =>
      getCache(domain, p, true)
    ),
    chukaiKaishaLocked: chukaiKaishaLockedProps.some(p =>
      getCache(domain, p, true)
    )
  };
  return [...kanriKaishaLockedProps, ...chukaiKaishaLockedProps].reduce(
    (s, p) => {
      const cache = getCache(domain, p, true);
      if (!isDefined(cache)) return s;
      return { ...s, [p]: converter[p].fromQuery({ [p]: cache }) };
    },
    lockConditions
  );
};

export const onChangeSearchConditions = (
  before: Partial<SearchConditions>,
  after: Partial<SearchConditions>
): void => {
  const domain = after.domain ?? DomainFilter.Ours;
  // 管理会社キャッシュ
  if (after.kanriKaishaLocked) {
    kanriKaishaLockedProps.forEach(p => {
      const cache = converter[p].toQuery(after);
      isString(cache)
        ? setCache(domain, p, cache, true)
        : deleteCache(domain, p, true);
    });
  } else if (before.kanriKaishaLocked && !after.kanriKaishaLocked) {
    kanriKaishaLockedProps.forEach(p => deleteCache(domain, p, true));
  }
  // 仲介会社キャッシュ
  if (after.chukaiKaishaLocked) {
    chukaiKaishaLockedProps.forEach(p => {
      const cache = converter[p].toQuery(after);
      isString(cache)
        ? setCache(domain, p, cache, true)
        : deleteCache(domain, p, true);
    });
  } else if (before.chukaiKaishaLocked && !after.chukaiKaishaLocked) {
    chukaiKaishaLockedProps.forEach(p => deleteCache(domain, p, true));
  }
};
