import {
  collection,
  CollectionReference,
  doc,
  onSnapshot,
  writeBatch
} from 'firebase/firestore';
import {
  subStatusCollectionPath,
  subStatusDocumentPath,
  subStatusSettingCollectionPath
} from 'requestform-types/lib/FirestorePath';
import {
  ParentStatusKeys,
  parentStatusKeys,
  requestStatusKeyMap
} from 'requestform-types/lib/IRequest';
import {
  ISubStatus,
  ISubStatusSetting
} from 'requestform-types/lib/ISubStatus';
import { Store } from 'vuex';
import { Context } from 'vuex-smart-module';
import { Actions, Getters, Module, Mutations } from 'vuex-smart-module';

import { db } from '@/firebase/firebase';
import { appLogger } from '@/utilities/appLogger';

import { SignInModule } from './SignInModule';

class SubStatusCollectionState {
  subStatus = {} as Record<ParentStatusKeys, ISubStatus[]>;
  subStatusRef: CollectionReference | null = null;
  unSubscribeSubStatus: (() => void) | null = null;
  subStatusSetting = {} as Record<ParentStatusKeys, ISubStatusSetting>;
  subStatusSettingRef: CollectionReference | null = null;
  unSubscribeSubStatusSetting: (() => void) | null = null;
  isLoaded: boolean = false;
}

export class SubStatusCollectionGetters extends Getters<SubStatusCollectionState> {
  get getSubStatus() {
    return this.state.subStatus;
  }

  get getSubStatusSetting() {
    return this.state.subStatusSetting;
  }

  get getIsLoaded() {
    return this.state.isLoaded;
  }

  get getSubStatusRef() {
    return this.state.subStatusRef;
  }

  get getSubStatusSettingRef() {
    return this.state.subStatusSettingRef;
  }
}

class SubStatusCollectionMutations extends Mutations<SubStatusCollectionState> {}

class SubStatusCollectionActions extends Actions<
  SubStatusCollectionState,
  SubStatusCollectionGetters,
  SubStatusCollectionMutations,
  SubStatusCollectionActions
> {
  signInCtx!: Context<typeof SignInModule>;

  $init(store: Store<any>): void {
    this.signInCtx = SignInModule.context(store);
  }

  setSubStatusRef(domainUID: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const startTime = performance.now();
      parentStatusKeys.map(v => {
        this.state.subStatusRef = collection(
          db,
          subStatusCollectionPath(domainUID, v)
        );
        this.state.unSubscribeSubStatus = onSnapshot(this.state.subStatusRef, {
          next: snap => {
            // NOTE: 各ドキュメントに参照型フィールドが入っていなことを前提としている
            this.state.subStatus = {
              ...this.state.subStatus,
              [v]: snap.docs
                .map(s => s.data() as ISubStatus)
                .sort((a, b) => a.sortOrder - b.sortOrder)
            };
          },
          error: error => {
            appLogger.error(
              `Failed onSnapshot ${this.state.subStatusRef}`,
              error
            );
            reject(error);
          }
        });
      });
      this.state.subStatusSettingRef = collection(
        db,
        subStatusSettingCollectionPath(domainUID)
      );
      this.state.unSubscribeSubStatusSetting = onSnapshot(
        this.state.subStatusSettingRef,
        {
          next: snap => {
            // NOTE: 各ドキュメントに参照型フィールドが入っていないことを前提としている
            snap.docs.forEach(s => {
              this.state.subStatusSetting = {
                ...this.state.subStatusSetting,
                [s.id]: s.data() as ISubStatusSetting
              };
            });
          },
          error: error => {
            reject(error);
          }
        }
      );
      const duration = Math.floor(performance.now() - startTime);
      this.state.isLoaded = true;
      resolve(duration);
    });
  }

  async update(payload: {
    defaultSubStatusList: Record<ParentStatusKeys, ISubStatusSetting>;
    subStatusList: Record<ParentStatusKeys, ISubStatus[]>;
    deletedItems: ISubStatus[];
  }) {
    const { defaultSubStatusList, subStatusList, deletedItems } = payload;
    const domainUID = this.signInCtx.getters.domainUID;
    const batch = writeBatch(db);

    if (!domainUID) {
      return Promise.reject('domainUID does not exits');
    }

    // delete
    if (deletedItems.length > 0) {
      for (const item of deletedItems) {
        const parentStatusKey = requestStatusKeyMap.get(item.parentStatus);
        if (parentStatusKey) {
          const docRef = doc(
            db,
            subStatusDocumentPath(domainUID, parentStatusKey, item.subStatusUID)
          );
          batch.delete(docRef);
        }
      }
    }

    // update
    if (this.state.subStatusSettingRef && this.state.subStatusRef) {
      for (const parentStatusKey of parentStatusKeys) {
        const docRef = doc(this.state.subStatusSettingRef, parentStatusKey);
        batch.set(docRef, defaultSubStatusList[parentStatusKey], {
          merge: true
        });
      }

      for (const parentStatusKey of parentStatusKeys) {
        for (const subStatus of subStatusList[parentStatusKey]) {
          const docRef = doc(
            db,
            subStatusDocumentPath(
              domainUID,
              parentStatusKey,
              subStatus.subStatusUID
            )
          );
          batch.set(docRef, subStatus, { merge: true });
        }
      }

      await batch.commit();
    }
  }

  unsetRef() {
    if (this.state.unSubscribeSubStatus) {
      this.state.unSubscribeSubStatus();
    }
    if (this.state.unSubscribeSubStatusSetting) {
      this.state.unSubscribeSubStatusSetting();
    }

    this.state.subStatus = {} as Record<ParentStatusKeys, ISubStatus[]>;
    this.state.subStatusSetting = {} as Record<
      ParentStatusKeys,
      ISubStatusSetting
    >;
    this.state.subStatusRef = null;
    this.state.subStatusSettingRef = null;
    this.state.isLoaded = false;
    this.state.unSubscribeSubStatus = null;
    this.state.unSubscribeSubStatusSetting = null;
  }
}

export const SubStatusCollectionModule = new Module({
  state: SubStatusCollectionState,
  getters: SubStatusCollectionGetters,
  mutations: SubStatusCollectionMutations,
  actions: SubStatusCollectionActions
});
